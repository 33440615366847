import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import useAPI from "services/useHunterApi";
import game from "data/model/game";
import { CircularProgress, Stack, Typography } from "@mui/material";

interface GameProps {}

export default function NewGame(props: GameProps) {
  const navigate = useNavigate();
  const { callAPI, isLoading } = useAPI();
  let { tourId, isPreview, startIndex } = useParams();
  const [errorState, setErrorState] = useState(false);

  // When we first load, create a new game
  useEffect(() => {
    createNewGame();
  }, []);

  const createNewGame = async () => {
    var strIsPreview:string = (isPreview !== undefined) && isPreview ? "true" : "false";
    var strStartIndex:string = (startIndex !== undefined) ? startIndex : "0";
    try {
      var g = await callAPI<void, game>(`/api/play/game/new/tour/${tourId}/preview/${strIsPreview}/startindex/${strStartIndex}`, "GET");

      if (g !== undefined) {
        navigate(`/play/tour/${tourId}/g/${g.id}`, { replace: true });
      } else {
        setErrorState(true);
      }
    } catch {
      setErrorState(true);
    }
  };

  return (
    <Stack direction="row" sx={{ height: "400px" }} alignItems="center" justifyContent="center">
      
      {errorState ? (
        <Typography>
          We couldn't start a new game right now.
          <br /><br />  
          Sometimes this happens when creating a game before a ticket has been processed. 
          If you recently paid for this tour, please 
          <a href='/app'>reload the website</a> and try again, or <a href="/app/contact">contact us</a> if you continue to experience this error.
          </Typography>
          ) : 
        <CircularProgress />
      }
    </Stack>
  );
}

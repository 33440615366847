
import { Stack, Typography } from "@mui/material";
import irlItem from "data/model/geo/irlItem";
import markerSource from "data/model/geo/markerSource";
import { DivIcon, Icon, LatLngExpression } from "leaflet";

import { Fragment, useRef } from "react";
import { Marker, Popup } from "react-leaflet";
import "react-leaflet-fullscreen/styles.css";

import irlItemMarkerImage from "../../assets/map/marker-purple-circle-16.png";
import { getIRLDivIcon } from "./IRLDivIcon";


// Marker Icons
  // Default - purple circle
  const irlItemMarkerIcon = new Icon({
    iconUrl: irlItemMarkerImage,
    iconSize: [16, 16],
    iconAnchor: [8, 8],
    popupAnchor: [0, -10] // Set the anchor point for popups
  });

  export const iconForMarkerSource = (ms: markerSource, iconLabel?: string) => {
    if (ms.dataClass === "question") {
      return new DivIcon({
        className: "custom-div-icon",
        html: `<div style="position:relative; width: 24px; height: 36px;">
          <svg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_681_3)">
          <g filter="url(#filter0_d_681_3)">
          <path d="M12 0C5.383 0 0 5.383 0 12C0 18.668 8.21 29.256 11.385 35.065C11.759 35.762 12.654 35.762 13.028 35.065C15.79 29.256 24 18.668 24 12C24 5.383 18.617 0 12 0Z" fill="#FF0000"/>
          </g>
          </g>
          <defs>
          <filter id="filter0_d_681_3" x="0" y="0" width="28" height="39.5878" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="2" dy="2"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_681_3"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_681_3" result="shape"/>
          </filter>
          <clipPath id="clip0_681_3">
          <rect width="28" height="39" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          
            <div style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -70%); color: white; font-size: 12px; font-weight: 800;">${iconLabel ?? "?"}</div>
          </div>`,
        iconSize: [24, 36],
        iconAnchor: [12, 36],
        popupAnchor: [0, -40] // Set the anchor point for popups
      });
    } else if (ms.dataClass === "irlitem") {
      var i:irlItem = (ms.dataObject as irlItem);
      return getIRLDivIcon(i);
    } else {
      return irlItemMarkerIcon;
    }
  };

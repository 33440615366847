import { useAuthenticator } from "@aws-amplify/ui-react";

export type groupNames = "SuperAdmins" | "Upgraded";
export type featureNames = "ComposeAI" | "POIPlanningAssistance" | "AlphaGameTesting" | "CreatePaidProducts";

export interface RequireGroupProps {
  groupNames: groupNames[];
}

const usePermissions = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const payload = user ? user.getSignInUserSession()?.getIdToken()?.payload ?? null : null;
  const groups = payload && payload["cognito:groups"] ? (payload["cognito:groups"] as string[]) : [];

  const isUserInSomeGroups = (groupNames: groupNames[]) => {
    // Translate our friendly names into the actual group IDs
    // eslint-disable-next-line array-callback-return
    let permittedGroupIDs: string[] = groupNames.map((n) => {
      switch (n) {
        case "SuperAdmins":
          return "superadmin_users";

        case "Upgraded":
          return "upgraded_users";
      }
    });

    // Uncomment for helpful debug assessment
    // console.log("permitted group ids are:");
    // permittedGroupIDs.forEach((g) => console.log(`* ${g}`));
    // console.log("user's cognito groups are:");
    // groups.forEach((g) => console.log(`* ${g}`));

    return groups.some((gp) => permittedGroupIDs.includes(gp));
  };

  const hasPermissionFor = (featureName: featureNames): boolean => {
    switch (featureName) {
      case "ComposeAI":
        return isUserInSomeGroups(["SuperAdmins", "Upgraded"]);

      case "POIPlanningAssistance":
        return isUserInSomeGroups(["SuperAdmins", "Upgraded"]);

      case "AlphaGameTesting":
        return isUserInSomeGroups(["SuperAdmins"]);

      case "CreatePaidProducts":
        return isUserInSomeGroups(["SuperAdmins"]); // soon to be more users we hope!

      default:
        return false;
    }
  };

  // Which functions are exported
  return {
    isUserInSomeGroups,
    hasPermissionFor
  };
};

export default usePermissions;

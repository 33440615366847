
import { renderStars } from "./renderStars";

interface StarBoxProps {
  score: number; // Score from 0 to 5 in increments of 0.5
}


export default function StarBox(props:StarBoxProps) {
  return (<>
  {renderStars(props.score)}
  </>
  )
}
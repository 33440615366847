import React, { ChangeEvent, useState } from "react";

import { AppBar, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import question from "../../data/model/question";

import CloseIcon from "@mui/icons-material/Close";

import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

import "@mdxeditor/editor/style.css";
import CollectionChipEditor from "./CollectionChipEditor";
import BorderedSection from "components/chrome/BorderedSection";
import StyledMediaItem from "./StyledMediaItem";
import styledMediaItem from "data/model/styledMediaItem";
import mediaItem from "data/model/mediaItem";

interface QuestionEdAdvancedDialogProps {
  open: boolean;
  fullScreen?: boolean;
  question: question;
  onUpdateQuestion: (newQuestion: question) => void;
  onRequestClose: () => void;
}

export default function QuestionEdAdvancedDialog(props: QuestionEdAdvancedDialogProps) {
  const handleChangeContentPrivateNotes = (e: ChangeEvent<HTMLInputElement>) => {
    props.onUpdateQuestion({
      ...props.question,
      contentPrivateNotes: e.target.value
    });
  };


  const handleChangeResponseVariableName = (e: ChangeEvent<HTMLInputElement>) => {
    props.onUpdateQuestion({
      ...props.question,
      responseVariableName: e.target.value
    });
  };

  const handleChangeResponsePlaceholder = (e: ChangeEvent<HTMLInputElement>) => {
    props.onUpdateQuestion({
      ...props.question,
      placeholder: e.target.value
    });
  };

  const handleChangeMaxLengthCommitted = (event: React.SyntheticEvent | Event, newValue: number | Array<number>) => {
    props.onUpdateQuestion({
      ...props.question,
      responseMaxLength: newValue as number
    });
  };

  const handleChangeMaxLength = (event: Event, newValue: number | number[]) => {
    props.onUpdateQuestion({
      ...props.question,
      responseMaxLength: newValue as number
    });
  };

  const handleTagsUpdated = (newTags: string[]) => {
    props.onUpdateQuestion({
      ...props.question,
      tags: newTags
    });
  };

  const handleTavatarMediaItemUpdated = (newMedia?: mediaItem) => {
    props.onUpdateQuestion({
      ...props.question,
      tavatarMediaItem: newMedia
    });
  };



  const wrapMedia = (mediaItem?: mediaItem):styledMediaItem|undefined => {
    return mediaItem ?
    {
    item: mediaItem ?? null,
    style: { fit: "Contain"} // Placeholder
    } : undefined
  }


  return (
    <Dialog open={props.open} fullScreen={props.fullScreen} fullWidth maxWidth="md">
      <AppBar position="sticky">
        <DialogTitle>
          <Stack direction="row" gap={{ xs: "4px", md: "20px" }} alignItems="center" justifyContent="start" sx={{ pl: "20px", pr: "20px", width: "100%", height: "30px" }}>
            <Typography variant="h4">Expert Settings</Typography>

            <IconButton
              color="inherit"
              sx={{ ml: "auto" }} // Pushes it to the right
              onClick={props.onRequestClose}>
              <CloseIcon color="inherit" />
            </IconButton>
          </Stack>
        </DialogTitle>
      </AppBar>

      <DialogContent>
        <FormControl fullWidth>

          {props.question.responseType === "FreeText" && (
            <div>
              <Typography gutterBottom>Maximum Characters in Answer</Typography>
              <Stack direction="row" spacing="30px">
                <Slider aria-label="Max Characters" defaultValue={250} valueLabelDisplay="auto" min={1} max={1000} value={props.question.responseMaxLength} onChange={handleChangeMaxLength} onChangeCommitted={handleChangeMaxLengthCommitted} />
                {props.question.responseMaxLength}
              </Stack>
            </div>
          )}

          {props.question.responseType === "FreeText" && (
            <TextField
              id="responsePlaceholder"
              label="Placeholder text"
              sx={{ marginTop: "10px" }}
              size="small"
              helperText="Appears in the answer box before anything is typed. Use this as a hint to the sort of answer you are expecting."
              variant="filled"
              value={props.question?.placeholder ?? ""}
              fullWidth
              onChange={handleChangeResponsePlaceholder}
            />
          )}


          {props.question.responseType !== "AcknowledgementOnly" && (
            <TextField
              id="responseVariableName"
              label="Variable Name"
              sx={{ marginTop: "10px" }}
              size="small"
              helperText="Stores this response in a named variable so it can be re-used in the text of other questions."
              variant="filled"
              value={props.question?.responseVariableName ?? ""}
              fullWidth
              onChange={handleChangeResponseVariableName}
            />
          )}

          <TextField
            multiline
            rows={3}
            id="privateNotes"
            label="Private Notes"
            placeholder="e.g. check this answer does not change in Winter 2028"
            sx={{ marginTop: "10px" }}
            size="small"
            helperText="Your own private notes to help edit and maintain this question - not shown to players."
            variant="filled"
            value={props.question?.contentPrivateNotes ?? ""}
            fullWidth
            onChange={handleChangeContentPrivateNotes}
          />

          <CollectionChipEditor collection={props.question.tags ?? []} maxCollectionLength={15} itemLabel="Tag" onUpdateCollection={handleTagsUpdated} sx={{ marginTop: "16px" }} />
       
          <BorderedSection title="Avatar" sx={{mt: "20px", width: "200px",aspectRatio: "1 / 1"}}>
              <StyledMediaItem 
              sx={{m: "10px"}}
              hideStylingControls expandedHeight={200} collapsedHeight={200}
              styledItem={ wrapMedia(props.question.tavatarMediaItem) }
              onUpdateMedia={(newStyledMedia?:styledMediaItem) => {
                handleTavatarMediaItemUpdated(newStyledMedia?.item ?? undefined);
              }}
              onRequestDeleteMedia={() => { 
                return Promise.resolve(true);
              }}
              mediaLibraryQuery={{slug: {include: "sys-tavatar-"}}}
              />
              {props.question.tavatarMediaItem === undefined && (
              <Typography variant="caption" color="#555555" textAlign="center">
                The tour's default avatar will be used.
              </Typography>
              )}
              </BorderedSection>
       
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onRequestClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import mediaItem from "./mediaItem";

export type mediaFitting = "Cover"|"Fill"|"Contain"|"ScaleDown";
export const mediaFittingValues:mediaFitting[] = ["Cover","Fill","Contain","ScaleDown"];

export const BLANK_MEDIA_ITEM_STYLE:mediaItemStyle = {
  fit: "Cover"
}

export default interface mediaItemStyle {
  fit: mediaFitting;
}

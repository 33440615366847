import React from 'react';

import {  Outlet } from "react-router-dom";
import "./LayoutHomeRoot.css"

export default function LayoutHomeRoot() {

  return (
      <Outlet />
  );
}

import { Button, Grid, Typography } from "@mui/material";

import { PropsWithChildren } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import { SITE_TITLE } from "./AppHeader";

interface NavItem {
  name: string;
  href: string;
}

export default function AppFooter(props: PropsWithChildren) {
  const navItems: NavItem[] = [];

  const { authStatus, signOut } = useAuthenticator((context) => [context.authStatus, context.signOut]);

  // Nav Items
  navItems.push({
    name: "Home",
    href: "/"
  });
  navItems.push({
    name: "Privacy Policy",
    href: "/app/privacy"
  });
  navItems.push({
    name: "Credits",
    href: "/app/credits"
  });
  navItems.push({
    name: "Contact Us",
    href: "/app/contact"
  });
  navItems.push({
    name: "Terms & Conditions",
    href: "/app/terms"
  });
  if (authStatus === "authenticated") {
    // Nav items for logged in users
  } else {
    // Any nav items solely for logged-out users
  }

  return (
    <Grid className="app-footer" container sx={{ backgroundColor: "#777777", color: "#ffffff", textAlign: "center", p: "30px 10px 30px 10px" }}>
      {navItems.map((item, i) => (
        <Grid item key={`bi-${i.toString()}`} xs={12} md={6}>
          <Button size="small" color="inherit" component={Link} to={item.href} sx={{ textTransform: "none" }}>
            {item.name}
          </Button>
        </Grid>
      ))}
      <Grid item xs={12}>
        <hr />
        <Typography variant="body2">&copy;2024{SITE_TITLE} All Rights Reserved
        
        {process.env.NODE_ENV === "development" ? " (DEV ENVIRONMENT)" : ""}
        </Typography>
      </Grid>
    </Grid>
  );
}

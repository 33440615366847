import { useContext, useEffect, useState } from "react";
import "./Game.css";
import WorkingQuestionSpace from "./WorkingQuestionSpace";
import gameSnapshot, { gameSnapshotActions } from "../../data/model/gameSnapshot";
import responsePost from "../../data/model/responsePost";
import tour, { BLANK_TOUR } from "../../data/model/tour";

import { useParams } from "react-router-dom";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";


import HintManagerDialog from "./HintManagerDialog";
import { ITitlebarContext, TitlebarContext } from "./TitlebarContext";
import useAPI from "services/useHunterApi";
import gameFrame from "data/model/gameFrame";
import gameResponseMessage from "data/model/gameResponseMessage";
import { useConfirm } from "material-ui-confirm";
import RatingControlDialog from "./RatingControlDialog";
import GameResponseMsg from "./GameResponseMsg";
import GameFooter from "./GameFooter";
import { customButtonActions } from "data/model/gameResponseMessageButton";

type GameDisplayModes = "Message"|"Step";

interface GameProps {

}

function Game(props: GameProps) {
  // State
  const [tour, setTour] = useState<tour>(BLANK_TOUR);
  const [displayMode, setDisplayMode] = useState<GameDisplayModes>("Message")
  const [snapshot, setSnapshot] = useState<gameSnapshot | undefined>();
  const [gameMessage, setGameMessage] = useState<gameResponseMessage | undefined | null>();
  const [hintOpen, setHintOpen] = useState(false);
  const [isFetchingHint, setIsFetchingHint] = useState(false);
  const [errorMsgGettingGame, setErrorMsgGettingGame] = useState<string>();

  const confirm = useConfirm();

  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();

  // Toolbar
  const { setClockProps, setTitle, setBGUrl } = useContext<ITitlebarContext>(TitlebarContext);
  let { gameId, tourId } = useParams();

  const END_OF_GAME_QUESTION_ID: number = -999;
  let isHuntEnded = snapshot?.workingQuestion.id === END_OF_GAME_QUESTION_ID;

  // When the TourID changes, fetch the tour
  useEffect(() => {
    if (tourId) {
      fetchTourDef(tourId);
    }
  }, [tourId]);

  // When the gameId changes, fetch the game snapshot
  useEffect(() => {
    if (gameId) {
      fetchGameSnapshot(gameId);
    }
  }, [gameId]);

  // When the snapshot, frame or display mode changes, scroll to the top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [snapshot?.workingQuestion.id, gameMessage, displayMode]);

  const fetchGameSnapshot = (gameId: string) => {
    callAPI<void, gameFrame>(`/api/play/game/${gameId}`, "GET")
      .then((f) => {
        setErrorMsgGettingGame(undefined);
        processGameFrame(f);
      })
      .catch((error) => handleFetchError);
  };

const handleFetchError = (error:any) => {
  console.log("Caught error making APi request - setting to client-facing message.");
  setErrorMsgGettingGame(error.toString());
}

  const fetchTourDef = (tourId: string) => {
    callAPI<void, tour>(`/api/tour/${tourId}`, "GET")
      .then((hs) => {
        setTour(hs);
      })
      .catch((error) => {});
  };

  // Process a new game frame
  const processGameFrame = (f: gameFrame) => {
    f.snapshot.clientTimestamp = Date.now();
    setSnapshot(f.snapshot);
    if (f.message) {
      setGameMessage(f.message);
      setDisplayMode("Message");
    } else {
      setDisplayMode("Step");
    }
  };

  // When the snapshot or hunt summary changes, change the clock, title and BG
  useEffect(() => {
    if (snapshot) {
      setClockProps({
        elapsedSecs: snapshot.progress?.elapsedSeconds ?? 0,
        clientTimestamp: snapshot.clientTimestamp ?? 0,
        isClockActive: true
      });

      let progLabel = `Q${snapshot?.progress?.viewingQuestionIndex ?? "?"}/${snapshot?.progress?.totalQuestions ?? "?"}`;
      setTitle(`${tour.name} ${progLabel}`);
      setBGUrl(tour.backgroundMediaItem?.urlLarge ?? "/game-bg-less.jpg");

      // TODO Set MessageControl to zero
    }
  }, [snapshot?.workingQuestion.id, tourId, setTitle]);

  const handleSubmitNext = () => {
    
    if (displayMode === "Message"){
      setDisplayMode("Step");
    } else {
      submitResponse();
    }
  
  };

  const submitResponse = () => {
    callAPI<responsePost, gameFrame>(`/api/play/game/${gameId}/question/${snapshot?.workingQuestion?.id ?? "0"}/response`, "POST", {
      responseValue: snapshot?.workingQuestion?.responseValue ?? "",
      responseIndex: snapshot?.workingQuestion?.responseIndex ?? null
    }).then((f) => {
      processGameFrame(f);
    })
    .catch((error) => handleFetchError);
  }

  const handleStepBack = () => {
    callAPI<void, gameFrame>(`/api/play/game/${gameId}/question/${snapshot?.workingQuestion.id}/stepback`, "GET")
      .then((f) => {
        processGameFrame(f);
      })
      .catch((error) => handleFetchError);
  };

  const handleSkip = () => {
    confirm({
      title: "Skip this question",
      description: "This will skip the question and you may not get another chance to answer it. Are you sure?",
      confirmationText: "Skip question"
    })
      .then(() => {
        callAPI<void, gameFrame>(`/api/play/game/${gameId}/question/${snapshot?.workingQuestion.id}/skip`, "GET")
          .then((f) => {
            processGameFrame(f);
          })
          .catch((error) => handleFetchError);
      })
      .catch(() => {
        /* User cancelled the skipping */
      });
  };

  /******************** */
  // INCOMING RESPONSE VALUE/INDEX CHANGES
  /********************** */

  // A freetext response value has changed
  const handleChangeResponseValue = (s: string) => {
    if (snapshot) {
      setSnapshot({
        ...snapshot,
        workingQuestion: {
          ...snapshot.workingQuestion,
          responseValue: s
        }
      });
    }
  };

  // A multichoice selection has changed, i.e. the user tapped a canned response
  const handleChangeResponseIndex = (index: number) => {
    if (snapshot) {
      setSnapshot({
        ...snapshot,
        workingQuestion: {
          ...snapshot.workingQuestion,
          responseIndex: index,
          responseValue: snapshot.workingQuestion.cannedResponses[index].slice()
        }
      });
    }
  };

  /******************** */
  // HINTS
  /********************** */
  const handleHintClosed = () => {
    setHintOpen(false);
  };
  const handleRequestHint = () => {
    setIsFetchingHint(true);
    callAPI<void, gameFrame>(`/api/play/game/${gameId}/question/${snapshot?.workingQuestion.id}/hint`, "GET")
      .then((f) => {
        setIsFetchingHint(false);
        processGameFrame(f);
      })
      .catch((error) => {});
  };



  /******************** */
  // HELPERS
  /********************** */

  const hintAvailable: boolean = snapshot?.workingQuestion?.hintAvailable ?? false;
  
  const hintButtonDisabled = isLoading || !hintAvailable || isFetchingHint || tour.canRequestHints === false;

  // Layout note: WE ARE a child of LayoutGameRoot
  if (errorMsgGettingGame) {
    return (
      <Box p="35px" color="#eeeeee">
      <Typography variant="h1">
        Oh dear
      </Typography>
      <Typography variant="body2" mt="10px">
        There was an issue getting this game from our server.  This could be because of several reasons:
      </Typography>
      <List>
        <ListItem>
        <ListItemText>
          &bull; An internet blip - please refresh and try again
          </ListItemText>
          </ListItem>

        <ListItem>
          <ListItemText>
          &bull;You may have followed a bad link - if refreshing doesn't help, please head to our <a href='/'>home page</a> and take it from there.
          </ListItemText>
         </ListItem>
         <ListItem>
          <ListItemText>
          &bull;We might be experiencing a technical fault - please try again later.
          </ListItemText>
          </ListItem>
      </List>
      <Typography variant="body2">
        If you continue to have problems, please do <a href="/app/support">drop us a line</a> and we'll do our best to help.
      </Typography>
      <Typography variant="caption" mt="25px" color="#aaaaaa">
        {`Diagnostic message: ${errorMsgGettingGame}`}
      </Typography>
      </Box>
    )
  }
  else if (!snapshot) {
    return <></>
  } else return (
    <> 
      <Box className="game-main" component="div">
        {displayMode === "Message" && gameMessage ? (
          <GameResponseMsg 
          key={snapshot.workingQuestion.id ?? "gr"}
          msg={gameMessage} 
          typingDelaySecs={process.env.NODE_ENV === "development" ? 0.5 : 1.2}
          avatarUrl={snapshot?.workingQuestion?.tavatarMediaItem?.urlSmall ?? tour?.defaultTavatarMediaItem?.urlSmall ?? ""} />
        ) : (
          <WorkingQuestionSpace question={snapshot?.workingQuestion ?? undefined} defaultTavatarMediaItem={tour?.defaultTavatarMediaItem} onChangeResponseValue={handleChangeResponseValue} onChangeResponseIndex={handleChangeResponseIndex} />
        )}
        
        {snapshot.actions.includes("LeaveRating") && gameId && <RatingControlDialog gameId={gameId} tourName={tour.name} />}

        <HintManagerDialog
          progress={snapshot?.progress}
          isOpen={hintOpen}
          isLoading={isFetchingHint}
          hintFormatted={snapshot?.workingQuestion?.hintFormatted}
          hintMedia={snapshot?.workingQuestion?.hintStyledMedia}
          onHintClosed={handleHintClosed}
          onRequestHint={handleRequestHint}
        />
        {/* {gameMessage && <GameMessageDialog isOpen={messageOpen} message={gameMessage} onButtonClicked={handleGameMessageButtonClicked} />} */}
      </Box>

      <GameFooter
      viewing={((snapshot?.progress?.viewingQuestionIndex ?? 0) / (snapshot?.progress?.totalQuestions ?? 1)) * 100}
      actions={displayMode === "Message" ? 
          ["OK"] :
          snapshot?.actions
      }
      totalHintsRemaining={snapshot?.progress?.totalHintsRemaining}
      showHintButton={displayMode === "Message" ? 
        false :
        snapshot?.actions.includes("Hint") || (snapshot?.workingQuestion?.hintFormatted ?? "").length > 0
      } 
      disableHintButton={hintButtonDisabled}
      isLoading={isLoading}
      isLoadingHint={isFetchingHint}
      disableNextSubmitButton={
          isLoading || isFetchingHint || 
        ((snapshot?.workingQuestion?.responseValue.length ?? 0) <= 0 && snapshot?.workingQuestion.responseType !== "AcknowledgementOnly")
      }
      disableSkipButton={snapshot?.workingQuestion.responseType === "AcknowledgementOnly"}
      disableBackButton={isHuntEnded || isLoading || (snapshot?.progress && snapshot?.progress?.questionsAnswered < 1) || (snapshot?.progress?.viewingQuestionIndex ?? 0) < 1}
      onClickAction={(action:gameSnapshotActions) => {
        switch (action) {
          case "Back":
            handleStepBack();
            break;
          
          case "Skip":
            handleSkip();
            break;
          
          case "Next":
          case "Submit":
            handleSubmitNext();
            break;

          case "Hint":
            setHintOpen(!hintOpen)
            break;

        }
      }}
      />
    </>
  );
}

export default Game;

import { ChangeEvent, useContext, useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ProgressButton from "components/controls/ProgressButton";
import { channel } from "data/model/channel";
import useAPI from "services/useHunterApi";
import { SBAlert, SBAlertContext } from "components/edit/SBAlertContext";
import StyledMediaItem from "components/edit/StyledMediaItem";
import styledMediaItem from "data/model/styledMediaItem";
interface ChannelEditorProps {  
}

export default function ChannelEditor(props: ChannelEditorProps) {
  
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [channelData, setChannelData] = useState<channel>({});
  const { alert, setAlert } = useContext(SBAlertContext);

  // On first load get channel data
  useEffect(() => {
    pullChannel();
  }, []);

  const pullChannel = async () => {
    callAPI<void, channel>(`/api/channel/me`, "GET")
    .then(c => {
      setChannelData(c);
    })
    .catch(() => {})
  };


  const pushChannel = async () => {
    callAPINoResponseBody<channel>(`/api/channel/me`, "POST", channelData)
    .then(() => {
      alertGood("Saved your publishing info");
    })
    .catch(() => {})
  };

  const handleChangeValue = (e:ChangeEvent<HTMLInputElement>) => {
    setChannelData(
      {
        ...channelData,
        [e.target.name]: e.target.value
      }
    )
  }

  const alertGood = (msg:string) => {
    setAlert(
      new SBAlert({
        message: msg,
        severity: "success",
        autoHideDuration: 1500
      }));
  }

  return (
   
      <FormControl fullWidth>
      <Grid container spacing={2} mt="20px">
          <Grid xs={12} sm={6}>
            <TextField fullWidth label="Channel name" value={channelData.name ?? ""} name="name" onChange={handleChangeValue} />
            <TextField sx={{mt: "15px"}} fullWidth multiline maxRows={5} label="About" value={channelData.description ?? ""} name="description" onChange={handleChangeValue} />
          </Grid>
          <Grid xs={12}  sm={6}>
            <StyledMediaItem hideStylingControls
            collapsedHeight={100}
            expandedHeight={200}
            onUpdateMedia={(media?: styledMediaItem | undefined) => { 
              setChannelData(
                {
                  ...channelData,
                  logo:  media?.item 
                }
              )
            }}
            styledItem=  { channelData.logo ?
              {
                item: channelData.logo 
              } as styledMediaItem : undefined
             } />
          </Grid>
          
          <Grid xs={12}>
            <ProgressButton variant="contained" disabled={isLoading} waiting={isLoading} color="primary" onClick={() => pushChannel()} title="Update" />
          </Grid>
        </Grid>
      </FormControl>
    
  );
}

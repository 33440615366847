import { useState } from "react";
import "./QuestionEditorDialog.css";

import { Box, Stack, Typography, Slider } from "@mui/material";


import question from "../../data/model/question";

import "@mdxeditor/editor/style.css";


import WorkingQuestionSpace from "components/play/WorkingQuestionSpace";
import workingQuestion from "data/model/workingQuestion";
import mediaItem from "data/model/mediaItem";

interface QuestionPreviewProps {
  question: question;
  defaultTavatar?: mediaItem;
  hideControls?: boolean;
}

export default function QuestionPreview(props: QuestionPreviewProps) {
  const [previewZoom, setPreviewZoom] = useState<number>(props.hideControls ? 0.7 : 0.4);
  const previewBaseWidth = 380;
  const previewAspectRatio = 2;

  return (
    <Stack direction="row" sx={{ backgroundColor: "#bababa", mb: "10px" }}>

      {props.hideControls || (
      <Stack direction="column" flexGrow={1} p="20px" borderRight="solid 1px gray">
        <Typography variant="h6" color="#ffffff">Live Preview</Typography>
        <Slider
          sx={{ width: "200px", mt: "10px" }}
          value={previewZoom}
          min={0.2}
          max={0.6}
          step={0.05}
          marks
          onChange={(event: Event, newValue: number | number[]) => {
            setPreviewZoom(newValue as number);
          }}
        />
        <Typography variant="caption" mt="2px" color="primary">
          Zoom: {`${Math.trunc(previewZoom * 100)}%`}
        </Typography>
      </Stack>
      )}

      <Box
        sx={{
          ml: props.hideControls ? 0 : "auto",
          width: `${previewBaseWidth * previewZoom}px`,
          height: `${previewBaseWidth * previewAspectRatio * previewZoom}px`,
          position: "relative",
          // backgroundColor: "gray",
          overflow: "hidden",
          pointerEvents: "none",
          border: "solid 1px black"
        }}>
        <WorkingQuestionSpace
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            transform: `scale(${previewZoom})`,
            transformOrigin: "0 0",
            overflow: "hidden",

            width: `${previewBaseWidth}px`,
            height: `${100 / previewZoom}%`,
            backgroundImage: "url('/media/sized/S_00000000-0000-0000-0000-200000000002.jpg')",
            color: "#ffffff"
          }}
          defaultTavatarMediaItem={props.defaultTavatar ?? {
            url: "/media/sized/S_00000000-0000-0000-0000-100000000002.png",
            urlLarge: "/media/sized/L_00000000-0000-0000-0000-100000000002.png",
            urlMedium: "/media/sized/M_00000000-0000-0000-0000-100000000002.png",
            urlSmall: "/media/sized/S_00000000-0000-0000-0000-100000000002.png",
            urlThumb: "/media/sized/XS_00000000-0000-0000-0000-100000000002.png",
            id: "",
            canEditMetadata: false,
            description: "",
            tags: [],
            title: "",
            isDirty: false,
            isNewlyUploaded: false
          }}
          question={props.question as workingQuestion}
          onChangeResponseIndex={() => {}}
          onChangeResponseValue={() => {}}
        />
      </Box>
    </Stack>
  );
}

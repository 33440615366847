import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import {Geocoder, geocoders} from 'leaflet-control-geocoder';
import "./GeocoderLeafletControl.css"

export interface GeocoderLeafletControlProps {
  onUpdatePosition: (newPosition: LatLngExpression) => void;
}

export default function GeocoderLeafletControl(props: GeocoderLeafletControlProps) {
  const map = useMap();

  useEffect(() => {

    new Geocoder({
      geocoder: new geocoders.Nominatim(),
      position: 'bottomleft',
      placeholder: "City, town, address, place",
      collapsed: false,
      defaultMarkGeocode: false,
      showResultIcons: true
    })
    
    .on("markgeocode", function (e) {
      props.onUpdatePosition(e.geocode.center);
      map.flyTo(e.geocode.center, 18, {duration: 2});
    })

    .addTo(map);

  }, [map]);

  return null;
}

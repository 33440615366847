import React, { ChangeEvent, Fragment, useEffect } from "react";

import { Button, ButtonGroup, Container, IconButton, List, ListItem, TextField, useTheme } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import { immutableMove } from "utils/utils-arrays";
import { Divider } from "@aws-amplify/ui-react";
import { TTTheme } from "@mui/material/styles/createPalette";


interface GenericCollectionEditorProps<T> {
  collection: T[];
  onUpdateCollection: (updatedCollection: T[]) => void;
  canMoveItems?: boolean;
  canDeleteItems?: boolean;
  itemNameLabel?: string; // displayed on the 'Add X' button
  onRequestNewItem: () => T;
  onRenderItem: (item: T, cbUpdateItem:(newItem: T)=>void ) => React.ReactNode;
  // onCompareItems: (a: T, b: T) => boolean;
}

export default function GenericCollectionEditor<T>(props: GenericCollectionEditorProps<T>) {
  const theme = useTheme<TTTheme>();
  
  const handleUpdatedItem = (newItem: T, index: number) => {
    props.onUpdateCollection(props.collection.map((item, i) => (i === index ? newItem : item)));
  };

  const handleClickAddItemButton = () => {
    // Clone the canned responses and add a new blank entry
    const moreItems: T[] = Object.assign([], props.collection);

    moreItems.push(props.onRequestNewItem());

    props.onUpdateCollection(moreItems);
  };

  const handleClickItemButtonDelete = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    const newItems: T[] = Object.assign([], props.collection);
    newItems.splice(index, 1);

    props.onUpdateCollection(newItems);
  };

  const handleClickItemButtonMove = (event: React.MouseEvent<HTMLButtonElement>, index: number, delta: number) => {
    const newItems = immutableMove(props.collection as [], index, index + delta);

    props.onUpdateCollection(newItems);
  };

  return (
    <Container disableGutters>
      <List dense>
        {props.collection.map((item, index) => (
          <Fragment key={`gce-item-${index}`}>
          <ListItem
            sx={{
              backgroundColor: theme.palette.grey[100],
              margin: "8px 0px 0px 0px",
              padding: "12px"
            }}
            key={`cr-li-${index}`}
            >

            

            {/* DISPLAY ITEM AND HOOK UP CHANGE EVENTS */}
            {props.onRenderItem(item, ( newItem => { handleUpdatedItem(newItem, index); }))}


            
            <ButtonGroup>
                {props.canMoveItems && (
                  <>
                    <IconButton disabled={index === 0} aria-label="Move up" onClick={(e) => handleClickItemButtonMove(e, index, -1)}>
                      <ArrowCircleUpIcon />
                    </IconButton>
                    <IconButton disabled={index === props.collection.length - 1} aria-label="Move down" onClick={(e) => handleClickItemButtonMove(e, index, 1)}>
                      <ArrowCircleDownIcon />
                    </IconButton>
                  </>
                )}

                {props.canDeleteItems && (
                  <IconButton edge="end" aria-label="Delete" onClick={(e) => handleClickItemButtonDelete(e, index)}>
                    <DeleteForeverIcon />
                  </IconButton>
                )}
              </ButtonGroup>

          </ListItem>
          
          </Fragment>
        ))}
      </List>

      <Button fullWidth variant="outlined" color="primary" endIcon={<AddIcon />} onClick={handleClickAddItemButton}>
        Add {props.itemNameLabel}
      </Button>
    </Container>
  );
}

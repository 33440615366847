import { PropsWithChildren } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";

import ImageIcon from "@mui/icons-material/Image";

import Box from "@mui/material/Box";
import { Chip, Typography } from "@mui/material";
import tour from "data/model/tour";

interface TourListItemProps {
  tour: tour;
  href: string;
  chipLabel?: string;
  hideChannelName?: boolean;
}
export default function TourListItem(props: PropsWithChildren<TourListItemProps>) {
  
    return (
    <>
    <ListItem disableGutters disablePadding key={`tour-li--${props.tour.id}`}>
    <ListItemButton href={props.href}>
      <ListItemAvatar>
        <Avatar src={props.tour.mediaItems.length > 0 ? props.tour.mediaItems[0]?.urlThumb ?? "" : ""}>
          <ImageIcon />
        </Avatar>
      </ListItemAvatar>
      <Box>
      <Typography variant="body2" fontWeight="700">
        {props.tour.name}
      </Typography>
      <Box>
        {props.chipLabel ? 
        <Chip color="primary" size="small" label={props.chipLabel} />  
        : props.hideChannelName ? (
          <></>
        ) :
        <Typography variant="caption"> 
        {props.tour.channel.name}
        </Typography> 
        }
      </Box>
      </Box>
    </ListItemButton>
    {props.children}
    </ListItem>
    <Divider  />
    </> );
  }

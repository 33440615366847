import { PropsWithChildren } from "react"
import { Typography } from "@mui/material";


export default function PageTitle(props: PropsWithChildren) {

    return (
        <Typography variant="h1" color="primary" sx={{mb: "10px"}}>
        {props.children}
        </Typography>
    );
}
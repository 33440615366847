import React, { EventHandler, useEffect, useRef, useState } from "react";
import mediaItem from "data/model/mediaItem";
import { Box, Button, CircularProgress, Stack, SxProps } from "@mui/material";

import useAPI from "services/useHunterApi";

interface MediaUploaderProps {
  onUploadedNewMedia: (media: mediaItem) => void;
  sx?: SxProps;
}

function MediaUploader(props: MediaUploaderProps) {
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>();

  const { callAPI } = useAPI();

  const inputFile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fileName && fileName.length > 0) {
      uploadFile();
    }
  }, [fileName]);

  // Test file upload
  const registerFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLInputElement;
    if (target?.files != null) {
      const tFile: File = target?.files[0];

      setFile(tFile);
      setFileName(tFile?.name);
    }
  };

  const resetInputFile = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
    }
  };

  const uploadFile = () => {
    if (file && fileName) {
      var formData: FormData = new FormData();

      formData.append("id", "NEW");
      formData.append("file", file!);
      formData.append("fileName", fileName!);
      formData.append("description", ""!);
      // formData.append("tags", ''!);

      setIsUploading(true);

      try {
        callAPI<FormData, mediaItem>("/api/media", "POST", formData, "FormData")
          .then((m: mediaItem) => {
            console.log("Uploaded");
            props.onUploadedNewMedia(m);

            // Reset input control
            resetInputFile();
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);
          });
      } catch (ex) {
        console.log(ex);
        setIsUploading(false);
      }
    }
  };

  return (
    <Stack component="div" direction="column" className="mediaitems-container" gap="5px" sx={props.sx}>
      <h6>From your device</h6>
      <input
        ref={inputFile}
        disabled={isUploading}
        id="upload-image"
        // accept="*"
        accept="image/*"
        type="file"
        onChange={registerFile}
      />
      <Stack direction="row" gap="10px" alignContent="center">
        <Button variant="outlined" onClick={uploadFile} disabled={isUploading || (inputFile.current?.value.length ?? 0) < 1}>
          Upload
        </Button>
        {isUploading && <CircularProgress size="24px" sx={{ marginTop: "6px" }} />}
      </Stack>
    </Stack>
  );
}

export default MediaUploader;

import tour, { BLANK_TOUR } from "../tour";
import game from "../game";
import questionResponses from "./questionResponses";

interface huntResponses {
    huntSummary: tour;
    updatedTime: Date;
    games: game[];
    responses: questionResponses[]
}

export const BLANK_HUNT_RESPONSES:huntResponses = {
    huntSummary: BLANK_TOUR,
    updatedTime: new Date(),
    games: [],
    responses: []
}

export default huntResponses
import { ChangeEvent } from "react";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Stack, Switch, Typography } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from '@mui/icons-material/Help';
import { qcJobDefinition } from "data/model/compose/composeContentRequest";

interface QCJobDefinitionProps {
  jobDefinition: qcJobDefinition;
  defaultExpanded?: boolean;
  onUpdate: (value: qcJobDefinition) => void;

}

export default function QCJobDefinition(props: QCJobDefinitionProps) {
  const handleChangeFieldValue = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleChangeFieldNameAndValue(e.target.name, e.target.value);
  };

  const handleChangeFieldNameAndValue = (name: string, value: any) => {
    const newOptions: qcJobDefinition = {
      ...props.jobDefinition,
      [name]: value
    };

    props.onUpdate(newOptions);
  };

  return props.jobDefinition ? (
    <FormControl fullWidth>
      <Stack direction="column" gap="20px" flexGrow="1" minHeight="200px" paddingTop="10px">


        <Accordion
        defaultExpanded={props.defaultExpanded}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack direction="row" gap="10px">
              <HelpIcon fontSize="small" />
              <Typography variant="caption">Question-related</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
              <InputLabel id="lab-responsetype">Type of questions</InputLabel>
              <Select value={props.jobDefinition.responseType} labelId="lab-responsetype" label="Type of questions" name="responseType" fullWidth onChange={(e) => handleChangeFieldNameAndValue(e.target.name, e.target.value)}>
                <MenuItem value={"MultiChoice"}>Multichoice</MenuItem>
                <MenuItem value={"FreeText"}>Free text entry</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
              <InputLabel id="lab-difficulty">Difficulty</InputLabel>
              <Select value={props.jobDefinition.difficulty} labelId="lab-difficulty" label="Difficulty" name="difficulty" fullWidth onChange={(e) => handleChangeFieldNameAndValue(e.target.name, e.target.value)}>
                <MenuItem value={"Easy"}>Easy</MenuItem>
                <MenuItem value={"Standard"}>Normal</MenuItem>
                <MenuItem value={"Hard"}>Hard</MenuItem>
                <MenuItem value={"VeryHard"}>Impossible</MenuItem>
              </Select>
            </FormControl>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="includeExplanations"
                    checked={props.jobDefinition.includeExplanations ?? false}
                    onChange={(e, checked) => {
                      handleChangeFieldNameAndValue(e.target.name, checked);
                    }}
                  />
                }
                label="Include explanations of answers"
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>

      </Stack>
    </FormControl>
  ) : (
    <></>
  );
}

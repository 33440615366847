import { Authenticator, CheckboxField } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";

import logoNoStrapBigOnDark2x from "../../assets/logo/tt7-logo-nostrap-big-on-dark@2x.png";
import logoNoStrapBigPrimary2x from "../../assets/logo/tt7-logo-nostrap-big-primary@2x.png";

  export const ttAuthFormFields = {
    signIn: {
      // email: {
      //   placeholder: "Your Email address",
      //   isRequired: false,
      //   label: "Email"
      // },
      // username: {
      //   placeholder: "Your Email or Phone Number",
      //   isRequired: true,
      //   label: "Email or Phone Number:"
      // }
    },
    signUp: {
      email: {
        placeholder: "Your Email address",
        isRequired: true,
        label: "Email"
      }
      // username: {
      //   placeholder: "Your Email or Phone Number",
      //   isRequired: true,
      //   label: "Email or Phone Number:"
      // },
    }
  };

  export const ttAuthComponents = {
    Header() {
      return (
        <Box
          sx={{
            marginTop: {xs: "15px", sm: "50px"},
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            marginBottom: "20px"
          }}>
          <img alt="Tour logo" src={logoNoStrapBigPrimary2x} width="350px" />
        </Box>
      );
    },
    Footer() {
      return (
        <Box sx={{marginBottom: {xs: "15px", sm: "50px"}}}>
            &nbsp;
        </Box>
      );
    },
    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();

        const termsAndConditionsLink = (
          <a href="/app/terms" target="_blank">
            Terms & Conditions
          </a>
        );

        return (
          <>
            {/* Re-use default `Authenticator.SignUp.FormFields` */}
            <Authenticator.SignUp.FormFields />

            {/* Append & require Terms & Conditions field to sign up  */}
            <CheckboxField
              errorMessage={validationErrors.acknowledgement as string}
              hasError={!!validationErrors.acknowledgement} // we use an empty string
              name="acknowledgement"
              value="yes"
              label={<>I agree with the {termsAndConditionsLink}</>}
            />
          </>
        );
      }
    }
  };

  export const ttAuthservices = {
    async validateCustomSignUp(formData: any) {
      if (!formData.acknowledgement) {
        // Each named control can have an acknowledgement error returned
        return {
          acknowledgement: "" // As per our logic above in CheckboxField, empty string triggers validation failure without displaying a label
        };
      }
    }
  };

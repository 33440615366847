import { Typography, Stack, IconButton } from "@mui/material";
import tour from "data/model/tour";
import { SBAlert, SBAlertContext } from "components/edit/SBAlertContext";

import { EmailShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";

import { EmailIcon, FacebookIcon, LinkedinIcon, PinterestIcon, RedditIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { useContext } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export interface TourShareCardProps {
  tour: tour;
  minimal?: boolean;
}

export default function TourShareCard(props: TourShareCardProps) {
  const { alert, setAlert } = useContext(SBAlertContext);

  const _url = props.tour.publishURL ? `${window.location.protocol}//${window.location.host}/${props.tour.publishURL}` : undefined;

  const _shareDesc = `I'm inviting you to check out '${props.tour.name}' - an experience on Treasure Tours`;

  return props.tour && _url ? (
    <>
    {props.minimal || (
      <>
      <Typography variant="body2">This tour can be taken online at this web link:</Typography>
      <Typography variant="caption">
        <a target="_new" href={_url}>
          {_url}
        </a>
      </Typography>
      <IconButton
        sx={{ ml: "10px" }}
        onClick={() => {
          navigator.clipboard.writeText(_url);
          setAlert(
            new SBAlert({
              message: "Web link copied to clipbard.",
              severity: "success",
              autoHideDuration: 3500
            })
          );
        }}>
        <ContentCopyIcon />
      </IconButton>
      <Typography variant="body2" sx={{ mt: "20px" }}>
        Use the buttons below to share via email, socials and more
      </Typography>
      </>
)}

      <Stack direction="row" flexWrap="wrap" gap="5px" sx={{ mt: "20px" }}>
        <EmailShareButton url={_url} subject="Invitation to take a tour" body={_shareDesc} separator="                      " title="Email">
          <EmailIcon size={42} round />
        </EmailShareButton>

        <WhatsappShareButton url={_url} title={props.tour.name}>
          <WhatsappIcon size={42} round />
        </WhatsappShareButton>

        <TwitterShareButton url={_url} title={props.tour.name} via="TreasureTours" hashtags={["gaming", "treasuretours", "unwalk"]}>
          <TwitterIcon size={42} round />
        </TwitterShareButton>

        <FacebookShareButton url={_url} quote={_shareDesc} title="Facebook">
          <FacebookIcon size={42} round />
        </FacebookShareButton>

        {/* <FacebookMessengerShareButton url={_url} title="Facebook Messenger">
            <FacebookMessengerIcon size={42} round />
          </FacebookMessengerShareButton> */}

        <LinkedinShareButton url={_url} title={props.tour.name}>
          <LinkedinIcon size={42} round />
        </LinkedinShareButton>

        <RedditShareButton url={_url} title={props.tour.name}>
          <RedditIcon size={42} round />
        </RedditShareButton>

        {props.tour.mediaItems.length > 0 && (
          <PinterestShareButton url={_url} media={props.tour.mediaItems[0].url} title="Pinterest">
            <PinterestIcon size={42} round />
          </PinterestShareButton>
        )}

        <TelegramShareButton url={_url} title={props.tour.name}>
          <TelegramIcon size={42} round />
        </TelegramShareButton>
      </Stack>
    </>
  ) : (
    <></>
  );
}

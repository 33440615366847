import React, { useState } from "react";
import "./ResponseMultiChoice.css";

import { Box, Button, ButtonGroup, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export interface ResponseMultiChoiceProps {
  cannedResponses: string[];
  selectedIndex: number|undefined;
  onPushOption: (a: number) => void;
}

function ResponseMultiChoice(props: ResponseMultiChoiceProps) {
  return (
      <Stack gap="5px">
        
        {props.cannedResponses.map((response, i) => (
          <Button key={`mc-button-${i}`}
          fullWidth   
          sx={{
            background: "#7304a8"
          }}
          className={`multichoice-button ${
            (props.selectedIndex === i) ?
              "selected" : ""
              }`}
            variant="contained"
            startIcon={
              (props.selectedIndex === i) ?
              (<CheckCircleIcon />) : 
              (<CheckCircleOutlineIcon/>) 
            }
            onClick={() => props.onPushOption(i)} 
          >
            {response}
          </Button>
        ))}
      </Stack>
  );
}

export default ResponseMultiChoice;

import {  useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { KEY_POST_LOGIN_URL } from "components/auth/RequireAuth";

// This component is rendered after login and conditionally redirects back to a particular page, if such a key is stored
export default function PostLogin() {
  const [newURL, setNewURL] = useState<string>("");
  const navigate = useNavigate();

  console.log('PL render..');
  // There may be a destination URL stored
  useEffect(() => {
    console.log('PL UE - check key');
    var item:string|null = null;
    try {
      item = window.sessionStorage.getItem(KEY_POST_LOGIN_URL);
    }
    catch {}

    if (item)
    {
      console.log(`PL UE - got key as ${item}`);
      setNewURL(item);
    } else {
      console.log(`PL UE - no key found; using default of '/app'`);
      setNewURL("/app");
    }

  }, []);

  useEffect(() => {
    if (newURL.length > 0) {
      navigate(newURL, { replace: true });
      // window.location.href = newURL;
    }
  }, [newURL]);
  return (<></>);
}
import { useState } from "react";

import {
  Box,
  Chip,
  Container,
  SxProps,
  TextField} from "@mui/material";

import "./CollectionChipEditor.css";


import BorderedSection from "components/chrome/BorderedSection";


interface CollectionChipEditorProps {
  collection: string[];
  onUpdateCollection: (updatedCollection:string[]) => void;
  itemLabel?: string
  maxCollectionLength: number
  disabled?: boolean,
  sx?: SxProps
}

export default function CollectionChipEditor(props: CollectionChipEditorProps) {

const [newTag, setNewTag] = useState<string>("");

  const handleClickAddItemButton = () => {

    if (props.collection.length < props.maxCollectionLength) {
      // Clone the canned responses and add the new typed tag
      const moreItems: string[] = Object.assign(
        [],
        props.collection
      );
      if (
        newTag.length > 0 
      ) {
        moreItems.push(newTag);
      }

      props.onUpdateCollection(moreItems);
      setNewTag("");
    }
  };

  const handleClickItemButtonDelete = (
    index: number
  ) => {
    if (props.disabled) return;
    
    const newItems: string[] = Object.assign(
      [],
      props.collection
    );
    newItems.splice(index, 1);

    props.onUpdateCollection(newItems);
  };



  return (
    <Container className="string-array-options"
    disableGutters sx={props?.sx}>

      <BorderedSection title="Tags">

        <Box sx={{padding: "10px;"}}>
        {props.collection.map((r, index) => (
          <Chip
          sx={{marginBottom: "15px", marginRight: "10px"}}
          color="primary"
          onDelete={() => { handleClickItemButtonDelete(index)}}
          key={`cr-li-${index}`}
          label={r}
          />
          ))
        }

        <TextField
        disabled = {props.disabled ||
                   props.collection.length >= props.maxCollectionLength}
        sx={{
          width: "100%",
          paddingRight: "25px"
        }}
        fullWidth
        inputProps={{
          maxLength: 25,
        }}
        label={`Add ${props.itemLabel ?? "Item"}`}
        value={newTag}
        onChange={(e) => setNewTag(e.target.value)}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            handleClickAddItemButton();
            ev.preventDefault();
          }
        }}
        />
        </Box>

      </BorderedSection>


 


  </Container>

                    
  );
}

import React, { ChangeEvent, useEffect } from "react";

import {
  Button,
  ButtonGroup,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  TextField} from "@mui/material";

import "./CollectionEditor.css";

import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import { immutableMove } from "utils/utils-arrays";


interface CollectionEditorProps {
  collection: string[];
  onUpdateCollection: (updatedCollection:string[]) => void;
  canMoveItems?: boolean;
  canDeleteItems?: boolean;
  itemLabel?: string
}

export default function CollectionEditor(props: CollectionEditorProps) {

  const handleChangeItem = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    props.onUpdateCollection( 
      (props.collection.map((item, i) =>
      i === index ? e.target.value : item)), 
    );
  }

  const handleClickAddItemButton = () => {
    // Clone the canned responses and add a new blank entry (unless the final item is already blank)
    const moreItems: string[] = Object.assign(
      [],
      props.collection
    );
    if (
      moreItems.length === 0 ||
      moreItems[moreItems.length - 1].length > 0
    ) {
      moreItems.push("");
    }

    props.onUpdateCollection(moreItems);
  };

  const handleClickItemButtonDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    const newItems: string[] = Object.assign(
      [],
      props.collection
    );
    newItems.splice(index, 1);

    props.onUpdateCollection(newItems);
  };

  const handleClickItemButtonMove = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
    delta: number
  ) => {
    const newItems = immutableMove(
      props.collection as [],
      index,
      index + delta
    );

    props.onUpdateCollection(newItems);
  };



  return (
    <Container className="string-array-options"
    disableGutters
    >
      <List dense>
        {props.collection.map((r, index) => (
          <ListItem
            sx={{
              margin: "0",
              padding: "0",
              paddingRight: "20px"
            }}
            key={`cr-li-${index}`}
            secondaryAction={
              <ButtonGroup>
                
                {props.canMoveItems && (
                  <>
                <IconButton
                  disabled={index === 0}
                  aria-label="Move up"
                  onClick={(e) =>
                    handleClickItemButtonMove(e, index, -1)
                  }>
                  <ArrowCircleUpIcon />
                </IconButton>
                <IconButton
                  disabled={
                    index ===
                    props.collection.length - 1
                  }
                  aria-label="Move down"
                  onClick={(e) =>
                    handleClickItemButtonMove(e, index, 1)
                  }>
                  <ArrowCircleDownIcon />
                </IconButton>
                </>
                )}

                {props.canDeleteItems && (
                <IconButton
                  edge="end"
                  aria-label="Delete"
                  onClick={(e) =>
                    handleClickItemButtonDelete(e, index)
                  }>
                  <DeleteForeverIcon />
                </IconButton>
                )}
              </ButtonGroup>
            }>

            <TextField
              sx={{
                width: "100%",
                paddingRight: "25px"
              }}
              autoFocus={ r === "" && index === (props.collection.length-1) }
              key={index}
              id="contentFormatted"
              label={props.itemLabel ?? "Choice"}
              variant="filled"
              value={r ?? ""}
              onChange={(e) =>
                handleChangeItem(e, index)
              }
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleClickAddItemButton();
                  ev.preventDefault();
                }
              }}
            />
          </ListItem>
        ))}
      </List>

      <Button
        fullWidth
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        onClick={handleClickAddItemButton}>
        Add {props.itemLabel}
      </Button>


  </Container>

                    
  );
}

import { helpForPlayMode, tourPlayModes } from "../../data/model/tour";


import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";


export interface TourPlayModeSelectProps {
  value: tourPlayModes;
  onUpdated: (newMode:tourPlayModes) => void;
}

export default function TourPlayModeSelect(props: TourPlayModeSelectProps) {

  return (

    <FormControl fullWidth sx={{ marginBottom: "20px"}}>
    <div>
      <InputLabel id="playMode-label">Quiz Mode</InputLabel>
      <Select
        id="questionType"
        value={props.value}
        labelId="playMode-label"
        label="Type of game"
        fullWidth
        onChange={(e) => { props.onUpdated(e.target.value as tourPlayModes)  }}>
        <MenuItem value={"RepeatUntilCorrect"}>
          Repeat questions until correct
        </MenuItem>
        <MenuItem value={"OneGoPerQuestion"}>
          One go per question
        </MenuItem>
        <MenuItem value={"FreeAnswersNoFeedback"}>
          Free answering
          </MenuItem>
      </Select>
      <Typography variant="body2">
        { helpForPlayMode(props.value) }
      </Typography>
    </div>
    </FormControl>

    
  );
}


import { Box, Typography } from "@mui/material";
import ToursBrowser from "../app/ToursBrowser";
import { PageBox } from "components/chrome/PageBox";

export default function DiscoverToursPage() {
  return (
    <PageBox>
      <Typography variant="h1" color="primary">
        Featured Tours
      </Typography>
      <ToursBrowser sx={{ mt: "10px" }} />
    </PageBox>
  );
}

import { LatLngLiteral } from "leaflet";
import tour, { BLANK_TOUR } from "./tour";
import { waypoint } from "./waypoint";
import { composeContentRequest, nsJobDefinition } from "./compose/composeContentRequest";
import { DEFAULT_COMPOSE_OPTIONS, DEFAULT_NS_JOBDEF } from "components/edit/compose/blankComposeOptions";

export const BLANK_CREATE_TOUR_WIZARD_DATA:createTourWizardData = {
  tour: BLANK_TOUR,
  initialWaypoint: undefined,
  shouldCreateFirstStepToWaypoint: true,
  shouldPopulateUsingComposeRequest: false,
  composeRequest: {
    jobDefinition: DEFAULT_NS_JOBDEF,
    options: DEFAULT_COMPOSE_OPTIONS,
    tourID: 0
  }
};

export default interface createTourWizardData {
  // A Hunt to be updated
  tour: tour;

  // If populated, creates an initial waypoint question
  initialWaypoint?: waypoint;
  shouldCreateFirstStepToWaypoint?: boolean;

  shouldPopulateUsingComposeRequest?: boolean;
  composeRequest?: composeContentRequest<nsJobDefinition>;
}


import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, DialogActions, DialogContent, FormControl, IconButton, ImageList, ImageListItem, ImageListItemBar, SxProps, TextField, Tooltip, useMediaQuery, useTheme } from "@mui/material";

import EditNoteIcon from "@mui/icons-material/EditNote";
import PreviewIcon from '@mui/icons-material/Preview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import mediaItem from "data/model/mediaItem";
import MediaUploader from "./MediaUploader";
import useAPI from "services/useHunterApi";
import MediaEditorDialog from "./MediaEditorDialog";
import { SBAlertContext } from "./SBAlertContext";
import { useConfirm } from "material-ui-confirm";
import NoDataPane from "components/chrome/NoDataPane";
import { mediaLibraryQuery, mediaLibrarySelectQuery } from "data/model/mediaLibraryQuery";
import { TTTheme } from "@mui/material/styles/createPalette";
import RequirePermission from "components/auth/RequirePermission";
import ProgressButton from "components/controls/ProgressButton";
import { composeContentRequest, imageGenDefinition } from "data/model/compose/composeContentRequest";
import { DEFAULT_COMPOSE_OPTIONS } from "./compose/blankComposeOptions";
import { ButtonGroup } from "@aws-amplify/ui-react";

interface MediaLibraryProps {
  onRequestCancel: () => void;
  onConfirmMedia: (media: mediaItem) => void;
  hideActionButtons?: boolean;
  disableSelection?: boolean;
  query?: mediaLibraryQuery;
  sx?: SxProps;
}

export default function MediaLibrary(props: MediaLibraryProps) {
  const [mediaItems, setMediaItems] = useState<mediaItem[]>([]);
  const [selectedMediaId, setSelectedMediaId] = useState<string>("");
  const [editingMediaId, setEditingMediaId] = useState<string>("");
  const [generateImgDescription, setGenerateImgDescription] = useState<string>("");

  const imageList = useRef<any>();
  const selectedImageListItem = useRef<any>();

  const confirm = useConfirm();

  const { alert, setAlert } = useContext(SBAlertContext);
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();

  // Fetch a list of all media items
  useEffect(() => {
    fetchMedia();
  }, []);

  // When media is selected, scroll to it
  useEffect(() => {
    const sm = mediaItems.find((m) => m.id === selectedMediaId);
    if (sm?.isNewlyUploaded) {
      selectedImageListItem.current?.scrollIntoView();
    }
  }, [selectedMediaId]);

  const BLANK_QUERY:mediaLibraryQuery = { };
  const fetchMedia = () => {
    callAPI<mediaLibraryQuery, mediaItem[]>(`/api/media/query`, "POST", props.query ?? BLANK_QUERY)
      .then((items) => {
        setMediaItems(items);
      })
      .catch((error) => {});
  };

  const selectedMedia: mediaItem | undefined = mediaItems.find((m) => m.id === selectedMediaId);

  const editingMedia: mediaItem | undefined = mediaItems.find((m) => m.id === editingMediaId);

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Changes to be saved?
    if (mediaItems.some((mi) => mi.isDirty)) {
      confirm({
        title: "Discard changes?",
        description: "There are unsaved changes to some of the images - close without saving?",
        confirmationText: "Close"
      })
        .then(() => {
          props.onRequestCancel();
        })
        .catch(() => {
          /* User cancelled the close */
        });
    } else {
      // Nothing was selected, or the thing that was selected had no changes to be saved
      props.onRequestCancel();
    }
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (selectedMediaId) {
      props.onConfirmMedia(mediaItems.find((m) => m.id === selectedMediaId)!);
    }
  };

  const handleClickImage = (e: React.MouseEvent<HTMLImageElement>, mediaId: string) => {
    handleClickImageMedia(mediaId);
  };

  const handleClickImageMedia = (mediaId: string) => {
    if (props.disableSelection) return;

    // Changes to be saved?
    if (selectedMedia && selectedMedia.isDirty) {
      confirm({
        title: "Discard changes?",
        description: "There are unsaved changes to the seleted image - continue without saving?",
        confirmationText: "Continue"
      })
        .then(() => {
          setSelectedMediaId(mediaId);
        })
        .catch(() => {
          /* User cancelled the change */
        });
    } else {
      // Nothing was selected, or the thing that was selected had no changes to be saved
      setSelectedMediaId(mediaId);
    }
  };

  const handleOnMediaEditorUpdatedMedia = (newMedia: mediaItem) => {
    const newMediaItems = mediaItems.map((mi) => {
      return mi.id === newMedia.id ? newMedia : mi;
    });
    setMediaItems(newMediaItems);
    // Close editor
    setEditingMediaId("");
  };

  const handleOnUploadedMedia = (m: mediaItem) => {
    m.isNewlyUploaded = true;
    m.canEditMetadata = true;
    const moreMedia = Object.assign([], mediaItems);
    moreMedia.unshift(m);
    setMediaItems(moreMedia);
    setSelectedMediaId(m.id);
  };

  const handleOnMediaEditorDeletedMedia = (deletionID: string) => {
    var lessMedia = Object.assign(
      [],
      mediaItems.filter((m) => {
        return m.id !== deletionID;
      })
    );

    setEditingMediaId("");
    setMediaItems(lessMedia);
    setSelectedMediaId("");
  };

  const theme = useTheme<TTTheme>();
  const mqLargeOrUp = useMediaQuery(theme.breakpoints.up("lg"));
  const mqMedOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const mqSmallOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <DialogContent sx={props.sx}>

      {mediaItems.length < 1 ? (
              <NoDataPane title={isLoading ? "Loading..." : "No images found"} icon={isLoading ? <EditNoteIcon /> : <CircularProgress size="28px" color="primary" />} sx={{height: "200px", backgroundColor: "#e8e8e8"}} />
            ) : (
          <ImageList ref={imageList} cols={mqLargeOrUp ? 4 : mqMedOrUp ? 3 : 2} variant="standard"
          sx={{ backgroundColor: "#e8e8e8", minHeight: "200px"}}
          >
           { mediaItems.map((item) => (
              <ImageListItem
                key={item.id}
                ref={item.id === selectedMediaId ? selectedImageListItem : null}
                sx={{
                  aspectRatio: "1 / 1",
                  overflow: "hidden",
                  border: item.id === selectedMediaId ? "4px solid" : "none",
                  borderColor: "hotpink"
                }}>
                <img src={item.urlMedium} alt={item.description} loading="lazy" onClick={(e) => handleClickImage(e, item.id)} />
                <ImageListItemBar
                  position="bottom"
                  title={item.title ?? "Untitled"}
                  onClick={(e) => handleClickImageMedia(item.id)}
                  subtitle={mqSmallOrUp ? item.description : undefined}
                  actionIcon={
                    <ButtonGroup>
                    <Tooltip title="View in new window">
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.84)" }}
                        aria-label={`View image`}
                        href={item.urlLarge}
                        target={`media-${item.id}`}>
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit details">
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.84)" }}
                        aria-label={`Edit details`}
                        onClick={() => {
                          setEditingMediaId(item.id);
                        }}>
                        <EditNoteIcon />
                      </IconButton>
                    </Tooltip>
                    </ButtonGroup>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>)}

      </DialogContent>
      
        <DialogActions sx={{width: "100%", pl:"24px", pr:"24px", pt: "10px", pb: "10px", justifyContent: "space-between", alignItems: "end", gap: "10px"}}>
        
        <Grid container spacing={2} flexGrow={1}>
          <Grid xs={12} sm={6}>
          <MediaUploader onUploadedNewMedia={handleOnUploadedMedia} 
            sx={{  height: "130px", maxWidth: mqSmallOrUp ? "1200px" : "240px", flexGrow:"1", backgroundColor: "#e8e8e8", p: "15px" }} />
          </Grid>
          <RequirePermission name="ComposeAI">
          <Grid xs={12} sm={6}>
          <Box width="100%">
            <FormControl fullWidth>
          <TextField fullWidth
          multiline
          rows={2}
          label="Generate image"
          value={generateImgDescription}
          onChange={(e) => { setGenerateImgDescription(e.target.value) }}
          />
          <ProgressButton variant="outlined" 
          waiting={isLoading}
          title="Generate" 
          sx={{width: "100%"}}
          onClick={() => {
            callAPI<composeContentRequest<imageGenDefinition>, mediaItem>(`/private/api/compose/image`, "POST", {
              jobDefinition: {
                description: generateImgDescription
              },
              options: DEFAULT_COMPOSE_OPTIONS,
            }).then((m) => {
                handleOnUploadedMedia(m);
            })
            .catch (() => {});
          }}
          />
          </FormControl>
          </Box>
          </Grid>
          </RequirePermission>
        </Grid>
        
        {props.hideActionButtons || (
          <Button onClick={handleConfirm} disabled={selectedMediaId === ""} variant="contained">
            Use Image
          </Button>
          )}
        </DialogActions>
      

        {editingMedia && (
          <MediaEditorDialog
            open={editingMediaId !== ""}
            media={editingMedia}
            onUpdateMedia={handleOnMediaEditorUpdatedMedia}
            onDeleteMedia={handleOnMediaEditorDeletedMedia}
            onRequestClose={() => {
              setEditingMediaId("");
            }}
          />
        )}
    </>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';



import { checkIsIOS, disableIosTextFieldZoom } from 'utils/utils-html';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Prevent Mobile Safari autozoom when textfield is focussed.  This is a little hacky but let's try it out for now
if (checkIsIOS()) {
  disableIosTextFieldZoom();
}

// Configure Amplify in index file or root file
let config;
if (process.env.NODE_ENV === 'production') {
  config = require('./config/aws-exports.prod').AWS_AUTH_CONFIG;
} else {
  config = require('./config/aws-exports.dev').AWS_AUTH_CONFIG;
}
export const AWS_AUTH_CONFIG = config;
Amplify.configure(AWS_AUTH_CONFIG)


root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

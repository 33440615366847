import React, { ChangeEvent, ReactNode, useContext, useState } from "react";
import tour, { tourPlayModes } from "../../data/model/tour";

import { AppBar, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Stack, Switch, Tab, Typography } from "@mui/material";


import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";


import { TabContext, TabList, TabPanel } from "@mui/lab";
import TourPlayModeSelect from "./TourPlayModeSelect";
import TourCountrySelect from "./TourCountrySelect";
import { LatLngLiteral } from "leaflet";
import MapPicker from "components/geo/MapPicker";
import StyledMediaItem from "./StyledMediaItem";
import styledMediaItem from "data/model/styledMediaItem";
import BorderedSection from "components/chrome/BorderedSection";
import RequirePermission from "components/auth/RequirePermission";
import usePermissions from "hooks/usePermissions";
import CollectionEditor from "./CollectionEditor";
import GenericCollectionEditor from "./GenericCollectionEditor";
import mediaItem, { BLANK_MEDIA_ITEM } from "data/model/mediaItem";
import { BLANK_MEDIA_ITEM_STYLE } from "data/model/mediaItemStyle";


export interface TourSummaryEditorDialogProps {
  open: boolean;
  fullScreen?: boolean;
  tour: tour;
  onRequestClose: () => void;
  onUpdateTour: (t: tour) => void;
}

export default function TourSummaryEditorDialog(props: TourSummaryEditorDialogProps) {

  const { hasPermissionFor } = usePermissions();

  /******************* 
  // TABS
  ********************/
  const TAB_VALUE_BASIC: string = "0";
  const TAB_VALUE_IMAGERY: string = "1";
  const TAB_VALUE_LOCATION: string = "2";
  const TAB_VALUE_GAMEPLAY: string = "3";
  const TAB_VALUE_MARKETPLACE: string = "4";

  const [tabValue, setTabValue] = useState(TAB_VALUE_BASIC);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  /******************* 
  // HUNT SUMMARY EVENTS
  ********************/
  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    props.onUpdateTour({
      ...props.tour,
      [e.target.name]: e.target.value // [..] denotes use the 'name' JSX element value
    });
  };
  const isNumbers = (str: string) => /^[0-9]*$/.test(str);
  const handleChangeNumericValue = (e: ChangeEvent<HTMLInputElement>) => {
    // Skip any input that isn't either numeric, or zero-length (zero-length to clear)
    if (e.target.value.length === 0) {
      const newTour:tour = {
        ...props.tour,
        [e.target.name]: undefined    
      };
      delete newTour[e.target.name as keyof tour];
      props.onUpdateTour(newTour);
    }
    else if (isNumbers(e.target.value))
    {
     var newValue =  Number(e.target.value);
    
     const newTour:tour = {
      ...props.tour,
      [e.target.name]: newValue
      };
      
      props.onUpdateTour(newTour);
    }
  };

  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    props.onUpdateTour({
      ...props.tour,
      [e.target.name]: e.target.checked
    });
  };

  const handleChangePlayMode = (newMode: tourPlayModes) => {
    props.onUpdateTour({
      ...props.tour,
      playMode: newMode
    });
  };

  const handleChangeLocation = (newPosition: LatLngLiteral) => {
    props.onUpdateTour({
      ...props.tour,
      placeLocation: newPosition
    });
  };


  const handleMediaItemsUpdated = (newMediaItems: mediaItem[]) => {
    props.onUpdateTour({
      ...props.tour,
      mediaItems: newMediaItems
    });
  };

  // const renderTabs = () => {
    
  

  return (
    <Dialog open={props.open} fullScreen={props.fullScreen} fullWidth maxWidth={"xl"}>
      <AppBar position="sticky">
        <DialogTitle>
          <Stack direction="row" gap={{ xs: "4px", md: "20px" }} alignItems="center" justifyContent="start" sx={{ width: "100%", height: "30px" }}>
            <Typography variant="h4">Tour settings</Typography>

            <IconButton
              color="inherit"
              sx={{ ml: "auto" }} // Pushes it to the right
              onClick={() => {
                props.onRequestClose();
              }}>
              <CloseIcon color="inherit" />
            </IconButton>
          </Stack>
        </DialogTitle>
      </AppBar>

      <DialogContent className="question-editor-dialog-content" sx={{ minHeight: "80vh" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} >
            <TabList variant="scrollable" onChange={handleTabChange}  aria-label="Tab navigation controls">
              <Tab label="Basic Info" value={TAB_VALUE_BASIC} />
              <Tab label="Imagery" value={TAB_VALUE_IMAGERY} />
              <Tab label="Location" value={TAB_VALUE_LOCATION} />
              <Tab label="Gameplay" value={TAB_VALUE_GAMEPLAY} />
              {hasPermissionFor("CreatePaidProducts") && (
                <Tab label="Marketplace" value={TAB_VALUE_MARKETPLACE} />
              )}
            </TabList>
          </Box>

          <TabPanel value={TAB_VALUE_BASIC}>
            <Grid container spacing="15px">

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Stack direction="column" spacing="15px">
                    <TourPlayModeSelect value={props.tour.playMode} onUpdated={handleChangePlayMode} />


                  <TextField
                  id="hs-desc"
                  multiline
                  rows={5}
                  maxRows={8}
                  fullWidth
                  size="small"
                  label="Pre-Tour Instructions"
                  placeholder="e.g. how to find the start location, what you'll need, any info on the terrain type and difficulty."
                  helperText="What players need to know, or do, before they begin"
                  variant="filled"
                  name="preTourInstructions" // NB this is the data model key name, used in handleChangeHuntValue
                  value={props.tour.preTourInstructions ?? ""}
                  onChange={handleChangeValue}
                />
                  </Stack>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="hs-desc"
                  multiline
                  maxRows={10}
                  rows={8}
                  fullWidth
                  size="small"
                  label="Description"
                  variant="filled"
                  name="description" // NB this is the data model key name, used in handleChangeHuntValue
                  value={props.tour.description}
                  onChange={handleChangeValue}
                  onFocus={(event) => {
                    // If the default value is still there, assume it will likely be deleted so select all text
                    if (props.tour.description.includes("created on")) {
                      const target = event.target;
                      setTimeout(() => target.select(), 0);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  id="hs-blurb"
                  multiline
                  maxRows={15}
                  rows={6}
                  fullWidth
                  size="small"
                  label="Detailed description"
                  variant="filled"
                  name="blurb" // NB this is the data model key name, used in handleChangeHuntValue
                  value={props.tour.blurb}
                  onChange={handleChangeValue}
                />
              </Grid>

            <Grid item xs={12}sm={6}>
            
              <Stack direction="row" spacing="15px">

              <FormControl fullWidth >    
                <TextField
                  placeholder={props.tour.distanceMetres?.toString() ?? ""}
                  helperText="Leave blank to use calculated value"
                  size="small"
                  label="Distance (metres)"
                  variant="filled"
                  name="distanceMetresStated"
                  type="text"
                  value={props.tour.distanceMetresStated !== undefined ? props.tour.distanceMetresStated : ""}
                  onChange={handleChangeNumericValue}
                  inputProps={{inputMode: "numeric", pattern:"[0-9]*"}}
                />
                </FormControl>

                <FormControl fullWidth>    
                  <TextField
                  placeholder={props.tour.durationMins?.toString() ?? ""}
                  helperText="Leave blank to use calculated value"
                  size="small"
                  label="Duration (mins)"
                  variant="filled"
                  name="durationMinsStated" 
                  type="text"
                  value={props.tour.durationMinsStated !== undefined ? props.tour.durationMinsStated : ""}
                  onChange={handleChangeNumericValue}
                  inputProps={{inputMode: "numeric", pattern:"[0-9]*"}}
                />
                </FormControl>
              </Stack>
            </Grid>



            </Grid>

          </TabPanel>

          <TabPanel value={TAB_VALUE_IMAGERY}>
            <Grid container spacing="15px" sx={{ marginTop: "15px", marginBottom: "15px" }}>

            <Grid item xs={12} sm={6}>
              <BorderedSection title="Featured Images">
                <Box pl="15px" pr="15px" pb="15px">
              <GenericCollectionEditor<mediaItem>
                  itemNameLabel="Image"
                  canDeleteItems canMoveItems
                  collection={props.tour.mediaItems}
                  onUpdateCollection={handleMediaItemsUpdated}
                  onRequestNewItem={function (): mediaItem {
                    return BLANK_MEDIA_ITEM;
                  } } 
                  onRenderItem={function (item: mediaItem, cbUpdateItem: (newItem: mediaItem) => void): ReactNode {
                        return  <Box width="200px" >
                        <StyledMediaItem
                        hideStylingControls hideDeleteButton
                        collapsedHeight={100} expandedHeight={100}
                        styledItem={ {  item: item, style: BLANK_MEDIA_ITEM_STYLE }}
                        onUpdateMedia={ (smi?: styledMediaItem) => cbUpdateItem(smi?.item ?? BLANK_MEDIA_ITEM) }
                        onRequestDeleteMedia={() => {  return Promise.resolve(true);}}
                        />
                        </Box>
                  } }                  
              />
              </Box>
              </BorderedSection>
            </Grid>


            <Grid item xs={6} md={3}>
              <BorderedSection title="Default Avatar">
              <StyledMediaItem 
              sx={{m: "10px"}}
              hideStylingControls expandedHeight={200} collapsedHeight={200}
              styledItem={ 
                props.tour.defaultTavatarMediaItem ?
                {
                item: props.tour.defaultTavatarMediaItem ?? null,
                style: { fit: "Contain"} // Placeholders
                } : undefined
                }
              onUpdateMedia={(newStyledMedia?:styledMediaItem) => {
                props.onUpdateTour({
                  ...props.tour,
                  defaultTavatarMediaItem: newStyledMedia?.item ?? undefined
                });
              }}
              onRequestDeleteMedia={() => { 
                return Promise.resolve(true);
              }}
              mediaLibraryQuery={{slug: {include: "sys-tavatar-"}}}
              />
              </BorderedSection>
            </Grid>

            <Grid item xs={6} md={3}>
              <BorderedSection title="Game Background" >
              <StyledMediaItem 
              sx={{m: "10px"}}
              hideStylingControls expandedHeight={200} collapsedHeight={200}
              styledItem={ 
                props.tour.backgroundMediaItem ?
                {
                item: props.tour.backgroundMediaItem ?? null,
                style: {  fit: "Contain"} // Placeholders
                } : undefined
                }
              onUpdateMedia={(newStyledMedia?:styledMediaItem) => {
                props.onUpdateTour({
                  ...props.tour,
                  backgroundMediaItem: newStyledMedia?.item ?? undefined
                });
              }}
              onRequestDeleteMedia={() => { 
                return Promise.resolve(true);
              }}
              mediaLibraryQuery={{slug: {include: "sys-bg"}}}
              />
              </BorderedSection>
            </Grid>




            </Grid>
          </TabPanel>


          <TabPanel value={TAB_VALUE_LOCATION}>
            <Grid container spacing="15px" sx={{ marginTop: "15px", marginBottom: "15px" }}>
              <Grid item xs={12} md={6}>
                <TourCountrySelect
                  countryCode={props.tour.placeCountryCode}
                  onUpdated={(newCountryCode?: string) => {
                    props.onUpdateTour({
                      ...props.tour,
                      placeCountryCode: newCountryCode
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    id="hs-placecode"
                    fullWidth
                    size="small"
                    label="Location (place)"
                    placeholder="Town / city name, etc."
                    variant="filled"
                    name="placeName" // NB this is the data model key name, used in handleChangeHuntValue
                    value={props.tour.placeName}
                    onChange={handleChangeValue}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" mb="10px">
                  Starting Location
                </Typography>
                {/* MAP */}
                <MapPicker height="300px" position={props.tour.placeLocation ?? { lat: 51, lng: -0.1 }} onUpdatePosition={handleChangeLocation} />
                <Typography variant="caption" mt="5px">
                  Displayed to users in tour's listing page
                </Typography>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={TAB_VALUE_GAMEPLAY}>
            <FormControl fullWidth>
              <Typography variant="h6" component="div" sx={{ marginTop: "20px;" }}>
                Behaviours
              </Typography>

              <FormGroup>
                {/* <FormControlLabel
                  control={
                    <Switch
                      disabled={props.tour.playMode === "OneGoPerQuestion"}
                      checked={props.tour.penaliseMultipleAttempts}
                      name="penaliseMultipleAttempts" // NB this is the data model key name
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Penalise multiple attempts"
                /> */}

                <FormControlLabel
                  control={
                    <Switch
                      checked={props.tour.canSkipQuestions}
                      name="canSkipQuestions" // NB this is the data model key name
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Can skip questions"
                />

                <FormControlLabel
                  control={
                    <Switch
                      disabled={props.tour.playMode === "RepeatUntilCorrect" && props.tour.canSkipQuestions === false}
                      checked={props.tour.giveCorrectAnswerOnFailure}
                      name="giveCorrectAnswerOnFailure" // NB this is the data model key name
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Reveal the answer/explanation if the player is unsuccessful"
                />
              </FormGroup>

              <Typography variant="h6" component="div" sx={{ marginTop: "20px;" }}>
                Hints
              </Typography>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.tour.canRequestHints}
                      name="canRequestHints" // NB this is the data model key name
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Allow hints to be requested on demand"
                />
              </FormGroup>

              <TextField
                id="hs-totalhints"
                fullWidth
                disabled={!props.tour.canRequestHints}
                size="small"
                label="Total number of hints permitted"
                helperText="Leave blank for unlimited hints"
                variant="filled"
                name="totalHintsPermitted" // NB this is the data model key name, used in handleChangeHuntValue
                value={props.tour.totalHintsPermitted === undefined ? "" : props.tour.totalHintsPermitted}
                onChange={handleChangeNumericValue}
              />

              <FormGroup>
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={props.tour.penaliseHints}
                      name="penaliseHints" // NB this is the data model key name
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Penalise score for each hint used"
                /> */}

                <FormControlLabel
                  control={
                    <Switch
                      disabled={props.tour.playMode === "OneGoPerQuestion"}
                      checked={props.tour.giveHintOnWrongAnswer}
                      name="giveHintOnWrongAnswer" // NB this is the data model key name
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Give a hint on a wrong answer"
                />
              </FormGroup>

              <Typography variant="h6" component="div" sx={{ marginTop: "20px;" }}>
                Visibility
              </Typography>

              <FormGroup>
                <FormControlLabel control={<Switch checked={props.tour.isLocked} name="isLocked" onChange={handleChangeCheckbox} />} label="Lock this tour" />
              </FormGroup>

              <TextField
                id="hs-lock"
                fullWidth
                size="small"
                label="Message to show if Locked"
                variant="filled"
                name="lockedMessage" // NB this is the data model key name, used in handleChangeHuntValue
                value={props.tour.lockedMessage}
                onChange={handleChangeValue}
              />

            
              <Typography variant="h6" component="div" sx={{ marginTop: "20px;" }}>
                Completion
              </Typography>

            <FormControl fullWidth sx={{ mt: "10px" }}>
              <Stack direction="column" spacing="15px">
                <TextField
                  multiline
                  rows={3}
                  id="hs-compl"
                  fullWidth
                  size="small"
                  label="Completion Message"
                  variant="filled"
                  name="completedMessage"
                  value={props.tour.completedMessage}
                  onChange={handleChangeValue}
                />
              </Stack>
            </FormControl>


            </FormControl>
          </TabPanel>

          <TabPanel value={TAB_VALUE_MARKETPLACE}>
            <FormControl fullWidth>
              <Typography variant="h6" component="div" sx={{ marginTop: "20px;" }}>
                Marketplace
              </Typography>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.tour.requiresTicket}
                      name="requiresTicket" // NB this is the data model key name
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Paid tour"
                />
              </FormGroup>

              <TextField
                fullWidth
                size="small"
                label="Price (pence)"
                variant="filled"
                name="ticketPrice" // NB this is the data model key name, used in handleChangeHuntValue
                value={props.tour.ticketPrice}
                onChange={handleChangeNumericValue}
              />

              <Typography variant="h6">
                Ensure that you have entered appropriate public channel details in your profile.  Also that you have set a Place Name in the location tab.
              </Typography>
            </FormControl>
          </TabPanel>

        </TabContext>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} disabled={selectedMediaId < 0} variant="contained">
          Select
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}

import { useContext, useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";

import ImageIcon from "@mui/icons-material/Image";
import NoBackpackIcon from '@mui/icons-material/NoBackpack';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Skeleton, Stack, Typography } from "@mui/material";
import useAPI from "services/useHunterApi";
import NoDataPane from "components/chrome/NoDataPane";
import { tourTicket } from "data/tourTicket";
import { formatDateAsPretty } from "utils/utils-time";

interface TourTicketsProps {
  showSpent?: boolean;
}

export default function TourTickets(props: TourTicketsProps) {
  const [tickets, setTickets] = useState<tourTicket[]>();
  const [showSpent, setShowSpent] = useState(props.showSpent);

  const { callAPI, isLoading } = useAPI();

  // On startup, get tickets belonging to logged in user only
  useEffect(() => {
    callAPI<null, tourTicket[]>(props.showSpent ? `/api/tourticket/me` : `/api/tourticket/me/unspent`, "GET")
      .then((tix) => {
        setTickets(tix);
      })
      .catch(() => {});
  }, []);

  return isLoading ? (
    <Stack direction="column" gap="10px" width="100%" sx={{ p: "25px" }}>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={36}></Skeleton>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={36}></Skeleton>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={36}></Skeleton>
    </Stack>
  ) : tickets && tickets!.length > 0 ? (
    <>
      <List sx={{ width: "100%" }}>
        {isLoading && (
          <ListItem disableGutters disablePadding>
            <Skeleton variant="rectangular" animation="wave" width="100%" height={40}></Skeleton>
          </ListItem>
        )}
        {tickets?.map((tk) => (
          <ListItem disableGutters disablePadding key={`tik-${tk.id}`}>
            <ListItemButton href={`/app/tours/${tk.tourId}`}>
              <ListItemAvatar>
                <Avatar src={tk.tourThumbnailUri ?? ""}>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>

              <Box>
      <Typography variant="body2" fontWeight="700">
        {tk.tourName}
      </Typography>
        <Typography variant="caption"> 
        {tk.isSpent ? `Played ${formatDateAsPretty(tk.usedTime)}` : `Purchased ${formatDateAsPretty(tk.creationTime)}`}
        </Typography> 
        
      </Box>
            </ListItemButton>

            <Button href={`/play/tour/${tk.tourId}/newgame`} 
            disabled={tk.isSpent}
            size="small" variant="outlined">
              Play now
            </Button>

            <Divider light />
          </ListItem>
        ))}
      </List>
    </>
  ) : (
    <>
      <NoDataPane icon={<NoBackpackIcon />} sx={{height: "200px"}} title="No tickets"
       subtitle="Find treasure tours in your area and get started for free!" />
    </>
  );
}


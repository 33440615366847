import React, { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";

export const renderStars = (starsScore: number) => {
  const filledStars = Math.floor(starsScore);
  const hasHalfStar = starsScore % 1 !== 0;
  const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

  const stars = [];

  for (let i = 0; i < filledStars; i++) {
    stars.push(
      <IconButton key={i} sx={{ fontSize: "inherit" }}>
        <StarIcon fontSize="inherit" />
      </IconButton>
    );
  }

  if (hasHalfStar) {
    stars.push(
      <IconButton key="half" sx={{ fontSize: "inherit" }}>
        <StarHalfIcon fontSize="inherit" />
      </IconButton>
    );
  }

  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <IconButton key={`empty-${i}`} sx={{ fontSize: "inherit" }}>
        <StarBorderIcon fontSize="inherit" />
      </IconButton>
    );
  }

  return stars;
};

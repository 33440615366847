
export const BLANK_MEDIA_ITEM:mediaItem = {
  id: "",
  description: "",
  title: "",
  tags: [],
  url: "",
  urlThumb: "",
  urlSmall: "",
  urlMedium: "",
  urlLarge: "",
  canEditMetadata: false,
  isDirty: false,
  isNewlyUploaded: false
}

export interface mediaItemSerial {
  id: string;
  originalFileName?: string;
  description: string;
  title: string;
  tags: string[]; 
}

export default interface mediaItem extends mediaItemSerial {
  // Calculated server-side
  url: string;
  urlThumb: string;
  urlSmall: string;
  urlMedium: string;
  urlLarge: string;

  canEditMetadata: boolean;

  // Internal
  isDirty: boolean;
  isNewlyUploaded: boolean;
}

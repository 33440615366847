import { Box, Typography } from "@mui/material";
import { PageBox } from "components/chrome/PageBox";
import PageTitle from "components/chrome/PageTitle";

export default function PrivacyPage() {
  return (
    <PageBox>
      <PageTitle>Privacy Policy</PageTitle>

      <Box>
        <h4>Introduction</h4>
        <p>This privacy policy explains how we use any personal information we collect about you when you use our products and services.</p>
        <hr />
        <h4>What information do we collect about you?</h4>
        <p>We collect information about you when you register for an account on the website.</p>
        <h5>Name and contact details</h5>
        For registered accounts, we collect your email address and, if you provide them to us, your name and mobile phone number.
        <h5>Website visit metrics</h5>
        When you use our website, we may create a cookie. Cookies are text files placed on your computer to collect standard internet log information and visitor behaviour information. This information is used to track visitor use of the website and
        to compile statistical reports on website activity.
        <hr />
        <h4>How will we use the information about you?</h4>
        <h5>Account services</h5>
        <p>We collect information about you for the purpose of creating an account and storing your information.</p>
        <p>We may also use the information to process an order or send a receipt.</p>
        <h5>Marketing Communications</h5>
        Subject to your consent, we also collect information to enable us to contact you about additional products and offers.
        <hr />
        <h4>How do we keep your information secure?</h4>
        <p>We use a range of technical and organisational security measures to ensure that your data is stored and transmitted securely. These include:</p>
        <ul>
          <li>Protection of personal data using pseudonymisation where practicable</li>
          <li>Securing the transfer and storage of data</li>
          <li>Prevention of unauthorised access to data via password protection and other means</li>
          <li>Implementing methods for detecting and dealing with breaches of security</li>
          <li>Secure methods of disposal of unwanted Personal Data</li>
        </ul>
        <hr />
        <h4>How can I Opt Out?</h4>
        <p>You have a right to stop us at any time from contacting you for marketing purposes.</p>
        <p>If you no longer wish to be contacted for marketing purposes, please click the link on any marketing email to unsubscribe.</p>
        <hr />
        <h4>Who we share this data with</h4>
        We can share this personal data with the following third parties, all of whom will endeavour to abide by the principles of this privacy policy: Any authorised payment processing companies, for the purposes of verifying your identity so that
        we can take payment for your orders, or storing a record of your journey history so we can display this to you. These may include Stripe Inc. We will also send your personal data to our web hosting company and SaaS provider, Amazon Web
        Services Inc for the purposes of processing the data and storing it securely.
        <hr />
        <h4>How long will we store your data for?</h4>
        We will store your account data for as long as you continue to hold your account. If an account has been dormant for more than three years we may also review this account and decide to close it on your behalf. If an account is closed, either
        by you or by us, any of your personally identifiable information will be removed within six months of the date of closure.
        <hr />
        <h4>How to access, amend, or delete your information</h4>
        <p>You have the right to obtain a copy of the information that we hold about you. If you would like to do this, please email us using the address below in the ‘Contact Us’ section.</p>
        <p>You have the right to request erasure (deletion) of your personal data. If you would like to do this, please contact us via our website.</p>
        <p>
          If you have a complaint about the way in which we have stored or processed your personal data, we would encourage you to contact us in the first instance so that we can work with you to resolve this. You also have the right to contact the
          Information Commissioner’s Office.
        </p>
        <hr />
        <h4>Changes to our privacy policy</h4>
        We keep our privacy policy under regular review and we will update this web page accordingly. This privacy policy was last updated on 21st November 2023.
        <hr />
        <h4>How to contact us</h4>
        Please contact us if you have a question about our privacy policy or information we hold about you, via email.
      </Box>
    </PageBox>
  );
}

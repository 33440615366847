export const formatSeconds = (secs: number) => {
  return new Date(secs * 1000).toISOString().slice(11, 19);
};

export const formatDateAsPretty = (aDate: Date) => {
  const saneDate: Date = new Date(aDate);

  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true
  };

  // Check if the year is the same as the current year
  const currentYear: number = new Date().getFullYear();
if (saneDate.getFullYear() !== currentYear) {
  options.year = 'numeric';
}

  try {
    return new Intl.DateTimeFormat("en-US", options).format(saneDate);
  }
  catch 
  {
    return aDate.toDateString();
  }
};

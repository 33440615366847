import { PropsWithChildren } from "react";
import usePermissions, { groupNames } from "hooks/usePermissions";

export interface RequireGroupProps {
  names: groupNames[];
}

export default function RequireGroups(props: PropsWithChildren<RequireGroupProps>) {
  const { isUserInSomeGroups } = usePermissions();
  return <>{isUserInSomeGroups(props.names) && props.children}</>;
}

import { DragEndEvent, LatLngExpression, LatLngLiteral } from "leaflet";
import { Marker, useMapEvents } from "react-leaflet";
import "react-leaflet-fullscreen/styles.css";

interface DropMarkerProps {
  position?: LatLngLiteral;
  onUpdatePosition: (newPosition: LatLngLiteral) => void;
}

export default function DropMarker(props: DropMarkerProps) {
  const eventHandlers = {
    dragend(e: DragEndEvent) {
      props.onUpdatePosition(e.target.getLatLng());
    }
  };

  useMapEvents({
    contextmenu(e) {
      props.onUpdatePosition(e.latlng);
    }
    // ,
    // click(e) {
    //   props.onUpdatePosition(e.latlng);
    // }
  });
  return (props.position !== undefined) ?
  <Marker eventHandlers={eventHandlers} draggable={true} autoPan={true} autoPanPadding={[20, 20]} autoPanSpeed={5} position={props.position ?? [0, 0]} />
  :
  <></>
}

/* POPUP

description: `${props.dropmarkerPosition!.lat.toFixed(3)}, ${props.dropmarkerPosition!.lng.toFixed(3)}`

*/

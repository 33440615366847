import { DragEndEvent, LatLngExpression } from "leaflet";
import { Marker, useMapEvents } from "react-leaflet";
import "react-leaflet-fullscreen/styles.css";

interface DYMProps {
  position?: LatLngExpression;
  onUpdatePosition: (newPosition: LatLngExpression) => void;
}

export default function DynamicMarker(props: DYMProps) {
  const eventHandlers = {
    dragend(e: DragEndEvent) {
      props.onUpdatePosition(e.target.getLatLng());
    }
  };

  useMapEvents({
    click(e) {
      props.onUpdatePosition(e.latlng);
    }
  });
  return (props.position !== undefined) ?
  <Marker eventHandlers={eventHandlers} draggable={true} autoPan={true} autoPanPadding={[20, 20]} autoPanSpeed={5} position={props.position ?? [0, 0]} />
  :
  <></>
}
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { useConfirm } from "material-ui-confirm";

import { Box, Button, List, ListItem, ListItemText, Skeleton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

import useAPI from "services/useHunterApi";

import TourRatings from "./TourRatings";
import { PageBox } from "components/chrome/PageBox";
import PageTitle from "components/chrome/PageTitle";


export default function TourReport() {
  let { tourId } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  

  if (!tourId) {
    return (<Box sx={{m: {xs: "12px", sm: "16px"}, height: "500px"}}>
        <Stack direction="column" gap="10px" sx={{width: "100%"}}>
  
          <Skeleton variant="rectangular" animation="wave" width="30px" height="30px" sx={{alignSelf:"end"}}></Skeleton>
          <Skeleton variant="rectangular" animation="wave" width="100%" height="100px"></Skeleton>

          <Skeleton variant="rectangular" animation="wave" width="200px" height="40px" sx={{alignSelf:"start"}}></Skeleton>
          <Skeleton variant="rectangular" animation="wave" width="150px" height="40px" sx={{alignSelf:"start"}}></Skeleton>

          <Skeleton variant="rectangular" animation="wave" width="100%" height="230px" sx={{mt: "20px"}}></Skeleton>

        </Stack>
    </Box>
    )
    ;
  } else {
    return (
      <PageBox>

      <PageTitle>Tour Stats</PageTitle>
       
       <Box>
        <Typography variant="h5">Ratings for tour</Typography>
        
        <TourRatings tourId={tourId} />

       </Box>
       

       </PageBox>
    );
  }
}

import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HuntPicker.css";

import tour from "../../data/model/tour";

import { useConfirm } from "material-ui-confirm";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";

import ImageIcon from "@mui/icons-material/Image";
import GridOnIcon from '@mui/icons-material/GridOn';
import DnsIcon from '@mui/icons-material/Dns';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import Box from "@mui/material/Box";
import { ButtonGroup, IconButton, Skeleton } from "@mui/material";
import useAPI from "services/useHunterApi";

interface HuntPickerProps {}

function HuntPicker(props: HuntPickerProps) {
  
  const navigate = useNavigate();

  const [tours, setTours] = useState<tour[]>();
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();

  // Fetch a list of all hunt summaries
  useEffect(() => {
      callAPI<void, tour[]>(
        `/api/public/tour`,
        "GET"
      ).then((h) => {
        setTours(h);
      })
      .catch (error => {})
  }, []);

  
  const handleClickHuntViewResponsesList = (tourId:number) => {
    navigate(`responseslist/tour/${tourId}`);
  }
  const handleClickHuntViewResponsesGrid = (tourId:number) => {
    navigate(`responsesgrid/tour/${tourId}`);
  }
  const handleClickHuntViewLiveProgress  = (tourId:number) => {
    navigate(`tour/${tourId}`);
  }


  return (
    <div className="hunt-picker-container">
      <h2>Choose a Hunt to Monitor</h2>
      <Box className="card-hunts">
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {isLoading && (
            <ListItem>
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={40}></Skeleton>
            </ListItem>
          )}
          {tours?.map((h, index) => (
            <ListItem
              key={`huntsum-${h.id}`}
              secondaryAction={
                <ButtonGroup>
                  <IconButton
                    aria-label="See live progress"
                    onClick={() => handleClickHuntViewLiveProgress(h.id)}>
                    <AutoGraphIcon />
                  </IconButton>
                  <IconButton
                    aria-label="See responses list"
                    onClick={() => handleClickHuntViewResponsesList(h.id)}>
                    <DnsIcon />
                  </IconButton>
                  <IconButton
                    aria-label="See responses grid"
                    onClick={() => handleClickHuntViewResponsesGrid(h.id)}>
                    <GridOnIcon />
                  </IconButton>
                </ButtonGroup>
              }>
              <Link to={`tour/${h.id}`}>
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={h.name} secondary={h.channel.name} />
                </ListItemButton>
              </Link>
              <Divider light />
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
}

export default HuntPicker;

import { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { LayerGroup, MapContainer, TileLayer } from "react-leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { LocateControl } from "components/geo/LocateControl";
import GeocoderLeafletControl from "components/geo/GeocoderLeafletControl";
import { SBAlert, SBAlertContext } from "./SBAlertContext";
import MarkersDisplay from "components/geo/MarkersDisplay";
import markerSource from "data/model/geo/markerSource";
import MapController from "components/geo/MapController";

import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import RouteIcon from "@mui/icons-material/Route";
import StreetviewIcon from '@mui/icons-material/Streetview';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { LatLng, LatLngBounds, LatLngExpression, LatLngLiteral, latLng, polyline } from "leaflet";
import irlItem, { defaultPoiFetchTypes, irlItemTypeValues, irlItemTypes } from "data/model/geo/irlItem";
import useAPI from "services/useHunterApi";
import { ButtonGroup, useAuthenticator } from "@aws-amplify/ui-react";
import usePermissions from "hooks/usePermissions";
import { truncateContent } from "utils/utils-strings";
import RequirePermission from "components/auth/RequirePermission";
import GetPoiButton from "./GetPoiButton";
import getIRLItemsRequest from "data/model/geo/irlItemsRequest";
import { directionsRequest } from "data/model/geo/directionsRequest";
import { ttRoutingResponse } from "data/model/geo/ttRoutingResponse";
import L from "leaflet";
import MapPolylines from "components/geo/MapPolylines";
import DynamicMarker from "components/geo/DynamicMarker";
import DropMarker from "components/geo/DropMarker";
import { useNavigate } from "react-router-dom";

interface ExplorerMapProps {
  questionMarkersData: markerSource[];
  selectedQuestionLocation?: LatLngLiteral;
  selectedIRLItems: irlItem[];
  dropmarkerPosition: LatLngLiteral | undefined;
  onSelectedIRLItems: (items: irlItem[]) => void;
  onDropmarkerPositionChange: (location: LatLngLiteral | undefined) => void;
  onTourRouteUpdated: (newDuration?:number, newDistance?:number) => void;
  onMovedQuestionMarker: (marker: markerSource, newPosition: LatLngLiteral) => void;
}

export default function ExplorerMap(props: ExplorerMapProps) {
  const { alert, setAlert } = useContext(SBAlertContext);
  const { hasPermissionFor } = usePermissions();
  const navigate = useNavigate();

  const { user } = useAuthenticator((context) => [context.user]);
  const [showMarkers, setShowMarkers] = useState(true);
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [mapBounds, setMapBounds] = useState<LatLngBounds>(new LatLng(0, 0).toBounds(1));
  const [irlItems, setIRLItems] = useState<irlItem[]>([]);

  // The types of POI to request from server
  const [poiFetchTypes, setPoiFetchTypes] = useState<irlItemTypes[]>(defaultPoiFetchTypes);

  const [routingResponse, setRoutingResponse] = useState<ttRoutingResponse>();
  const [doneInitialMapFlight, setDoneInitialMapFlight] = useState(false);

  const mapContainerRef = useRef<L.Map>(null);

  const [poiMarkersData, setPoiMarkersData] = useState<markerSource[]>([]);
  const [questionMarkersBounds, setQuestionMarkersBounds] = useState<LatLngBounds | null>(null);

  const handleGetDirections = () => {
    if (!mapBounds.contains([0, 0])) {
      if (props.questionMarkersData &&  props.questionMarkersData.length > 1) { // at least 2 markers!
        var drq: directionsRequest = {
          waypoints: props.questionMarkersData.map((ms) => ms.location)
        };
        callAPI<directionsRequest, ttRoutingResponse>(`/private/api/geo/directions`, "POST", drq)
          .then((rr) => {
            setRoutingResponse(rr);
          })
          .catch(() => {});
      } else {
        handleClearDirections();
      }
    }
  };

  const handleClearDirections = () => {
    setRoutingResponse(undefined);
  }

  const handleGetPOI = () => {
    if (!hasPermissionFor("POIPlanningAssistance")) return;
    if (!mapBounds.contains([0, 0])) {
      callAPI<getIRLItemsRequest, irlItem[]>(`/private/api/irlitem/boundingbox`, "POST", {
        boundingBox: {
          minlat: mapBounds.getSouthWest().lat,
          minlng: mapBounds.getSouthWest().lng,
          maxlat: mapBounds.getNorthEast().lat,
          maxlng: mapBounds.getNorthEast().lng
        },
        itemTypes: poiFetchTypes
      })
        .then((items) => {
          if (items !== undefined) {
            setIRLItems(items);
          }
        })
        .catch(() => {});
    }
  };

  const updatePoiMarkerData = () => {
    var newPoiMarkersData: markerSource[] = [];

    // Map POI data to marker data
    if (irlItems !== undefined && irlItems !== null) {
      var filteredMarkers = irlItems.filter((i) => poiFetchTypes.includes(i.itemType));

      var irlMarkers: markerSource[] = filteredMarkers.map((i) => {
        return {
          id: i.id,
          name: i.shortDescription,
          description: (i.rating ? `(${i.rating}★ from ${i.numberOfReviews})\r\n` : "") + truncateContent(i.longDescription, 140),
          location: i.location,
          dataClass: "irlitem",
          dataObject: i,
          webURL: i.webURL
        } as markerSource;
      });
      newPoiMarkersData.push(...irlMarkers);
    }

    setPoiMarkersData(newPoiMarkersData);
  };

  const recalculateQuestionMarkerBounds = () => {
    setQuestionMarkersBounds(calculateMarkerBounds(props.questionMarkersData));
  };

  const calculateMarkerBounds = (markers: markerSource[]) => {
    if (markers.length === 0) {
      return null;
    } else {
      var bds: LatLngBounds = new LatLng(0, 0).toBounds(100);

      bds = new LatLng(markers[0].location.lat, markers[0].location.lng).toBounds(250); // 250m around first point
      markers.forEach((ms) => {
        bds.extend(ms.location);
      });

      bds = bds.pad(0.1);

      return bds;
    }
  };

  useEffect(() => {
    updatePoiMarkerData(); // sets AllMarkersData
  }, [irlItems]);

  useEffect(() => {
    updatePoiMarkerData(); // sets AllMarkersData
  }, [poiFetchTypes]);

  useEffect(() => {
    recalculateQuestionMarkerBounds(); // sets questionMarkerBounds
  }, [props.questionMarkersData]);

  // Whenever the question markers change, get directions
  // useEffect(() => {
  //   if (props.questionMarkersData) { 
  //     handleGetDirections();
  //   } else {
  //     handleClearDirections();
  //   }
  // }, [props.questionMarkersData]); 



  // ONE-TIME ONLY: Fly to the marker bounds provided on first render
  useEffect(() => {
    if (doneInitialMapFlight === false && questionMarkersBounds !== null) {
      mapContainerRef.current?.flyToBounds(questionMarkersBounds, { duration: 1 });
      setDoneInitialMapFlight(true);
      setMapBounds(questionMarkersBounds);
    }
  }, [questionMarkersBounds]);


  // Let the caller know when route changes - at the moment it's passed up through 3-4 components up to TourEditor
  // so it can save the values together with the tour.  We may wish to pass this responsibility to the server
  // instead to be handled at publish time.
  useEffect(() => {
    if (routingResponse !== undefined) {
      props.onTourRouteUpdated(routingResponse?.totalDuration, routingResponse?.totalDistance);
    }
  }, [routingResponse]); 

  const repositionBoundsOnQuestions = () => {
    if (doneInitialMapFlight === true && questionMarkersBounds !== null) {
      mapContainerRef.current?.flyToBounds(questionMarkersBounds, { duration: 1 });
    }
  };

  // Deal with markers that are clicked
  const handleSelectedMarkerSources = (selectedMarkers: markerSource[]) => {
    var selectedIRLItems = selectedMarkers
      .filter((ms) => ms.dataClass === "irlitem")
      .map((ms) => {
        return ms.dataObject as irlItem;
      });
    props.onSelectedIRLItems(selectedIRLItems);
    props.onDropmarkerPositionChange(undefined);
  };


  // Listen for clicks on the map - clear markers
  mapContainerRef.current?.addEventListener(
    // "contextmenu",  (e) => {  // For right-click/long tap, but map.tapHold not currently supported in React Leaflet
    "click",
    (e) => {
      props.onDropmarkerPositionChange(undefined);

    }
  );

  return (
    <Box>
      {/* Required otherwise popup appears behind map */}
      <Box zIndex={1}>
        <MapContainer ref={mapContainerRef} center={[0, 0]} zoom={2} style={{ zIndex: "1", height: "500px", borderBottom: "solid 1px gray" }} scrollWheelZoom={true}>
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <FullscreenControl forceSeparateButton position="topright" />
          <MapController
            center={props.selectedQuestionLocation} animateCenterUpdates
            onBoundsChanged={(newBounds: LatLngBounds) => {
              setMapBounds(newBounds);
            }}
          />
          <LayerGroup>
            <MarkersDisplay data={poiMarkersData} onSelectedMarkers={handleSelectedMarkerSources} />
            {showMarkers && (
              <MarkersDisplay data={props.questionMarkersData} draggable onSelectedMarkers={handleSelectedMarkerSources}
              onMovedMarker={props.onMovedQuestionMarker} />
            )}
          </LayerGroup>
          <DropMarker position={props.dropmarkerPosition} onUpdatePosition={(newPosition:LatLngLiteral) => props.onDropmarkerPositionChange(newPosition)} />
          <MapPolylines encodedPolyline={routingResponse?.geometryString} />

          <LocateControl
            position="bottomright"
            setView={"untilPanOrZoom"} //  Options are false, 'once', 'always', 'untilPan', or 'untilPanOrZoom'
            clickBehaviour={{ inView: "setView", outOfView: "setView", inViewNotFollowing: "setView" }} // 'stop', 'setView' or name of a key to inherit, e.g. {inView: 'stop', outOfView: 'setView', inViewNotFollowing: 'inView'}
            highAccuracy={true}
            onLocationError={(e, control) => {
              setAlert(
                new SBAlert({
                  message: "Couldn't get your location - check your device's location permission settings for this app.",
                  logMessage: `Couldnt get location ${e.message}`,
                  severity: "warning",
                  autoHideDuration: 5500
                })
              );
            }}
          />
          <GeocoderLeafletControl onUpdatePosition={() => {}} />
        </MapContainer>
      </Box>

      {/* INFO STRIP */}
      <Stack direction="row" width="100%" gap="10px" alignItems="center" justifyContent="space-between" sx={{ mt: "8px" }}>
        <Typography variant="caption" fontWeight="900">
          {`${props.questionMarkersData.length} WAYPOINTS`}
        </Typography>

        <Typography variant="caption" fontWeight="400" letterSpacing={-0.3}>
          {(irlItems.length < 1 && props.dropmarkerPosition === undefined) ? 
            `Right click map for marker` : 
            `${irlItems.length + 1} marker(s)`}
        </Typography>

        {routingResponse && (
          <Typography variant="caption" fontWeight="900">
            {`${routingResponse?.totalDistance && (routingResponse.totalDistance * 0.000621371).toFixed(1)} miles`}
            ,&nbsp;
            {`${routingResponse?.totalDuration && ((routingResponse.totalDuration * 0.0166667)).toFixed(0)} mins`}
          </Typography>
        )}
      </Stack>

      {/* BUTTON STRIP */}
      <Stack direction="row" width="100%" justifyContent="space-between" sx={{ mt: "8px" }}>
        <RequirePermission name="POIPlanningAssistance">
          <GetPoiButton
            disabled={isLoading}
            selectedTypes={poiFetchTypes}
            onUpdateSelectedTypes={(newTypes: irlItemTypes[]) => {
              setPoiFetchTypes(newTypes);
            }}
            onClick={() => {
              handleGetPOI();
            }}
          />
        </RequirePermission>

        <IconButton
          color="primary"
          title="Redraw walking path"
          disabled={isLoading || props.questionMarkersData?.length < 2}
          onClick={() => {
            handleGetDirections();
          }}>
          <RouteIcon />
        </IconButton>


        <IconButton
          color="primary"
          title="Toggle markers"
          onClick={() => { setShowMarkers(!showMarkers)}}>
            {showMarkers ? <LocationOnIcon /> : <LocationOffIcon />}
        </IconButton>

        <IconButton
          color="primary"
          title="Show in Streetview"
          disabled={isLoading ||
            (props.dropmarkerPosition === undefined && props.selectedIRLItems.length < 1)}
          onClick={() => {
             // Get the location, either from first selected IRL item or selected user marker
            var newLocation = props.dropmarkerPosition ?? props.selectedIRLItems[0]?.location ?? undefined;
            if (newLocation) {
                const url = `https://maps.google.com/maps?q=&layer=c&cbll=${newLocation.lat},${newLocation.lng}`;
                window.open(url, '_blank');
            }
          }}>
          <StreetviewIcon />
        </IconButton>


        <IconButton
          color="primary"
          title="Pan map to show all questions"
          disabled={isLoading}
          onClick={() => {
            repositionBoundsOnQuestions();
          }}>
          <CenterFocusStrongIcon />
        </IconButton>
      </Stack>
    </Box>
  );
}

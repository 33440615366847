import { useContext, useEffect, useRef } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { Box, Button } from "@mui/material";

import DirectionsIcon from "@mui/icons-material/Directions";
import MapController from "components/geo/MapController";
import { ExtendedLocateOptions, LocateControl } from "components/geo/LocateControl";
import { SBAlert, SBAlertContext } from "components/edit/SBAlertContext";


import { waypoint } from "data/model/waypoint";



interface WaypointDisplayMapProps {
  waypoint: waypoint;
}

export default function WaypointDisplayMap(props: WaypointDisplayMapProps) {
  const { alert, setAlert } = useContext(SBAlertContext);
  const locateControl = useRef<L.Control.Locate>(null);

  // Grab a reference to the locateControl because it can't update itself due to the way in which createControlComponent factory is designed.
  // Instead we must update its options (in this case the callback method getLocationBounds, which we want to change for each question displayed)
  useEffect(() => {
    if (locateControl) {
      const opts = locateControl.current?.options as ExtendedLocateOptions;
      if (opts) {
        opts.getLocationBounds = (e: L.LocationEvent) => {
          if (props.waypoint.location) {
            return e.bounds.extend(props.waypoint.location).pad(0.02);
          } else {
            return e.bounds;
          }
        };
      }
    }
  }, [props.waypoint, locateControl.current]);


  return (
   
        <Box>
          <MapContainer
            center={props.waypoint.location} // Needs something as will load immediately before MapController.center responds and pans the map
            zoom={18}
            dragging={true}
            scrollWheelZoom={true}
            zoomControl={true}
            style={{
              width: "100%",
              aspectRatio: "12 / 7",
              borderRadius: "5px",
              overflow: "hidden"
            }}>
            <MapController
              center={props.waypoint.location} // no fallback required, location is non-null here
            />
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={props.waypoint.location} />
            <FullscreenControl forceSeparateButton={true} position="topright" />
            <LocateControl
              ref={locateControl}
              position="bottomright"
              // getLocationBounds={getBoundsCallback}
              setView="untilPanOrZoom" //  Options are false, 'once', 'always', 'untilPan', or 'untilPanOrZoom'
              clickBehaviour={{ inView: "setView", outOfView: "setView", inViewNotFollowing: "setView" }} // 'stop', 'setView' or name of a key to inherit, e.g. {inView: 'stop', outOfView: 'setView', inViewNotFollowing: 'inView'}
              highAccuracy={true}
              onLocationError={(e, control) => {
                setAlert(
                  new SBAlert({
                    message: "Couldn't get your location - check your device's location permission settings for this app.",
                    logMessage: `Couldnt get location ${e.message}`,
                    severity: "warning",
                    autoHideDuration: 5500
                  })
                );
              }}
            />
          </MapContainer>
          <Button variant="contained" color="secondary" href={`http://maps.apple.com/?q=${props.waypoint.location.lat},${props.waypoint.location.lng}`} target="_new" fullWidth endIcon={<DirectionsIcon />}>
            Show directions
          </Button>
        </Box>
      );
}
import { Box, Button, Dialog, DialogActions, FormControl, TextField, Typography } from "@mui/material";
import StarSelector from "./StarSelector";
import { ChangeEvent, useState } from "react";
import { rating } from "data/model/rating";
import useAPI from "services/useHunterApi";

interface RatingControlProps {
  tourName: string;
  gameId: string;
}

export default function RatingControlDialog(props: RatingControlProps) {
  const [myRating, setMyRating] = useState<rating|undefined>();
  const [isOpen, setIsOpen] = useState(true);

  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();

  const handleScoreUpdated = (newValue: number) => {
    if (myRating === undefined) {
      // User gave a score - create a rating
      var aRating: rating = {
        gameId: props.gameId,
        id: -1,
        ratingScore: newValue,
        reviewText: "",
        creationDateTime: new Date()
      };
      setMyRating(aRating);
    } else {
      setMyRating({
        ...myRating,
        ratingScore: newValue
      });
    }
  };

  const handleChangeReviewText = (e: ChangeEvent<HTMLInputElement>) => {
    if (myRating) {
      const newRating: rating = {
        ...myRating,
        reviewText: e.target.value
      };

      setMyRating(newRating);
    }
  };

  const handleSkip = () => {
    setIsOpen(false);
  }

  const handleSave = () => {

    callAPINoResponseBody<rating>(
      `/api/rating/create`,
      "POST",
      myRating
    )
    .then(() => {
      setIsOpen(false);
    })
    .catch (error => {
      setIsOpen(false);
    })
  }

  return (
    <Dialog open={isOpen}>
      <Box
        p="20px"
        borderRadius="5px"
        sx={{
          backgroundColor: "rgb(106,106,106)",
          background: "radial-gradient(circle, rgba(106,106,106,1) 0%, rgba(66,66,66,1) 100%)",
          color: "#eeeeee"
        }}>
        <Typography variant="h3" sx={{mb: "10px"}}>Did you enjoy {props.tourName}?</Typography>

        <StarSelector
          boxWidth={250}
          score={myRating?.ratingScore} 
          onScoreUpdated={handleScoreUpdated}
        />

        <FormControl fullWidth disabled={!myRating} sx={{ mt: "20px", mb: "10px", opacity: myRating ? 1.0 : 0.3 }}>
          <TextField fullWidth variant="outlined" multiline rows="2" label="Comments (optional)" value={myRating?.reviewText ?? ""} onChange={handleChangeReviewText} />
        </FormControl>
      </Box>

      <DialogActions>
        <Button variant="outlined"
        onClick={() => handleSkip()}
        >Skip</Button>

        <Button variant="contained"
        onClick={() => handleSave()}
        disabled={!myRating}
        >
          Send</Button>
      </DialogActions>
    </Dialog>
  );
}

import { Box, Typography } from "@mui/material";
import ToursBrowser from "../app/ToursBrowser";
import { PageBox } from "components/chrome/PageBox";
import MediaLibrary from "components/edit/MediaLibrary";

import "./MediaLibraryPage.css"

export default function MediaLibraryPage() {
  return (
    <PageBox>
      <Typography variant="h1" color="primary">
        Media Library
      </Typography>
      
      <MediaLibrary
            onRequestCancel={() => {}}
            onConfirmMedia={() => {}}
            disableSelection
            hideActionButtons
            sx={{p: 0}}
        />

        <Box sx={{mt: "20px"}}>
            <h6>Credits and Attributions</h6>
            Image credits are located within the metadata for each image - click on the 'Edit' icon on the image to view.
        </Box>

    </PageBox>
  );
}

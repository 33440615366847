import { TTPaletteOptions, TTThemeOptions } from "@mui/material/styles/createPalette";
import { TypographyOptions } from "@mui/material/styles/createTypography";

// https://mui.com/material-ui/customization/theme-components/#theme-default-props
// https://mui.com/material-ui/customization/palette/#adding-new-colors
const myPalette: TTPaletteOptions = {
  primary: {
    main: "#DE191E"
  },
  secondary: {
    main: "#145899"
  },
  tertiary: {
    main: "#0F8883"
  }
};

// https://mui.com/material-ui/customization/typography/
const myType: TypographyOptions = {
  fontFamily: "Quicksand, sans-serif",
  fontSize: 14,
  fontWeightRegular: 400,
  fontWeightBold: 700,
  fontWeightMedium: 500,
  fontWeightLight: 200,

  h1: {
    fontSize: "2.1rem",
    fontWeight: 700,
    lineHeight: 1.35
  },
  h2: {
    fontSize: "1.8rem",
    fontWeight: 700,
    lineHeight: 1.3
  },
  h3: {
    fontSize: "1.6rem",
    fontWeight: 700,
    lineHeight: 1.2
  },
  h4: {
    fontSize: "1.4rem",
    fontWeight: 600,
    lineHeight: 1.2
  },
  h5: {
    fontSize: "1.2rem",
    fontWeight: 600,
    lineHeight: 1.2
  },
  h6: {
    fontSize: "1.1rem",
    fontWeight: 500,
    lineHeight: 1.2
  },
  body1: {
    fontSize: "1.05rem",
    fontWeight: 400,
    lineHeight: 1.3
  },
  body2: {
    fontSize: "1.0rem",
    fontWeight: 400,
    lineHeight: 1.25
  },
  caption: {
    fontSize: "0.85rem",
    fontWeight: 500,
    lineHeight: 1.25
  },
  button: {
    fontSize: "0.9rem",
    fontWeight: 700
  }
};

const ttThemeOptions: TTThemeOptions = {
  palette: myPalette,
  typography: myType
  // components: {
  //   MuiAppBar: {
  //     defaultProps: {
  //     }
  //   },
  // },
};

export default ttThemeOptions;
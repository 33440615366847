import React, { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import { renderStars } from "./renderStars";

interface StarSelectorProps {
  score?: number; // Score from 0 to 5 in increments of 0.5
  onScoreUpdated: (newScore: number) => void;
  boxWidth: number; // Width of the Box element in pixels
}


const StarSelector: React.FC<StarSelectorProps> = (props) => {
  const { score, onScoreUpdated, boxWidth } = props;
  const [hoveredScore, setHoveredScore] = useState<number | null>(null);

  const calculateScoreFromX = (x: number) => {
    var percentage = x / boxWidth;
    // Bump over to the right a little to avoid accidental reduced scoring
    percentage = percentage + 0.1;
    // Clamp
    percentage = Math.max(  Math.min( percentage, 1), 0 );
    return Math.round(percentage * 10) / 2;
  };

  const handleBoxClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const boxRect = event.currentTarget.getBoundingClientRect();
    const hoverX = event.clientX - boxRect.left;
    const newScore = calculateScoreFromX(hoverX);
    onScoreUpdated(newScore);
  };

  

  const scoreToWords = (score?: number) => {
    if (score === undefined) {
      return " ";
    } else if (score > 4) {
      // 4.5-5
      return "Great 😀";
    } else if (score > 3) {
      // 3.5-4
      return "Good 🙂";
    } else if (score > 2) {
      // 2.5-3
      return "OK 😐";
    } else if (score > 1) {
      // 1.5-2
      return "Not great 🫤";
    } else {
      return "Poor 😞";
    }
  };

  return (
    <Stack direction="column" alignItems="end" sx={{ width: props.boxWidth }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        fontSize="30px"
        sx={{ width: "100%", overflow: "hidden" }}
        onClick={handleBoxClick}
        onMouseMove={(event) => {
          const boxRect = event.currentTarget.getBoundingClientRect();
          const hoverX = event.clientX - boxRect.left;
          const newHoveredScore = calculateScoreFromX(hoverX);
          setHoveredScore(newHoveredScore);
        }}
        onMouseLeave={() => {
          setHoveredScore(null);
        }}>
        {renderStars(hoveredScore !== null ? hoveredScore : score !== undefined ? score : 0)}
      </Stack>
      <Typography variant="h6" height="20px">
        {scoreToWords(score)}
      </Typography>
    </Stack>
  );
};

export default StarSelector;

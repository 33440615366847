import L, { LatLngBounds, LatLngLiteral, LocationEvent, LocationEventHandlerFn } from "leaflet";
import { useEffect } from "react";
import { useMap, useMapEvents } from "react-leaflet";

export interface MapControllerProps {
  center?: LatLngLiteral;
  animateCenterUpdates?: boolean;
  locationfound?: (event: LocationEvent, map: L.Map) => void | undefined;
  onBoundsChanged?: (newBounds:LatLngBounds) => void;
}

export default function MapController(props: MapControllerProps) {

  // Pass through locationFound events
  const map = useMapEvents({
    locationfound(e) {
      if (props.locationfound) { props.locationfound(e, map); }
    },
    dragend(e) {
      if (props.onBoundsChanged) { props.onBoundsChanged(map.getBounds())};
    },
  });

  // Inject an unsupported property to try and support tapHold (long tap)
  var opt = map.options as any;
  opt["tapHold"] = true;

  // Here's how we would also listen for the turning on/off of the LocateControl
  // map?.addEventListener("locateactivate", (e) => {
  //   if (props.locateActivated) props.locateActivated();
  // });

  useEffect(() => {
    if (!map) return;

    if (props.center) {
      map.panTo(props.center, { animate: props.animateCenterUpdates });
    }
  }, [props.center, map]);

  if (map) {
    map.attributionControl.setPrefix("Leaflet | ");

  }

  return <></>;
}

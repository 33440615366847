import React, { useContext, useEffect, useState } from "react";

import { useConfirm } from "material-ui-confirm";

import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";

import AddIcon from "@mui/icons-material/Add";
import PsychologyIcon from '@mui/icons-material/Psychology';

import question from "../../data/model/question";

import { immutableMove } from "../../utils/utils-arrays";
import QuestionEditorDialog from "./QuestionEditorDialog";
import { SBAlertContext } from "./SBAlertContext";
import { FormControl, List, Skeleton, useTheme } from "@mui/material";
import useAPI from "services/useHunterApi";
import { unformatAndTruncateRichContent } from "utils/utils-strings";
import { LatLngExpression, LatLngLiteral } from "leaflet";
import markerSource from "data/model/geo/markerSource";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import StripePane from "components/chrome/StripePane";
import QuestionPlanner from "./QuestionPlanner";
import { getRandomInt } from "utils/utils-maths";
import mediaItem from "data/model/mediaItem";
import { TTTheme } from "@mui/material/styles/createPalette";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import QuestionListItem from "./QuestionListItem";
import RequirePermission from "components/auth/RequirePermission";
import RefineQuestionsDialog from "./RefineQuestionsDialog";

interface TourQuestionsEditorProps {
  tourId: number;
  defaultTavatar?: mediaItem;
  onTourRouteUpdated: (newDuration?: number, newDistance?: number) => void;
  onSelectedQuestionIndexUpdated: (newIndex: number) => void;
}

export default function TourQuestionsEditor(props: TourQuestionsEditorProps) {
  const MAX_QUESTION_DISPLAY_CHARS_MAP: number = 60;
  const confirm = useConfirm();

  const [questions, setQuestions] = useState<question[]>([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number | undefined>(undefined);
  const [editingQuestionIndex, setEditingQuestionIndex] = useState<number | undefined>(undefined);

  const [questionMarkers, setQuestionMarkers] = useState<markerSource[]>([]);
  const [refineDialogOpen, setRefineDialogOpen] = useState<boolean>(false);
  
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const theme = useTheme<TTTheme>();


  // Fetch a list of the questions for this hunt on first render
  useEffect(() => {
    callAPI<void, question[]>(`/api/question/tour/${props.tourId}`, "GET")
      .then((qs) => {
        setQuestions(qs);
      })
      .catch((error) => {});
  }, [props.tourId]);


  // Our parent keeps a shadow copy of the selected question index/0
  useEffect(() => {
      props.onSelectedQuestionIndexUpdated((selectedQuestionIndex !== undefined) ? selectedQuestionIndex : 0);
  }, [selectedQuestionIndex])


  /**************************
  // TOUR QUESTION EVENTS
  **************************/
  const handleClickQuestion = (index: number) => {
    setSelectedQuestionIndex(index);
  };

  const handleClickQuestionButtonDelete = async (index: number) => {
    try {
      await confirm({ title: "Delete this question", description: "This will permanently delete this question. Are you sure?", confirmationText: "Delete" });
      await callAPINoResponseBody<void>(`/api/question/${questions[index].id}`, "DELETE");

      // Unselect question
      if (selectedQuestionIndex === index) {
        setSelectedQuestionIndex(undefined);
      }
      // Delete here
      const lessQuestions: question[] = Object.assign([], questions);
      lessQuestions.splice(index, 1);
      setQuestions(lessQuestions);
      saveOrderofQuestions(lessQuestions);
    } catch {} /* User cancelled the deletion */
  };

  const handleClickQuestionButtonDuplicate = async (insertIndex: number) => {
    try {
      await confirm({ title: "Duplicate this question", description: "This will duplicate this question. Are you sure?", confirmationText: "Duplicate" });
      var dupeQuestion = await callAPI<void, question>(`/api/question/${questions[insertIndex].id}/copy/tour/${props.tourId}/index/${insertIndex}`, "GET");
      // Unselect question
      if (selectedQuestionIndex === insertIndex) {
        setSelectedQuestionIndex(undefined);
      }
      // Duplicate
      const clonedQuestions: question[] = Object.assign([], questions);
      clonedQuestions.splice(insertIndex, 0, dupeQuestion);
      setQuestions(clonedQuestions);
      saveOrderofQuestions(clonedQuestions);
    } catch {} /* User cancelled the duplication */
  };

  const handleClickQuestionButtonMove = (index: number, delta: number) => {
    reorderQuestions(index, index + delta);
  };

  const reorderQuestions = (sourceIndex: number, destIndex: number) => {
    const newQuestions: question[] = immutableMove(questions as [], sourceIndex, destIndex);
    setQuestions(newQuestions);
    saveOrderofQuestions(newQuestions); // Assume that server will accept the re-ordered questions
    // If question was selected...
    if (selectedQuestionIndex == sourceIndex) {
      setSelectedQuestionIndex(destIndex);
    }
  };

  // Push the new order of specified questions to server.  NB don't rely on this being a state variable questions, due to potential race conditionsn in setting that, always pass the questions
  const saveOrderofQuestions = async (theQuestions:question[]) => {
    var questionArray = theQuestions.map((q) => q.id);
    try {
      await callAPINoResponseBody<number[]>(`/api/tour/${props.tourId}/questionorder`, "PUT", questionArray);
    }
    catch {}
  };

  const handleClickAddQuestionButton = () => {
    addBlankQuestion(true);
  };

  const handleClickRefineQuestionButton = () => {
    setRefineDialogOpen(true);
  };

  const addBlankQuestion = async (editNewQuestion: boolean) => {
    //  (unless the final item is already blank)
    if (questions.length === 0 || questions[questions.length - 1].contentFormatted.length > 0) {
      try {
        var newQuestion = await callAPI<void, question>(`/api/question/create/tour/${props.tourId}`, "POST");

        // Clone the questions and add the new question
        const moreQuestions: question[] = Object.assign([], questions);
        const newIndex = (selectedQuestionIndex !== undefined) ? selectedQuestionIndex + 1 : moreQuestions.length ;
        moreQuestions.splice(newIndex, 0, newQuestion);

        // Make served aware of new question ordering
        saveOrderofQuestions(moreQuestions);

        // Save changes
        setQuestions(moreQuestions);
        if (editNewQuestion) {
          setEditingQuestionIndex(newIndex);
        }
      } catch {}
    }
  };
  

  const handleRefinerUpdateQuestions = (refinedQuestions: question[]) => {
    
    // Merge the new question into all questions, replacing the items at the same indices when the condition is met.
    const newQuestions = questions.map((item) => (refinedQuestions.find(rq => rq.id === item.id) ?? item));
    setQuestions(newQuestions); // ...to be clear, this then re-renders, which means const editingQuestion changes, which means our child re-renders its props.question

  };

  /**************************
  // QUESTION-EDITOR EVENTS
  **************************/

  const handleQuestionEditorUpdateQuestion = (newQuestion: question) => {
    updateQuestion(newQuestion);
  };

  const updateQuestion = (newQuestion: question) => {
    // Merge the new question into all questions, replacing the item at the same index when the condition is met.
    const newQuestions = questions.map((item) => (item.id === newQuestion.id ? newQuestion : item));
    setQuestions(newQuestions); // ...to be clear, this then re-renders, which means const editingQuestion changes, which means our child re-renders its props.question
  };

  const saveQuestion = async (question: question) => {
    await callAPINoResponseBody<question>(`/api/question`, "PUT", question);
  };

  const handleQuestionEditorRequestCancel = () => {
    // TODO: wipe changes?
    setEditingQuestionIndex(undefined);
  };

  const handleQuestionEditorRequestSaveAndClose = () => {
    handleQuestionEditorRequestSaveAndMoveBy(0);
    setEditingQuestionIndex(undefined);
  };

  const handleQuestionEditorRequestSaveAndMoveNext = () => {
    handleQuestionEditorRequestSaveAndMoveBy(1);
  };

  const handleQuestionEditorRequestSaveAndMovePrevious = () => {
    handleQuestionEditorRequestSaveAndMoveBy(-1);
  };
  const handleQuestionEditorRequestSaveAndMoveBy = (delta: number) => {
    if (!editingQuestion) return;

    // Create new question on 'move next'?
    if (delta === 1 && editingQuestionIndex === questions.length - 1) {
      addBlankQuestion(true); // also sets it as the new editing question
    } else {
      setEditingQuestionIndex((editingQuestionIndex ?? 0) + delta);
    }
    //  and (in the background is fine) push updated question to the server
    saveQuestion(editingQuestion);
  };

  const handleNewGeneratedQuestions = (newQuestions: question[]) => {
    // Insert the new question in our list
    const moreQuestions: question[] = Object.assign([], questions);
    const newIndex = (selectedQuestionIndex !== undefined) ? selectedQuestionIndex+1 : moreQuestions.length;
    for (var newQ of newQuestions) {
      moreQuestions.splice(newIndex, 0, newQ);
    }
    setQuestions(moreQuestions);

    // Select the first
    setSelectedQuestionIndex(newIndex);

    // Save the new order
    saveOrderofQuestions(moreQuestions);
  };


  // Deal with (question) markers that are moved in the QuestionPlanner -> ExplorerMap
  const handleMovedQuestionMarkerSource = (marker: markerSource, newPosition: LatLngLiteral) => {
    console.log("Handle Moved Question Marker Source");
    if (marker.dataClass !== "question") return;

    var q = marker.dataObject as question; // NB: don't use marker.ID, it's not the same as question.ID
    if (q !== undefined) {
      console.log("Found question");
      if (q?.waypoint) {
          console.log("Updating waypoint");
          q.waypoint.location = newPosition;

          saveQuestion(q);
          updateQuestion(q); // don't wait for server, we'll prioritise speed here
      }
    }
    
  };

  const editingQuestion: question | undefined = editingQuestionIndex !== undefined ? questions[editingQuestionIndex] : undefined;

  // Suggest next waypoint?
  var suggestingWaypointLocation: LatLngLiteral | undefined = undefined;
  if (editingQuestionIndex !== undefined) {
    for (var i = editingQuestionIndex - 1; i >= 0; i--) {
      if (questions[i].waypoint) {
        suggestingWaypointLocation = questions[i].waypoint?.location;
        break;
      }
    }
  }


  // When questions change, convert them to marker source data
  useEffect(() => {
    setQuestionMarkers(
      questions
        .map((q, index) => {
          return { index: index, question: q };
        })
        .filter((q) => q.question.waypoint)
        .map((x, index) => {
          return {
            id: `Q ${x.question.id?.toString() ?? getRandomInt(1, 50000).toString()}`,
            location: x.question.waypoint?.location,
            name: `Q${(x.index + 1).toString()}`,
            description: unformatAndTruncateRichContent(x.question.contentFormatted, MAX_QUESTION_DISPLAY_CHARS_MAP),
            dataClass: "question",
            dataObject: x.question,
            iconLabel: (x.index + 1).toString()
          } as markerSource;
        })
    );
  }, [questions]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    reorderQuestions(result.source.index, result.destination.index);
  };

  return (
    <>
      <FormControl fullWidth>
        <Grid container columnSpacing={2}>
          {/* COLUMN ONE - QUESTIONS LIST */}
          <Grid  xs={12} sm={6}>
            
            <StripePane title="Steps">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <List dense sx={{ bgcolor: "background.paper" }}>
                        {!questions && (
                          <>
                            <ListItem key={"sk1"}>
                              <Skeleton variant="rectangular" animation="wave" width="100%" height={30}></Skeleton>
                            </ListItem>
                            <ListItem key={"sk2"}>
                              <Skeleton variant="rectangular" animation="wave" width="100%" height={30}></Skeleton>
                            </ListItem>
                            <ListItem key={"sk3"}>
                              <Skeleton variant="rectangular" animation="wave" width="100%" height={30}></Skeleton>
                            </ListItem>
                            <ListItem key={"sk4"}>
                              <Skeleton variant="rectangular" animation="wave" width="100%" height={30}></Skeleton>
                            </ListItem>
                            <ListItem key={"sk5"}>
                              <Skeleton variant="rectangular" animation="wave" width="100%" height={30}></Skeleton>
                            </ListItem>
                          </>
                        )}
                        {questions?.map((q, index) => (
                          <Draggable key={`${q.id}`} draggableId={q.id.toString()} index={index}>
                            {(provided) => (
                              <QuestionListItem
                                index={index}
                                provided={provided}
                                question={q}
                                key={q.id.toString()}
                                disableMoveDownButton={index === questions.length - 1}
                                selected={index == selectedQuestionIndex}
                                onClickQuestion={handleClickQuestion}
                                onClickQuestionButtonDelete={handleClickQuestionButtonDelete}
                                onClickQuestionButtonDuplicate={handleClickQuestionButtonDuplicate}
                                onClickQuestionButtonMove={handleClickQuestionButtonMove}
                                onClickQuestionButtonEdit={(index: number) => {
                                  setEditingQuestionIndex(index);
                                }}
                              />
                            )}
                          </Draggable>
                        ))}
                      </List>

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <Button fullWidth={false} variant="outlined" color="primary" endIcon={<AddIcon />} sx={{ mb: "10px;" }} onClick={handleClickAddQuestionButton}>
                Add Step
              </Button>

              <RequirePermission name="ComposeAI">
                <Button variant="outlined" 
                      disabled={selectedQuestionIndex === undefined}
                      endIcon={<PsychologyIcon />} sx={{ mb: "10px", ml: "10px;", 
                      color: theme.palette.tertiary.main, borderColor: theme.palette.tertiary.main }} 
                      onClick={handleClickRefineQuestionButton}>
                  Refine Question
                </Button>
              </RequirePermission>
            </StripePane>
          </Grid>

          {/* COLUMN TWO - PLANNING ASSISTANT */}
          <Grid xs={12} sm={6} sx={{ mt: {xs: "20px", sm: "0px"} }}>
            <QuestionPlanner
              tourId={props.tourId}
              questionsMarkersData={questionMarkers}
              selectedQuestionLocation={(selectedQuestionIndex !== undefined) ? questions[selectedQuestionIndex]?.waypoint?.location : undefined  }
              onGeneratedNewQuestions={handleNewGeneratedQuestions}
              onTourRouteUpdated={props.onTourRouteUpdated}
              onMovedQuestionMarker={handleMovedQuestionMarkerSource}
            />
          </Grid>
        </Grid>
      </FormControl>

      {editingQuestion && (
        <QuestionEditorDialog
          open={editingQuestion !== undefined}
          fullScreen // fullScreen={mqXSOrDown}
          question={editingQuestion}
          title={`Step ${(editingQuestionIndex ?? 0) + 1}`}
          showNext={!(editingQuestionIndex === questions.length - 1 && editingQuestion!.contentFormatted.length < 1)}
          showNextAsAddButton={editingQuestionIndex === questions.length - 1}
          showPrevious={(editingQuestionIndex ?? 0) > 0}
          defaultWaypointLocation={suggestingWaypointLocation}
          defaultTavatar={props.defaultTavatar}
          onUpdateQuestion={handleQuestionEditorUpdateQuestion}
          onRequestNext={handleQuestionEditorRequestSaveAndMoveNext}
          onRequestPrevious={handleQuestionEditorRequestSaveAndMovePrevious}
          onRequestSave={handleQuestionEditorRequestSaveAndClose}
          onRequestCancel={handleQuestionEditorRequestCancel}
        />
      )}

      <RefineQuestionsDialog
          open={refineDialogOpen}
          questions={(selectedQuestionIndex !== undefined) ? [questions[selectedQuestionIndex]] : []}
          onRequestClose={() => {setRefineDialogOpen(false)}}
          onUpdateQuestions={handleRefinerUpdateQuestions}
      />

    </>
  );
}

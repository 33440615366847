import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import "./QuestionEditorDialog.css";

import { SelectChangeEvent, Button, Dialog, DialogContent, FormControl, IconButton, MenuItem, Select, Box, Container, DialogTitle, Stack, useTheme, useMediaQuery, Typography, DialogActions, AppBar, Slider, TextField } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import question, { QuestionResponseTypes } from "../../data/model/question";

import { MDXEditor, MDXEditorMethods, headingsPlugin, imagePlugin, linkDialogPlugin, linkPlugin, listsPlugin, quotePlugin, thematicBreakPlugin } from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import MediaLibraryDialog from "./MediaLibraryDialog";
import mediaItem from "data/model/mediaItem";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import QuestionEdAdvancedDialog from "./QuestionEdAdvancedDialog";
import CannedResponsesEditor from "./CannedResponsesEditor";

import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import TuneIcon from "@mui/icons-material/Tune";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CloseIcon from "@mui/icons-material/Close";

import StyledMediaItem from "./StyledMediaItem";
import styledMediaItem from "data/model/styledMediaItem";
import { useConfirm } from "material-ui-confirm";
import CollectionEditor from "./CollectionEditor";
import Waypoint from "./Waypoint";
import { waypoint } from "data/model/waypoint";
import { ButtonGroup } from "@aws-amplify/ui-react";
import { LatLngLiteral } from "leaflet";
import StripePane from "components/chrome/StripePane";
import WorkingQuestionSpace from "components/play/WorkingQuestionSpace";
import workingQuestion from "data/model/workingQuestion";
import QuestionPreview from "./QuestionPreview";
import { TTTheme } from "@mui/material/styles/createPalette";
import GenericCollectionEditor from "./GenericCollectionEditor";
import customButton from "data/model/gameResponseMessageButton";
import CustomButtonDef from "./CustomButtonDef";

interface QuestionEditorDialogProps {
  title: string;
  open: boolean;
  fullScreen?: boolean;
  showNext: boolean;
  showPrevious: boolean;
  showNextAsAddButton: boolean;
  question: question;
  defaultWaypointLocation?: LatLngLiteral;
  defaultTavatar?: mediaItem;
  onRequestSave: () => void;
  onRequestCancel: () => void;
  onRequestNext: () => void;
  onRequestPrevious: () => void;
  onUpdateQuestion: (updatedQuestion: question) => void;
}

function QuestionEditorDialog(props: QuestionEditorDialogProps) {
  const confirm = useConfirm();
  const [isQEADOpen, setIsQEADOpen] = useState<boolean>(false);

  const theme = useTheme<TTTheme>();
  const mqSMOrDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mqXSOrDown = useMediaQuery(theme.breakpoints.down("xs"));
  const mqSMOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mqSMorLarge = useMediaQuery(theme.breakpoints.between("md", "xl"));

  const [isLibraryDialogOpen, setIsLibraryDialogOpen] = useState<boolean>(false);

  // Reference to rich editor
  let refSelect = useRef<HTMLSelectElement>(null);
  let mdxEditorContent = useRef<MDXEditorMethods>(null);
  let mdxEditorHint = useRef<MDXEditorMethods>(null);

  // When the question changes, make a one-time push of content into the editors, otherwise they don't refresh/update properly
  useEffect(() => {
    syncContentToEditors();

    // Scroll to top
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [props.question.id]);

  const syncContentToEditors = () => {
    if (mdxEditorHint !== null) {
      // Check if the editor is already sync'd, as its own internal 'changed' events update the question too, so we don't want to double update, which also changes where the edit cursor is
      if (mdxEditorHint.current?.getMarkdown() !== props.question.hintFormatted) {
        mdxEditorHint.current?.setMarkdown(props.question.hintFormatted ?? "");
      }
    } else {
      console.log("No hint editor ref yet - can't sync it.");
    }

    if (mdxEditorContent !== null) {
      // Check if the editor is already sync'd, as its own internal 'changed' events update the question too, so we don't want to double update, which also changes where the edit cursor is
      if (mdxEditorContent.current?.getMarkdown() !== props.question.contentFormatted) {
        mdxEditorContent.current?.setMarkdown(props.question.contentFormatted);
      }
    } else {
      console.log("No content editor ref yet - can't sync it.");
    }
  };

  const handleChangeExplanation = (e: ChangeEvent<HTMLInputElement>) => {
    props.onUpdateQuestion({
      ...props.question,
      explanation: e.target.value
    });
  };

  const handleChangeResponseType = (event: SelectChangeEvent<string>) => {
    const updatedQuestion = {
      ...props.question,
      responseType: event.target.value as QuestionResponseTypes
    };
    props.onUpdateQuestion(updatedQuestion);
  };

  const handleChangeMDXContent = (markdown: string) => {
    if (props.question && props.question.id >= 0 && props.question.contentFormatted !== markdown) {
      const updatedQuestion = {
        ...props.question,
        contentFormatted: markdown.slice()
      };
      props.onUpdateQuestion(updatedQuestion);
    }
  };
  const handleChangeMDXHint = (markdown: string) => {
    if (props.question && props.question.id >= 0 && props.question.hintFormatted !== markdown) {
      const updatedQuestion = {
        ...props.question,
        hintFormatted: markdown.slice()
      };
      props.onUpdateQuestion(updatedQuestion);
    }
  };

  const handleOnCorrectResponsesUpdated = (newCorrectResponses: string[]) => {
    const updatedQuestion = {
      ...props.question,
      correctResponses: newCorrectResponses
    };
    props.onUpdateQuestion(updatedQuestion);
  };
  const handleOnCannedResponsesAndCorrectResponsesUpdated = (newCannedResponses: string[], newCorrectResponses: string[]) => {
    const updatedQuestion:question = {
      ...props.question,
      cannedResponses: newCannedResponses,
      correctResponses: newCorrectResponses
    };
    props.onUpdateQuestion(updatedQuestion);
  };



  /****************************** */
  // POST QUESTION INFO
  /******************************* */
  const handleOnPostGameResourcesUpdated = (newPostQuestionResources: customButton[]) => {
    const updatedQuestion:question = {
      ...props.question,
      postQuestionResources: newPostQuestionResources
    };
    props.onUpdateQuestion(updatedQuestion);
  };


  /****************************** */
  // MEDIA LIBRARY
  /******************************* */
  const mlDialogRequestCancel = () => {
    setIsLibraryDialogOpen(false);
  };

  const mlDialogRequestInsertMedia = (media: mediaItem) => {
    // Insert the new media at the end of the file
    const newMDContent = `${mdxEditorContent.current?.getMarkdown()}\n\n<img src='${media.url}' width='10%;' height='10%;' />`;
    mdxEditorContent.current?.setMarkdown(newMDContent);
    setIsLibraryDialogOpen(false);
  };

  const handleUpdateContentMedia = (media?: styledMediaItem) => {
    const updatedQuestion: question = {
      ...props.question,
      contentStyledMedia: media
    };
    props.onUpdateQuestion(updatedQuestion);
  };

  const handleRequestDeleteContentMedia = (): Promise<boolean> => {
    return confirm({
      title: "Clear this image?",
      description: "This will clear this image.  The original image will still remain in your media library.\r\n\r\nAre you sure?",
      confirmationText: "Delete"
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        /* User cancelled the deletion */
        return false;
      });
  };

  const handleUpdateHintMedia = (media?: styledMediaItem) => {
    const updatedQuestion: question = {
      ...props.question,
      hintStyledMedia: media
    };
    props.onUpdateQuestion(updatedQuestion);
  };

 
  const handleUpdateExplanationMedia = (newMedia?: mediaItem) => {
    props.onUpdateQuestion({
      ...props.question,
      explanationMediaItem: newMedia
    });
  };

  const handleRequestDeleteMedia = (): Promise<boolean> => {
    return confirm({
      title: "Clear this image?",
      description: "This will clear this image.  The original image will still remain in your media library.\r\n\r\nAre you sure?",
      confirmationText: "Delete"
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        /* User cancelled the deletion */
        return false;
      });
  };

  const handleRequestDeleteWaypoint = (): Promise<boolean> => {
    return confirm({
      title: "Clear this waypoint?",
      description: "This will delete this waypoint.\r\n\r\nAre you sure?",
      confirmationText: "Delete"
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        /* User cancelled the deletion */
        return false;
      });
  };

  const handleUpdateStyledWaypoint = (shouldShowMap: boolean | undefined, waypoint: waypoint | undefined) => {
    const updatedQuestion: question = {
      ...props.question,
      waypoint: waypoint,
      showWaypointMap: shouldShowMap
    };
    props.onUpdateQuestion(updatedQuestion);
  };


  const wrapMedia = (mediaItem?: mediaItem):styledMediaItem|undefined => {
    return mediaItem ?
    {
    item: mediaItem ?? null,
    style: { fit: "Contain"} // Placeholder
    } : undefined
  }


  return (
    <>
      <Dialog open={props.open} fullWidth fullScreen={props.fullScreen} maxWidth="xl">
        <AppBar position="sticky">
          <DialogTitle>
            <Stack direction="row" gap={{ xs: "4px", md: "20px" }} alignItems="center" justifyContent="start" sx={{ pl: "20px", pr: "20px", width: "100%", height: "30px" }}>
              <Typography variant="h3">{props.title}</Typography>

 
              <ButtonGroup marginLeft="auto">
                <IconButton
                  disabled={!props.showPrevious}
                  color="inherit"
                  onClick={() => {
                    props.onRequestPrevious();
                  }}>
                  <ArrowBackIcon />
                </IconButton>

                <IconButton
                  disabled={!props.showNext}
                  color="inherit"
                  onClick={() => {
                    props.onRequestNext();
                  }}>
                  {props.showNextAsAddButton ? (
                    <Stack direction="row" gap="0">
                      <ArrowForwardIcon />
                      <AddBoxIcon fontSize="small" sx={{ ml: "-7px", pb: "5px" }} />
                    </Stack>
                  ) : (
                    <Stack direction="row" gap="0">
                      <ArrowForwardIcon />
                      <AddBoxIcon fontSize="small" sx={{ opacity: 0, ml: "-7px", pb: "5px" }} />
                    </Stack>
                  )}
                </IconButton>

                <IconButton
            color="inherit"
              sx={{ml: "auto"}} // Pushes it to the right
              onClick={() => {
                props.onRequestSave();
              }}>
              <CloseIcon color="inherit" />
            </IconButton>
            
              </ButtonGroup>
            </Stack>
          </DialogTitle>
        </AppBar>

        <DialogContent>
          {/* MAIN GRID OF CONTROLS FOR EDITING QUESTION */}
          <Grid container spacing={2} wrap="wrap" justifyContent="space-between" sx={{ minHeight: { xs: "400px", sm: "580px", md: "640px" }, mt: "0px" }}>
            {/* LEFT COLUMN */}
            <Grid xs={12} md={6} xl={5}>
              <FormControl sx={{ width: "100%", mb: "12px" }}>
                <Select ref={refSelect} sx={{ minWidth: "220px" }} id="questionType" value={props.question.responseType} onChange={handleChangeResponseType}>
                  <MenuItem value={"AcknowledgementOnly"}>
                    <InfoIcon color="primary" sx={{ mr: "7px" }} />
                    Instruction or Information
                  </MenuItem>
                  <MenuItem value={"MultiChoice"}>
                    <SpeakerNotesIcon color="primary" sx={{ mr: "7px" }} />
                    Multi-choice question
                  </MenuItem>
                  <MenuItem value={"FreeText"}>
                    <QuestionAnswerIcon color="primary" sx={{ mr: "7px" }} />
                    Free text question
                  </MenuItem>
                </Select>
              </FormControl>

              <StripePane defaultExpanded={true}
                  title={props.question.responseType === "AcknowledgementOnly" ? "Instruction" : "Question"}>
                <MDXEditor
                  ref={mdxEditorContent}
                  autoFocus={props.question?.contentFormatted.length < 1}
                  placeholder={`Type your ${props.question.responseType === "AcknowledgementOnly" ? "instruction" : "question"} here`}
                  className="mdx-editor-content"
                  markdown={props.question?.contentFormatted ?? ""}
                  onChange={handleChangeMDXContent}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    linkPlugin(),
                    linkDialogPlugin({ linkAutocompleteSuggestions: [] }),
                    imagePlugin()
                    // toolbarPlugin({
                    //   toolbarContents: () => (
                    //     <>
                    //       {" "}
                    //       <UndoRedo />
                    //       <BoldItalicUnderlineToggles />
                    //       <BlockTypeSelect />
                    //       <CreateLink />
                    //       <InsertThematicBreak />
                    //       <ListsToggle />{" "}
                    //       <IconButton onClick={clickAddImageButton}>
                    //         <ImageIcon />
                    //       </IconButton>
                    //     </>
                    //   )
                    // })
                  ]}
                />

                <Stack direction="column" spacing="0">
                  <StyledMediaItem
                    collapsedHeight={mqSMOrDown ? "50px" : "70px"}
                    onUpdateMedia={handleUpdateContentMedia}
                    onRequestDeleteMedia={handleRequestDeleteContentMedia}
                    styledItem={props.question?.contentStyledMedia ?? undefined}
                    mediaLibraryQuery={{ slug: { exclude: "sys-" } }}
                  />
                  <hr color="#000000" />
                  <Waypoint
                    fullScreen={mqSMOrDown}
                    collapsedHeight={mqSMOrDown ? "50px" : "70px"}
                    waypoint={props.question.waypoint}
                    defaultCenter={props.defaultWaypointLocation}
                    showMap={props.question.showWaypointMap ?? true}
                    onUpdateStyledWaypoint={handleUpdateStyledWaypoint}
                    onRequestDeleteWaypoint={handleRequestDeleteWaypoint}
                  />
                </Stack>
              </StripePane>



              {/* HINT */}
              {props.question.responseType !== "AcknowledgementOnly" && (
                <>
              <Container sx={{ marginTop: "20px" }} />
              <StripePane defaultExpanded={true }
                title="Hint">
                <Box component="div" className="mdx-editor-hint-container">
                  <FormControl fullWidth>
                    <MDXEditor
                      ref={mdxEditorHint}
                      placeholder="A hint shown when
                  wrong or when requested."
                      className="mdx-editor-hint"
                      markdown={props.question?.hintFormatted ?? ""}
                      onChange={handleChangeMDXHint}
                      plugins={[
                        headingsPlugin(),
                        listsPlugin(),
                        quotePlugin(),
                        thematicBreakPlugin()
                        // toolbarPlugin({
                        //   toolbarContents: () => (
                        //     <>
                        //       {" "}
                        //       <UndoRedo />
                        //       <BoldItalicUnderlineToggles />
                        //       <BlockTypeSelect />
                        //       <InsertThematicBreak />
                        //       <ListsToggle />{" "}
                        //     </>
                        //   )
                        // })
                      ]}
                    />
                  </FormControl>
                </Box>

                <StyledMediaItem
                  collapsedHeight={mqSMOrDown ? "50px" : "70px"}
                  onUpdateMedia={handleUpdateHintMedia}
                  onRequestDeleteMedia={handleRequestDeleteMedia}
                  styledItem={props.question?.hintStyledMedia ?? undefined}
                  mediaLibraryQuery={{ slug: { exclude: "sys-" } }}
                />
              </StripePane>
              </>
              )}



            </Grid>

            {/* RIGHT COLUMN */}
            <Grid xs={12} md={6} xl={5} >
              
              {/* MINI PREVIEW BOX - DESKTOP ONLY */}
              {mqSMorLarge && (<QuestionPreview question={props.question} defaultTavatar={props.defaultTavatar} /> )}
              {/* END MINI PREVIEW BOX */}

              {props.question.responseType !== "AcknowledgementOnly" && (
              <StripePane title="Answers">
                <Box>
                  {props.question.responseType === "FreeText" && <CollectionEditor collection={props.question.correctResponses} canDeleteItems onUpdateCollection={handleOnCorrectResponsesUpdated} itemLabel="Correct answer" />}

                  {props.question.responseType === "MultiChoice" && <CannedResponsesEditor array={props.question.cannedResponses} correctArray={props.question.correctResponses} onUpdateArrays={handleOnCannedResponsesAndCorrectResponsesUpdated} />}
                </Box>
              </StripePane>
              )}
            
               {/* EXPLANATION */}
               {props.question.responseType !== "AcknowledgementOnly" && (
                <>
              <Container sx={{ marginTop: "20px" }} />
              <StripePane defaultExpanded={(props.question?.explanation?.length ?? 0) > 0}
                title="Explanation">
                
              <FormControl fullWidth>
              <TextField
              multiline
              rows={2}
              id="explanation"
              label="Explanation shown after question complete, regardless of outcome"
              size="small"
              variant="filled"
              value={props.question?.explanation ?? ""}
              fullWidth
              onChange={handleChangeExplanation}
            />
            </FormControl>

              <StyledMediaItem 
                collapsedHeight={mqSMOrDown ? "50px" : "70px"}
                hideStylingControls
                styledItem={ wrapMedia(props.question.explanationMediaItem) }
                onUpdateMedia={(newStyledMedia?:styledMediaItem) => {
                  handleUpdateExplanationMedia(newStyledMedia?.item ?? undefined);
                }}
                onRequestDeleteMedia={handleRequestDeleteMedia}
                />

              </StripePane>
              </>
              )}

              {/* POST QUESTION INFO */}
              <Box height="20px" />
              {props.question.responseType !== "AcknowledgementOnly" && (
              <StripePane 
              title="Post Question Resources">

              <GenericCollectionEditor<customButton>
                  itemNameLabel="Resource"
                  canDeleteItems canMoveItems
                  collection={props.question.postQuestionResources}
                  onUpdateCollection={handleOnPostGameResourcesUpdated}
                  onRequestNewItem={function (): customButton {
                    return {
                      action: "DisplayContent",
                      label: "More Info"
                    };
                  } } 
                  onRenderItem={function (item: customButton, cbUpdateItem: (newItem: customButton) => void): ReactNode {
                        return <CustomButtonDef item={item} onUpdateItem={cbUpdateItem} />
                  } }                  
              />

              </StripePane>
                )}


            </Grid>

            {/* EXTRA PREVIEW COLUMN */}
            <Grid xl={2} visibility={{xs: "hidden", xl:"visible"}}>
              <QuestionPreview question={props.question} defaultTavatar={props.defaultTavatar} hideControls />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            startIcon={<TuneIcon />}
            onClick={() => {
              setIsQEADOpen(!isQEADOpen);
            }}>
            Expert settings
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              props.onRequestSave();
            }}>
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>

      <MediaLibraryDialog open={isLibraryDialogOpen} fullScreen={mqXSOrDown} onRequestCancel={mlDialogRequestCancel} onConfirmMedia={mlDialogRequestInsertMedia} />
      <QuestionEdAdvancedDialog
        open={isQEADOpen}
        fullScreen={mqSMOrDown}
        question={props.question}
        onUpdateQuestion={props.onUpdateQuestion}
        onRequestClose={() => {
          setIsQEADOpen(false);
        }}
      />
    </>
  );
}

export default QuestionEditorDialog;

import React, { useState } from "react";
import TourCollectionEditor from "./TourCollectionEditor";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Stack, Typography } from "@mui/material";
import RecentTours from "./RecentTours";
import { PageBox } from "components/chrome/PageBox";
// import PageBox from "components/chrome/PageBox";
import { GridBreak } from "components/chrome/GridBreak";
import PageTitle from "components/chrome/PageTitle";
import TourTickets from "./TourTickets";


import homeBackground from "../../assets/home-background_65q.jpg";

import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import HistoryIcon from '@mui/icons-material/History';
import ConstructionIcon from '@mui/icons-material/Construction';


function Dashboard() {
  return (
    <PageBox>

      <PageTitle>Your Dashboard</PageTitle>

      <Grid container spacing={4} >

      <Grid xs={12} sm={6} >
          <Box sx={{ backgroundColor: "#eeeeee",  p: "16px", borderRadius: "10px", 
          boxShadow: "0 4px 4px rgb(0 0 0 / 0.2)"
        }}>
            <Stack direction="row" alignItems="center" gap="8px">
            <LocalActivityIcon />
            <Typography variant="h4">
              Ready to play</Typography>
            </Stack>
            <TourTickets />
          </Box>
          <Box sx={{ backgroundColor: "#eeeeee", mt: "20px",  p: "16px", borderRadius: "10px", boxShadow: "0 4px 4px rgb(0 0 0 / 0.2)" }}>
          <Stack direction="row" alignItems="center" gap="8px">
            <HistoryIcon />
            <Typography variant="h4">
              Recently Played</Typography>
            </Stack>
            <RecentTours />
          </Box>
        </Grid>

        <Grid xs={12} sm={6} >
          <Box sx={{ backgroundColor: "#eeeeee", p: "16px", borderRadius: "10px", boxShadow: "0 4px 4px rgb(0 0 0 / 0.2)" }}>
          <Stack direction="row" alignItems="center" gap="8px">
            <ConstructionIcon />
            <Typography variant="h4">
            Tours you've created</Typography>
            </Stack>
            
            <TourCollectionEditor />
          </Box>
        </Grid>
      </Grid>
    </PageBox>
  );
}

export default Dashboard;

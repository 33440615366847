import { Dialog, DialogTitle, DialogContent, Typography, Button, Stack, IconButton, DialogActions, Stepper, Step, StepLabel, Box, List, ListItem, LinearProgress, FormControl, TextField } from "@mui/material";
import { composeContentRequest, qcJobDefinition, refineQuestionsJobDefinition } from "data/model/compose/composeContentRequest";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import question from "data/model/question";
import { Fragment, useEffect, useState } from "react";
import { DEFAULT_COMPOSE_OPTIONS, DEFAULT_RQ_JOBREF } from "./compose/blankComposeOptions";
import useAPI from "services/useHunterApi";
import ContentComposerOptions from "./compose/ContentComposerOptions";
import contentComposerOptions from "data/model/compose/contentComposerOptions";
import QCJobDefinition from "./compose/QCJobDefinition";

export interface RefineQuestionsDialogProps {
  open: boolean;
  questions: question[];
  onUpdateQuestions: (refinedQuestions: question[]) => void;
  onRequestClose: () => void;
}

export default function RefineQuestionsDialog(props: RefineQuestionsDialogProps) {
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [refinedQuestions, setRefinedQuestions] = useState<question[]>([]);

  const [request, setRequest] = useState<composeContentRequest<refineQuestionsJobDefinition>>({
    jobDefinition: {
      ...DEFAULT_RQ_JOBREF,
      questions: props.questions
    },
    options: DEFAULT_COMPOSE_OPTIONS
  })

  // WHen the quesitons change, reset
  useEffect(() => {
    resetDialog();
    updateQuestionsFromProps();
  }, [props.questions]);

  const updateQuestionsFromProps = () => {
    const newRequest: composeContentRequest<refineQuestionsJobDefinition> = {
      jobDefinition: {
        ...request.jobDefinition,
        questions: props.questions
      },
      options: request.options
    };
    setRequest(newRequest);
  }

  const resetDialog = () => {
    setActiveStep(0);
    setRefinedQuestions([]);
    // Don't reset the job Def, keep any changes to compose options etc.
  };

  const handleClickNext = () => {
    if (activeStep === 0) {
      refineQuestions();
    } else if (activeStep === 1) {
      mergeRefinedQuestions();
      props.onRequestClose();
    }
  };

  const refineQuestions = () => {

    callAPI<composeContentRequest<refineQuestionsJobDefinition>, question[]>(`/private/api/compose/refinequestions`, "POST", request)
      .then((qs) => {
        if (qs) {
          setRefinedQuestions(qs);
          setActiveStep(1);
        }
      })
      .catch(() => {});
  };

  const mergeRefinedQuestions = () => {
    var output: question[] = [];
    for (var pq of props.questions) {
      var rq = refinedQuestions.find((x) => x.id === pq.id);
      if (rq) {
        output.push({
          ...pq,
          contentFormatted: rq.contentFormatted,
          hintFormatted: rq.hintFormatted,
          cannedResponses: rq.cannedResponses,
          correctResponses: rq.correctResponses,
          explanation: rq.explanation ?? pq.explanation
        });
      }
    }
    props.onUpdateQuestions(output);
  };

  const listOfStrings = (str: string[]) => {
    return (
      <ul>
        {str.map((s, index) => (
          <Fragment key={`s-${index}`}>
            <li>{s}</li>
          </Fragment>
        ))}
      </ul>
    );
  };

  const stepOptions = (
    <Box>
      {isLoading ? (
        <Box>
          <LinearProgress sx={{m: "120px"}} />
        </Box>
      ) : (
        <Box>
      
      

      <FormControl fullWidth>
              <Stack direction="column" gap="20px" flexGrow="1" minHeight="350px" paddingTop="10px">
                <ContentComposerOptions
                  defaultExpanded={false}
                  options={request.options}
                  onUpdateOptions={(newOptions: contentComposerOptions) => {
                    const newRequest: composeContentRequest<refineQuestionsJobDefinition> = {
                      jobDefinition: request.jobDefinition,
                      options: newOptions
                    };
                    setRequest(newRequest);
                  }}
                />

                <QCJobDefinition
                defaultExpanded
                  jobDefinition={request.jobDefinition}
                  onUpdate={(newDefinition: qcJobDefinition) => {
                    const newRequest: composeContentRequest<refineQuestionsJobDefinition> = {
                      jobDefinition: {
                        ...newDefinition,
                        questions: request.jobDefinition.questions
                      },
                      options: request.options
                    };
                    setRequest(newRequest);
                  }}
                />

                <TextField
                fullWidth
                label="Location name"
                helperText="Optional, used to provide context to write question"
                value={request.jobDefinition.locationName ?? ""}
                name="locationName"
                onChange={(e) => { 
                  const newRequest: composeContentRequest<refineQuestionsJobDefinition> = {
                    jobDefinition: {
                      ...request.jobDefinition,
                      locationName: e.target.value
                    },
                    options: request.options
                  };
                  setRequest(newRequest);

                  }}
                />
              </Stack>
            </FormControl>

      </Box>
      )}
    </Box>
  );

  const stepConfirm = (
    <Box>
      <Typography>Results</Typography>

      {refinedQuestions.map((rq, index) => (
        <Grid
          key={`gi-${index}`}
          container
          spacing={4}
          fontSize="14px"
          sx={{
            "--Grid-borderWidth": "1px",
            borderTop: "var(--Grid-borderWidth) solid",
            borderLeft: "var(--Grid-borderWidth) solid",
            borderColor: "divider",
            "& > div": {
              borderRight: "var(--Grid-borderWidth) solid",
              borderBottom: "var(--Grid-borderWidth) solid",
              borderColor: "divider"
            }
          }}>
          <Grid xs={2}>&nbsp;</Grid>
          <Grid xs={5}>Original</Grid>
          <Grid xs={5}>Refined</Grid>

          <Grid xs={2}>Question</Grid>
          <Grid xs={5}>{props.questions.find((pq) => pq.id === rq.id)?.contentFormatted}</Grid>
          <Grid xs={5}>{rq.contentFormatted}</Grid>

          <Grid xs={2}>Choices</Grid>
          <Grid xs={5}>{listOfStrings(props.questions.find((pq) => pq.id === rq.id)?.cannedResponses ?? [])}</Grid>
          <Grid xs={5}>{listOfStrings(rq.cannedResponses)}</Grid>

          <Grid xs={2}>Hint</Grid>
          <Grid xs={5}>{props.questions.find((pq) => pq.id === rq.id)?.hintFormatted}</Grid>
          <Grid xs={5}>{rq.hintFormatted}</Grid>

          {request.jobDefinition.includeExplanations && (
            <>
          <Grid xs={2}>Explanation</Grid>
          <Grid xs={5}>{props.questions.find((pq) => pq.id === rq.id)?.explanation ?? ""}</Grid>
          <Grid xs={5}>{rq.explanation}</Grid>  
          </>
          )}

          {props.questions.find((pq) => pq.id === rq.id)?.responseType === "FreeText" && (
            <>
              <Grid xs={2}>Correct</Grid>
              <Grid xs={5}>{listOfStrings(props.questions.find((pq) => pq.id === rq.id)?.correctResponses ?? [])}</Grid>
              <Grid xs={5}>{listOfStrings(rq.correctResponses)}</Grid>
            </>
          )}
        </Grid>
      ))}
    </Box>
  );

  return (
    <Dialog open={props.open} fullWidth maxWidth="md"
      keepMounted={false}
      onClose={() => {
        props.onRequestClose();
      }}>
      <DialogTitle>Refine Questions</DialogTitle>
      <DialogContent dividers sx={{minHeight: "500px"}}>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step key="a">
            <StepLabel>Options</StepLabel>
          </Step>
          <Step key="b">
            <StepLabel>Confirm results</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && stepOptions}
        {activeStep === 1 && stepConfirm}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onRequestClose()}>
          Cancel
        </Button>

        <Button variant="contained" disabled={isLoading} onClick={() => handleClickNext()}>
          {activeStep === 0 ? "Next" : "Accept"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

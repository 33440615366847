import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import { Typography } from "@mui/material";

interface StarRatingProps {
  score?: number;
}

export default function StarRating(props: StarRatingProps) {
  const stars = [];
  if (props.score) {
    const filledStars = Math.floor(props.score);
    const hasHalfStar = props.score % 1 !== 0;
    const emptyStars = 5 - filledStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < filledStars; i++) {
      stars.push(<StarIcon fontSize="inherit" key={i} />);
    }

    if (hasHalfStar) {
      stars.push(<StarHalfIcon fontSize="inherit" key="half" />);
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(<StarBorderIcon fontSize="inherit" key={`empty-${i}`} />);
    }
  }

  return (
    <Typography variant="h4" textAlign="left" color="#ffff44" sx={{ textShadow: "0 2px 2px rgb(0 0 0 / 0.5)" }}>
      {stars}
    </Typography>
  );
}

import { useEffect, useState } from "react";
import tour from "../../data/model/tour";
import { Box, SxProps } from "@mui/material";
import useAPI from "services/useHunterApi";
import TourShowcase from "./TourShowcase";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

interface ToursBrowserProps {
  sx?: SxProps;
}

export default function ToursBrowser(props: ToursBrowserProps) {
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [tours, setTours] = useState<tour[]>();

  // Fetch a list of eligible tours
  useEffect(() => {    
    callAPI<void, tour[]>(`/api/public/tour`, "GET")
    .then(t => {
      setTours(t);
    })
    .catch(() => {})
  }, []);


  return ( 
    <Box sx={props.sx}>

      <Grid container spacing={2}>
    
      {tours?.map((h) => (
        <Grid key={h.id} xs={12} sm={6} md={4} >
            <TourShowcase tour={h} />
        </Grid>
      ))}
    
      </Grid>
    </Box>

  );
}

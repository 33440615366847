import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import tour, { BLANK_TOUR, tourPublishingStatuses } from "../../data/model/tour";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from "@mui/icons-material/Settings";
import ShareIcon from "@mui/icons-material/Share";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import LockIcon from '@mui/icons-material/Lock';

import { useConfirm } from "material-ui-confirm";

import { Box, Button, Skeleton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

import { SBAlert, SBAlertContext } from "./SBAlertContext";
import StyledMediaItem from "./StyledMediaItem";
import TourQuestionsEditor from "./TourQuestionsEditor";

import useAPI from "services/useHunterApi";
import styledMediaItem from "data/model/styledMediaItem";
import { BLANK_MEDIA_ITEM_STYLE } from "data/model/mediaItemStyle";
import EditableLabel from "components/chrome/EditableLabel";
import TourPublishedSwitchMenu from "./TourPublishedSwitchMenu";
import TourShareDialog from "./TourShareDialog";

import { TTTheme } from "@mui/material/styles/createPalette";
import TourSummaryEditorDialog from "./TourSummaryEditorDIalog";


export default function TourEditor() {
  let { tourId } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [sumEditorOpen, setSumEditorOpen] = useState<boolean>(false);

  // Use a shallow copy of the data for state, then commit on save
  const [tour, setTour] = useState<tour>(BLANK_TOUR);

  const { alert, setAlert } = useContext(SBAlertContext);
  const [isSavingHunt, setIsSavingHunt] = useState(false);
  const [previewStartIndex, setPreviewStartIndex] = useState(0);
  const [shareOpen, setShareOpen] = useState(false);
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();

  const theme = useTheme<TTTheme>();
  const mqSMOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  // Get tour on first load or when ID changes
  useEffect(() => {
    callAPI<void, tour>(`/api/tour/${tourId}`, "GET")
      .then((hs) => {
        setTour(hs);
      })
      .catch((error) => {});
  }, [tourId]);

  // Published checkbox
  const handleChangePublishingStatus = (newStatus: tourPublishingStatuses) => {
    if (tour.publishingStatus === "Unpublished") {
      if (newStatus === "PublicListed" || newStatus === "PublicUnlisted") {
        setShareOpen(true);
      }
    }

    const newTour = { ...tour, publishingStatus: newStatus };
    handleSave(newTour, false);
    setTour(newTour);
  };

  // Name Editable Label
  const handleChangeHuntNameLabel = (newName: string) => {
    const newTour = { ...tour, name: newName };
    handleSave(newTour, false);
    setTour(newTour);
  };

  /******************* 
  // MAIN CANCEL/SAVE EVENTS
  ********************/

  const handleSave = (pendingHunt: tour, closeAfterSaving: boolean) => {
    setIsSavingHunt(true);

    callAPINoResponseBody<tour>(`/api/tour`, "PUT", pendingHunt)
      .then(() => {
        setIsSavingHunt(false);
        setAlert(
          new SBAlert({
            message: "Saved tour successfully.",
            severity: "success",
            autoHideDuration: 2500
          })
        );

        if (closeAfterSaving) {
          navigate(`/app/home/edit`);
        }
      })
      .catch((error) => {
        setIsSavingHunt(false);
      });
  };

  /******************* 
  // MEDIA ITEM
  ********************/
  const handleUpdateMedia = (updatedMediaItem?: styledMediaItem) => {

    var newMediaItems = Object.assign([], tour.mediaItems);

    // Remove existing first item
    if (newMediaItems.length > 0) {
      newMediaItems.splice(0, 1);
    }

    // Do we have a new media item?
    if (updatedMediaItem !== undefined) {
      newMediaItems.splice(0, 0, updatedMediaItem.item);
    }

    const newTour: tour =
      {
          ...tour,
          mediaItems: newMediaItems
      };
      
    handleSave(newTour, false);
    setTour(newTour);
  };

  const handleRequestDeleteMedia = (): Promise<boolean> => {
    return confirm({
      title: "Clear this image?",
      description: "This will clear this image.  The original image will still remain in your media library.\r\n\r\nAre you sure?",
      confirmationText: "Delete"
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        /* User cancelled the deletion */
        return false;
      });
  };

  /*
  // At the moment, a distant grandchild (explorerMap) tells us all anbout this
  // It might be better to handle this on the server at publish-time instead
  */

  const handleUpdateTourRoutePlan = (newDuration?:number, newDistance?:number) => {
    const newTour:tour = { ...tour, 
      distanceMetres: newDistance ? Math.floor(newDistance) : undefined,
      durationMins: newDuration ? Math.floor(newDuration * 0.0166667) : undefined,
    };
    handleSave(newTour, false);
    setTour(newTour);
  };


  if (tour?.isBlank) {
    return (<Box sx={{m: {xs: "12px", sm: "16px"}, height: "500px"}}>
        <Stack direction="column" gap="10px" sx={{width: "100%"}}>
  
          <Skeleton variant="rectangular" animation="wave" width="30px" height="30px" sx={{alignSelf:"end"}}></Skeleton>
          <Skeleton variant="rectangular" animation="wave" width="100%" height="100px"></Skeleton>

          <Skeleton variant="rectangular" animation="wave" width="200px" height="40px" sx={{alignSelf:"start"}}></Skeleton>
          <Skeleton variant="rectangular" animation="wave" width="150px" height="40px" sx={{alignSelf:"start"}}></Skeleton>

          <Skeleton variant="rectangular" animation="wave" width="100%" height="230px" sx={{mt: "20px"}}></Skeleton>

        </Stack>
    </Box>
    )
    ;
  } else {
    return (
        <>
        <TourSummaryEditorDialog
          open={sumEditorOpen}
          fullScreen={mqSMOrDown}
          tour={tour}
          onUpdateTour={(h: tour) => {
            setTour(h);
          }}
          onRequestClose={() => {
            setSumEditorOpen(false);
            handleSave(tour, false);
          }}
        />

      
      <Box sx={{ m: {xs: "12px", sm: "16px"}}}>

        {/* TOP PANE - HUNT SUMMARY */}
        <Grid container columnSpacing={{xs: 1, sm: 3}} rowSpacing={1.5} sx={{ mb: "20px"}}>
        
        {/* ROW - BACK BUTTON */}
        <Grid xs={12}>
        <Stack direction="row" alignItems="center" spacing="10px">
          <Button
            size="small"
            aria-label="close"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              handleSave(tour, true);
            }}>
              Back
          </Button>
          
          </Stack>
        </Grid>

        {/* COLUMN 1 - IMAGE */}
        <Grid xs={12} sm={2}>

            <StyledMediaItem
              collapsedHeight="100px"
              expandedHeight="100px"
              hideStylingControls
              styledItem={
                tour?.mediaItems && tour?.mediaItems.length > 0
                  ? {
                      item: tour.mediaItems[0],
                      style: BLANK_MEDIA_ITEM_STYLE
                    }
                  : undefined
              }
              onUpdateMedia={handleUpdateMedia}
              onRequestDeleteMedia={handleRequestDeleteMedia}
              mediaLibraryQuery={{slug: {exclude: "sys-"}}}
            />
        </Grid>

        {/* COLUMN 2 - TITLE AND PUBLISHING STACK.  */}
        <Grid xs={12} sm={10} maxWidth={{xs: "390px", sm: "100%"}} >
        {/* MaxWidth setting above is a bit of a fudge to prevent the editable label pushing things out if it gets overly long */}
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" overflow="hidden">
          <EditableLabel textVariant="h3" editTextSize={24} value={tour.name} placeholder="Type a Name" onValueUpdated={handleChangeHuntNameLabel} />
          <Stack direction="row" alignItems="center" gap="8px">

            {tour.isLocked &&  <Tooltip title="Tour is locked, unlock in settings"><LockIcon color="secondary" /></Tooltip> }

            <TourPublishedSwitchMenu value={tour.publishingStatus} onUpdated={handleChangePublishingStatus} />
            
            <Stack direction={{xs: "column", sm: "row"}} gap="5px">
            <Button
              sx={{ height: "30px" }}
              variant="outlined"
              startIcon={<PlayCircleOutlineIcon />}
              href={`/play/tour/${tourId}/newgame/true/${previewStartIndex}`} 
              target="_preview" >
              Preview
            </Button>
            <Button
              sx={{ height: "30px" }}
              variant="outlined"
              disabled={!(tour.publishingStatus === "PublicListed" || tour.publishingStatus === "PublicUnlisted")}
              startIcon={<ShareIcon />}
              onClick={() => {
                setShareOpen(!shareOpen);
              }}>
              Share
            </Button>
            </Stack>
            <Button
            sx={{marginLeft: "auto"}}
              startIcon={<SettingsIcon />}
              onClick={() => {
                setSumEditorOpen(!sumEditorOpen);
              }}>
              Settings
            </Button>

          </Stack>
        </Stack>
        </Grid>


        {/* END OF ROW */}
        </Grid>


        {/* BOTTOM PANE - QUESTIONS SUMMARY */}
        <TourQuestionsEditor
         tourId={tour.id}
         defaultTavatar={tour.defaultTavatarMediaItem}
         onTourRouteUpdated={handleUpdateTourRoutePlan}
         onSelectedQuestionIndexUpdated={(newIndex:number) => { setPreviewStartIndex(newIndex) }} // we keep a shadow copy for starting previews at the selected question index
         />


      </Box>
        <TourShareDialog tour={tour} open={shareOpen} onRequestClose={() => setShareOpen(false)} />
      </>
    );
  }
}

import { createControlComponent } from '@react-leaflet/core';
import * as L from 'leaflet';

import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';
 
interface LocateControlProps extends L.ControlOptions {
  onLocationError?: ((event: L.ErrorEvent, control:any) => void) | undefined;
  setView?: boolean | string | undefined;
  clickBehaviour?: any;
  highAccuracy?: boolean;
}

export interface ExtendedLocateOptions extends L.Control.LocateOptions {
  getLocationBounds?: (e: L.LocationEvent) => L.LatLngBounds;
}

function createLocateInstance(props: LocateControlProps) {

  var opts:ExtendedLocateOptions = {
    ...props,
    showPopup: false,
    setView: props.setView,
    clickBehavior: props.clickBehaviour,
    locateOptions: {
      enableHighAccuracy: props.highAccuracy
    },
    onLocationError: (event, control) => { if (props.onLocationError) { props.onLocationError(event, control) }}
  }

  const { Locate } = L.Control;
  const instance = new Locate(opts);
  return instance;
}

 export const LocateControl = createControlComponent(createLocateInstance);

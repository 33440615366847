import { nsJobDefinition, qcJobDefinition, refineQuestionsJobDefinition } from "data/model/compose/composeContentRequest";
import contentComposerOptions from "data/model/compose/contentComposerOptions";
import { BLANK_SCENARIO } from "data/model/compose/scenario";

export const DEFAULT_COMPOSE_OPTIONS: contentComposerOptions = {
  style: {
    tone: "Casual",
    humour: "FunnyUniversal",
    audience: "Universal"
  }
};

export const DEFAULT_QC_JOBDEF: qcJobDefinition = {
  difficulty: "Standard",
  responseType: "MultiChoice",
  includeExplanations: true
};

export const DEFAULT_NS_JOBDEF: nsJobDefinition = {
  scenarioId: undefined,
  placeName: "London",
  narrativePointQuantity: "One"
};

export const DEFAULT_RQ_JOBREF: refineQuestionsJobDefinition = {
  questions: [],
  difficulty: "Standard",
  responseType: "MultiChoice",
  includeExplanations: false
};
import "@aws-amplify/ui-react/styles.css";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import React, { PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router";
import { ttAuthComponents, ttAuthFormFields, ttAuthservices } from "./AuthenticatorTheming";


import homeBackground from "../../assets/home-background_65q.jpg";
import { Box } from "@mui/material";

export const KEY_POST_LOGIN_URL = "postLoginURL";

export default function RequireAuth(props: PropsWithChildren) {
  const location = useLocation();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  // Whenever the location changes and we're potentially signing in to access a new page, save the URL in sessionStorage
  // so that it can be accessed after the evil OAuth federati have redirected us away and lost our state.  
  useEffect(() => {
    // console.log(`RequireAuth UE triggered; auth status '${authStatus}': path is '${location.pathname}'`);
    if (authStatus === 'unauthenticated') {
      // console.log(`..RequireAuth UE: Unauthenticated ('${authStatus}'), setting key.`);
      window.sessionStorage.setItem(KEY_POST_LOGIN_URL, location.pathname);
    } 
  }, [authStatus]);

  
  // console.log(`RequireAuth RENDER: path:'${location.pathname}', authStatus:'${authStatus}'`);
  switch (authStatus) {
    case "configuring":
      return (<></>)

    case "authenticated":
      return (
      <>
      {props.children}
      </>
      );

    default:
      case "unauthenticated":
        return (
          <Box sx={{
          backgroundImage: `url(${homeBackground})`,
          backgroundPosition: 'top'
          }}>
          <Authenticator
          formFields={ttAuthFormFields}
          components={ttAuthComponents}
          services={ttAuthservices}
          socialProviders={["google", "apple"]}
          />
          </Box>
        )
  
  }
  
}

import { useContext, useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";

import ImageIcon from "@mui/icons-material/Image";
import NoBackpackIcon from '@mui/icons-material/NoBackpack';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Chip, Skeleton, Stack, Typography } from "@mui/material";
import useAPI from "services/useHunterApi";
import NoDataPane from "components/chrome/NoDataPane";
import tour from "data/model/tour";
import TourListItem from "./TourListItem";

export default function RecentTours() {
  const [recentTours, setRecentTours] = useState<tour[]>();
  const [inplayTours, setInPlayTours] = useState<tour[]>();

  const { callAPI, isLoading } = useAPI();

  // On startup, Retrieve games belonging to logged in user only
  useEffect(() => {
    getCurrentTours();
    getRecentTours();
  }, []);

  const getCurrentTours = () => {
    callAPI<null, tour[]>(`/api/tour/currentlyplaying/me`, "GET")
      .then((data) => {
        setInPlayTours(data);
      })
      .catch(() => {});
  }

  const getRecentTours = () => {
    callAPI<null, tour[]>(`/api/tour/recentlycompleted/me`, "GET")
      .then((data) => {
        setRecentTours(data);
      })
      .catch(() => {});
  }

  const listItemForTour = (t:tour, isInPlay:boolean) => {
    return (
      <TourListItem key={`tli-${t.id}`} tour={t}
      href={`/app/tours/${t.id}`}
      chipLabel={isInPlay ? "Currently playing" : undefined}>
      <Button href={`/app/tours/${t.id}`} size="small" variant="outlined">
        View
      </Button>
    </TourListItem>
    )
  }

  const generateSkeletonListItems = (count: number) => {
    const items = [];
    for (let i = 0; i < count; i++) {
      items.push(
        <ListItem key={i} disableGutters disablePadding>
          <Skeleton variant="rectangular" animation="wave" width="100%" height={40}></Skeleton>
        </ListItem>
      );
    }
    return items;
  };

  return isLoading ? (
    <Stack direction="column" gap="10px" width="100%" sx={{ p: "25px" }}>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={36}></Skeleton>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={36}></Skeleton>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={36}></Skeleton>
    </Stack>
  ) : (
      (recentTours && recentTours!.length > 0) ||
      (inplayTours && inplayTours!.length > 0) )
    ? (
    <>
      <List sx={{ width: "100%" }}>
        {isLoading && generateSkeletonListItems(3)}
        {inplayTours?.map((t) => (
          listItemForTour(t, true)
        ))}
        {recentTours?.map((t) => (
          listItemForTour(t, false)
        ))}
      </List>
      <Button fullWidth href="/app/tours" variant="contained" endIcon={<TravelExploreIcon />}>
        Discover tours
      </Button>
    </>
  ) : (
    <>
      <NoDataPane icon={<NoBackpackIcon />} sx={{height: "200px"}} title="No tours" subtitle="Find challenges in your area and get hunting!" />
      <Button fullWidth href="/app/tours" variant="contained" endIcon={<TravelExploreIcon />}>
        Discover tours
      </Button>
    </>
  );
}



import { IconButton, Stack, Typography } from "@mui/material";
import markerSource from "data/model/geo/markerSource";
import { DragEndEvent, LatLngExpression, LatLngLiteral } from "leaflet";

import { Fragment, useEffect, useRef } from "react";
import { Marker, Popup } from "react-leaflet";
import "react-leaflet-fullscreen/styles.css";

import { iconForMarkerSource } from "./MarkersDisplayIcons";
import LaunchIcon from '@mui/icons-material/Launch';

interface MarkersDisplayProps {
  data?: markerSource[];
  draggable?: boolean;
  onSelectedMarkers?: (markers: markerSource[]) => void;
  onMovedMarker?: (marker: markerSource, newPosition: LatLngLiteral) => void;
}

export default function MarkersDisplay(props: MarkersDisplayProps) {


  // console.log(`MD: Rendering with ${props.data?.length} markers.. `);

  return (
    <>
      {props.data ? (
        props.data.map((ms, index) => (
          <Fragment key={ms.id}>
            <Marker 
              icon={iconForMarkerSource(ms, ms.iconLabel)}
              draggable={props.draggable}
              eventHandlers={{
                click: (e: any) => {
                  props.onSelectedMarkers!([ms]);
                },
                popupclose: (e) => {
                  props.onSelectedMarkers!([]);
                },
                dragend(e: DragEndEvent) {
                  if (props.onMovedMarker) {
                    props.onMovedMarker(ms, e.target.getLatLng());
                  }
                }
              }}
              position={ms.location ?? undefined}>
              <Popup autoPan={false}>
                <Stack direction="column" gap="5px" width="100%">

                  <Stack direction="row" gap="10px" alignItems="center">
                    <Typography variant="h6" sx={{ m: "0px" }}>
                      {ms?.name?.trim() ?? ""}
                    </Typography>
                    <IconButton 
                    
                    size="small"
                    color="secondary"
                    disabled={!ms.webURL}
                    onClick={() => {
                        window.open(ms.webURL, "_blank");
                    }}
                    >
                      <LaunchIcon fontSize="inherit" />
                    </IconButton>
                  </Stack>

                  {ms.description && (
                    <Typography variant="caption" sx={{ m: "0px!important" }}>
                      {ms.description.trim()}
                    </Typography>
                  )}
                </Stack>
              </Popup>
            </Marker>
          </Fragment>
        ))
      ) : (
        <></>
      )}
    </>
  );
}

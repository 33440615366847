import { useState } from "react";
import { tourPublishingStatuses } from "../../data/model/tour";


import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Switch} from "@mui/material";


export interface TourPublishedSwitchMenuProps {
  value: tourPublishingStatuses;
  onUpdated: (newMode:tourPublishingStatuses) => void;
}

export default function TourPublishedSwitchMenu(props: TourPublishedSwitchMenuProps) {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSwitchClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };


  return (
    <>
      <FormControl>
          <FormGroup>
            <FormControlLabel
              sx={{ color: "#8888dd" }}
              control={
                <Switch
                  checked={
                    props.value === "PublicListed" ||
                    props.value === "PublicUnlisted"
                  }
                  onClick={handleSwitchClick}
                />
              }
              label={
                props.value === "PublicListed" ? "Published (listed)" : props.value === "PublicUnlisted" ? "Published (unlisted)" : "Not Published"
              }
            />
          </FormGroup>

          <Menu 
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            > 
            
            <Box sx={{padding: "12px", width: "350px"}}>
          <FormControl fullWidth sx={{ marginBottom: "20px" }}>
            <InputLabel id="pubstatus-label">Publish tour</InputLabel>
            <Select
              id="publishignStatus"
              value={props.value}
              labelId="pubstatus-label"
              label="Publish hunt"
              fullWidth
              onChange={(e) => {
                props.onUpdated(e.target.value as tourPublishingStatuses);
                setAnchorEl(null);
              }}>
              <MenuItem value={"Unpublished"}>Not published</MenuItem>
              <MenuItem value={"PublicUnlisted"}>Public but only for people I share the link with</MenuItem>
              <MenuItem value={"PublicListed"}>Public and listed for everyone to play</MenuItem>
            </Select>
            {/* <Typography variant="body2">{helpForPlayMode(props.value)}</Typography> */}
            </FormControl>
            </Box>
      </Menu>
        
      </FormControl>


    </>
  );
}


import { PropsWithChildren, useEffect, useState } from "react";
import "./BorderedSection.css";
import { Box, FormControl, IconButton, Stack, SvgIcon, TextField, Typography, TypographyPropsVariantOverrides } from "@mui/material";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Variant } from "@mui/material/styles/createTypography";

interface BorderedSectionProps {
  value: string;
  placeholder?: string;
  textVariant?: Variant | "inherit";
  editTextSize?: number;
  onValueUpdated: (newValue: string) => void;
}

export default function EditableLabel(props: PropsWithChildren<BorderedSectionProps>) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingValue, setEditingValue] = useState<string>(props.value);

  const edTextSize = props.editTextSize ?? 30;


  // When we start editing, copy the value into the label
  useEffect(() => {
    if (isEditing) {
      // Copy in value
      setEditingValue(props.value);
    } else {
      // Raise change event
      props.onValueUpdated(editingValue);
      // Nuke value
      // setEditingValue("");
    }
  }, [isEditing]);

  return (
    <Box sx={{ width: "100%", height: "60px" }}>
      {isEditing ? (
        <TextField
          fullWidth
          autoFocus
          variant="standard"
          inputProps={{ style: { fontSize: edTextSize } }}
          value={editingValue}
          placeholder={props.placeholder}
          onChange={(e) => {
            setEditingValue(e.target.value);
          }}
          onBlur={() => {
            setIsEditing(false);
          }}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              setIsEditing(false);
            }
          }}
        />
      ) : (
        <Stack direction="row" alignItems="center" sx={{ height: "100%" }}>
          <Typography
            noWrap
            variant={props.textVariant}
            sx={
              props.value.length < 1
                ? {
                    color: "#999999"
                  }
                : {}
            }
            onClick={() => {
              setIsEditing(true);
            }}>
            {props.value.length > 0 ? props.value : props.placeholder ?? "..."}
          </Typography>
          <IconButton
            color="primary"
            onClick={() => {
              setIsEditing(true);
            }}>
            <BorderColorIcon />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
}

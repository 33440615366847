import React, { Fragment } from "react";


import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";

import RoomIcon from "@mui/icons-material/Room";
import ImageIcon from "@mui/icons-material/Image";
import BlockIcon from "@mui/icons-material/Block";
import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

import question from "../../data/model/question";

import { Box, Button, Chip, Divider, Hidden, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { unformatAndTruncateRichContent } from "utils/utils-strings";
import TourQuestionsEditorMenu from "./TourQuestionsEditorMenu";
import { TTTheme } from "@mui/material/styles/createPalette";
import { DraggableProvided } from "react-beautiful-dnd";

interface QuestionListItemProps {
  question: question;
  index: number;
  selected?: boolean;
  provided:DraggableProvided;
  onClickQuestion: (index: number) => void;
  onClickQuestionButtonEdit: (index: number) => void;
  onClickQuestionButtonMove: (index: number, delta: number) => void;
  onClickQuestionButtonDelete: (index: number) => void;
  onClickQuestionButtonDuplicate: (index: number) => void;
  disableMoveDownButton?: boolean;
}

const MAX_QUESTION_DISPLAY_CHARS: number = 60;

export default function QuestionListItem(props: QuestionListItemProps) {
  const theme = useTheme<TTTheme>();
  const mqXSOrDown = useMediaQuery(theme.breakpoints.down("sm"));


  const iconForQuestion = (q: question) => {
    const iconStyle = {
      fontSize: '16px', // Adjust the font size as needed
    };

    return q.responseType === "AcknowledgementOnly" ? <InfoIcon sx={iconStyle} /> :
      q.responseType === "FreeText" ? <QuestionAnswerIcon sx={iconStyle} /> :
      q.responseType === "MultiChoice" ? <SpeakerNotesIcon sx={iconStyle} /> :
      <BlockIcon sx={iconStyle} />;
  };

  return (
    <Fragment >
      <ListItem 
      sx={{m: "0px", p:"0px"}}
      disableGutters disablePadding key={`qu-${props.index}`}
      ref={props.provided.innerRef}
      {...props.provided.draggableProps}
      {...props.provided.dragHandleProps} >
        <Box bgcolor={props.selected ? "red" : "transparent"} m="0px" p="0px" height="100%" >&nbsp;</Box>
        <ListItemButton disableGutters onClick={(event) => props.onClickQuestion(props.index)}
        selected={props.selected}
        >
          <ListItemAvatar sx={{  pl: { xs: "0px", sm: "12px" }, minWidth: { xs: "0px", sm: "56px" }, width: { xs: "5px", sm: "56px" }, visibility: { xs: "hidden", sm: "visible" } }}>
            <Avatar  sx={{
                bgcolor: "secondary.light",
                width: "28px", // Set the desired width
                height: "28px", // Set the desired height
              }}            
            >{iconForQuestion(props.question)}</Avatar>
          </ListItemAvatar>
          <Hidden smUp>{iconForQuestion(props.question)} &nbsp;</Hidden>
          <Stack direction="column" gap="1px" flexGrow={1}>
            <Typography variant="body2">{/* {`${props.index + 1} : ${q.responseType === "AcknowledgementOnly" ? "Instruction" : "Question"}`} */}</Typography>
            <Typography
              variant="caption"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1
              }}>
              <>
                {`${props.index + 1} . `}
                {unformatAndTruncateRichContent(props.question.contentFormatted, MAX_QUESTION_DISPLAY_CHARS)}
              </>
            </Typography>
            <Box m="0px" p="0px">
            {props.question.tags && (
                  <>
                    {props.question.tags.map((t, index) => (
                      <Chip key={`ch-${props.index}`} sx={{ m: "0px", p:"0px", backgroundColor: theme.palette.secondary.light, color: "#ffffff" }} size="small" label={t} />
                    ))}
                  </>
                )}
            </Box>
          </Stack>

          <Hidden smDown>
            <Stack direction="row" gap="4px">
              <Tooltip title={props.question.contentStyledMedia ? "Question has an image" : undefined}>
                <ImageIcon color="info" sx={{ opacity: props.question.contentStyledMedia ? 0.8 : 0.12 }} />
              </Tooltip>
              <Tooltip title={props.question.waypoint ? "Question has a waypoint" : undefined}>
                <RoomIcon color="info" sx={{ opacity: props.question.waypoint ? 0.8 : 0.12 }} />
              </Tooltip>
            </Stack>
          </Hidden>
        </ListItemButton>

        <Button variant="outlined" size="small" color="secondary" sx={{ml: "10px"}}
        onClick={() => props.onClickQuestionButtonEdit(props.index)}>
          Edit
        </Button>

        <TourQuestionsEditorMenu
          disableMoveUp={props.index === 0}
          disableMoveDown={props.disableMoveDownButton}
          onClickQuestionButtonMove={(delta: number) => props.onClickQuestionButtonMove(props.index, delta)}
          onClickQuestionButtonDelete={() => {
            props.onClickQuestionButtonDelete(props.index);
          }}
          onClickQuestionButtonDuplicate={() => {
            props.onClickQuestionButtonDuplicate(props.index);
          }}
        />
      </ListItem>
      <Divider component="li" />
    </Fragment>
  );

  
}

import { ChangeEvent } from "react";
import { FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Stack, Switch } from "@mui/material";

import contentComposerOptions from "data/model/compose/contentComposerOptions";
import composeStyles from "data/model/compose/tourStyleTypes";


interface ComposerStylesProps {
  styles: composeStyles;
  onUpdateStyles: (newStyles: composeStyles) => void;
}

export default function ComposerStyles(props: ComposerStylesProps) {

  const handleChangeFieldNameAndValue = (name: string, value: any) => {
    const newStyles: composeStyles = {
      ...props.styles,
      [name]: value
    };

    props.onUpdateStyles(newStyles);
  };

  return props.styles ? (
    <FormControl fullWidth>
      <Stack direction="column" gap="4px" flexGrow="1" minHeight="200px" paddingTop="10px">
        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
          <InputLabel id="lab-humour">Humour</InputLabel>
          <Select value={props.styles.humour} labelId="lab-humour" label="Humour" name="humour" fullWidth onChange={(e) => handleChangeFieldNameAndValue(e.target.name, e.target.value)}>
            <MenuItem value={"NoHumour"}>Straight-laced</MenuItem>
            <MenuItem value={"FunnyUniversal"}>Funny (all ages)</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
          <InputLabel id="lab-tone">Tone</InputLabel>
          <Select value={props.styles.tone} labelId="lab-tone" label="Tone" name="tone" fullWidth onChange={(e) => handleChangeFieldNameAndValue(e.target.name, e.target.value)}>
            <MenuItem value={"Casual"}>Casual</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
          <InputLabel id="lab-audience">Audience</InputLabel>
          <Select value={props.styles.audience} labelId="lab-audience"
           label="Audience" name="audience" fullWidth onChange={(e) => handleChangeFieldNameAndValue(e.target.name, e.target.value)}>
            <MenuItem value={"Universal"}>Universal (everyone)</MenuItem>
            <MenuItem value={"YoungChildren"}>Young children</MenuItem>
            <MenuItem value={"Children"}>Children & Teens</MenuItem>
            <MenuItem value={"Adults"}>Adults</MenuItem>
            <MenuItem value={"Educated"}>Intellectuals</MenuItem>
            <MenuItem value={"IntellectualMasochists"}>Nerds and Brainiacs</MenuItem>
          </Select>
        </FormControl>

      </Stack>
    </FormControl>
  ) : (
    <></>
  );
}

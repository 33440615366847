import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import "./AccountProfile.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, Link, Stack, TextField, Typography } from "@mui/material";
import { PageBox } from "components/chrome/PageBox";
import PageTitle from "components/chrome/PageTitle";
import RequireGroups from "components/auth/RequireGroups";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Auth } from "aws-amplify";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ProgressButton from "components/controls/ProgressButton";
import { SBAlert, SBAlertContext } from "components/edit/SBAlertContext";
import ChannelEditor from "./ChannelEditor";

interface accountAttributes {
  preferred_username?: string;
  email?: string;
  email_verified?: boolean;
  phone_verified?: boolean;
  phone_number?: string;
  given_name?: string;
  family_name?: string;
}

// See https://docs.amplify.aws/react/prev/build-a-backend/auth/
export default function AccountProfile() {
  const { route, user } = useAuthenticator((context) => [context.route, context.user]);
  const { alert, setAlert } = useContext(SBAlertContext);

  const [accountAtts, setAccountAtts] = useState<accountAttributes>({});
  const [isPushing, setIsPushing] = useState(false);
  const [isPulling, setIsPulling] = useState(false);
  const [didUpdateDetails, setDidUpdateDetails] = useState(false);

  // Change email address
  const [verifyCode, setVerifyCode] = useState<string>("");

  // Used for admin/internal fields below
  var jToken:string|undefined;
  var advancedPayload:any|undefined;
  try {
  jToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
  advancedPayload = user.getSignInUserSession()?.getIdToken()?.payload;
  }
  catch {}

  // On first load get attributes from AWS
  useEffect(() => {
    pullAttributes();
  }, []);

  const pullAttributes = async () => {
    setIsPulling(true);

    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true
    });
    const { attributes } = user;

    // let attributes = user.getSignInUserSession()?.getIdToken()?.payload;
    setIsPulling(false);

    if (attributes) {
      // Copy all values from attributes over to our new 'account atributes' object
      // var atts_a: any = {};
      // Object.keys(payload).forEach(k => {
      //   atts_a[k] = payload![k];
      // });

      var atts_a:accountAttributes = {
        preferred_username: attributes["preferred_username"],
        email: attributes["email"],
        email_verified: attributes["email_verified"],
        phone_verified: attributes["phone_verified"],
        phone_number: attributes["phone_number"] ?? null,
        given_name: attributes["given_name"],
        family_name: attributes["family_name"]
      }

      setAccountAtts(atts_a); // casts into object:accountAttributes which should strip away additional keys
    }
  };

  const alertGood = (msg:string) => {
    setAlert(
      new SBAlert({
        message: msg,
        severity: "success",
        autoHideDuration: 1500
      }));
  }
  const alertBad = (msg:string) => {
    setAlert(
      new SBAlert({
        message: msg,
        severity: "error",
        autoHideDuration: 3200
      }));
  }

  const pushChanges = async () => {
    
      setIsPushing(true);
      const user = await Auth.currentAuthenticatedUser();

      // Remove null values from accountAtts - Cognito doesn't like them
      const filteredAtts =  Object.fromEntries(Object.entries(accountAtts).filter(([_, value]) => value !== null));

    try {
      const result = await Auth.updateUserAttributes(user, filteredAtts);
      console.log(result);
      alertGood("Saved changes.");
      setIsPushing(false);

      // Some events, e.g. update email/phone require a second step of verification code.
      // To detect if this is required would involve refreshing the page.
      // As a workaround, we simply set this flag to display a warning message for now.
      setDidUpdateDetails(true);
      // Dont refresh the page because the old email address will be displayed
    } 
    catch (err) {
      setIsPushing(false);
      console.log(err);      
      alertBad("Couldn't save changes: " + err);
    }
  };

  const handleChangeAAText = (e: ChangeEvent<HTMLInputElement>) => {
    setAccountAtts({
      ...accountAtts,
      [e.target.name]: e.target.value
    });
  };


  // Update email or phone.  atributename is 'email' or 'phone_number'
  const verifyAttribute = async (attributeName:string, code:string) => {  
    if (verifyCode) {

      try {
        const result = await Auth.verifyCurrentUserAttributeSubmit(attributeName, code)
        console.log(result);
        alertGood("Updated email");
        // re-sync
        // pullAttributes();
        setAccountAtts({
          ...accountAtts,
          email_verified: true
        })
      }
      catch (err) {
        alertBad("Couldn't update this: " + err);
      }
    }
  }


  return (
    <PageBox>
      <PageTitle>Profile</PageTitle>

      <Typography variant="h5" sx={{ mt: "20px" }}>
        Account Details
      </Typography>

      <FormControl disabled={isPulling || isPushing}>
        <Grid container spacing={2} mt="20px">
          <Grid xs={12} sm={6}>
            <TextField fullWidth label="Email" value={accountAtts.email ?? ""} name="email" onChange={handleChangeAAText} />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField fullWidth label="Phone number" value={accountAtts.phone_number ?? ""} name="phone_number" onChange={handleChangeAAText} />
          </Grid>
          <Grid xs={6} sm={4}>
            <TextField fullWidth label="First name" value={accountAtts.given_name ?? ""} name="given_name" onChange={handleChangeAAText} />
          </Grid>
          <Grid xs={6} sm={4}>
            <TextField fullWidth label="Last name" value={accountAtts.family_name ?? ""} name="family_name" onChange={handleChangeAAText} />
          </Grid>
          <Grid xs={12} sm={4}>
            <TextField fullWidth label="Handle / Nickname" value={accountAtts.preferred_username ?? ""} name="preferred_username" onChange={handleChangeAAText} />
          </Grid>
          <Grid xs={12}>
            <ProgressButton variant="contained" waiting={isPulling || isPushing} color="primary" onClick={() => pushChanges()} title="Save changes" />
          </Grid>
        </Grid>
      </FormControl>


      {didUpdateDetails && (
        <Box borderRadius="5px" mt="8px">
            <Typography color="red">
              ⚠️ If you updated your email or phone, you should refresh this page and check your inbox/SMS for a validation code.
            </Typography>
        </Box>
      )}


      {
        (
      (accountAtts.phone_verified !== undefined && accountAtts.phone_verified === false)  ||
      (accountAtts.email_verified !== undefined && accountAtts.email_verified === false) 
      ) && (
      <Accordion defaultExpanded={false} sx={{mt:"20px"}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Verify new email address</AccordionSummary>
      <AccordionDetails>
      <Typography variant="body2">
        If you asked to change your email or phone, we have sent a verification code to your new email.  Enter it below to complete the change of email address.
      </Typography>
      <FormControl disabled={isPulling || isPushing}>
        <Grid container spacing={2} mt="10px">
          <Grid xs={12} sm={12}>
            <Stack direction="row" gap="10px">
            <TextField fullWidth label="Verification code" value={verifyCode ?? ""} name="given_name" onChange={(e) => setVerifyCode(e.target.value)} />
            <ProgressButton variant="contained" disabled={!verifyCode} waiting={isPulling || isPushing} color="secondary" onClick={() => verifyAttribute('email', verifyCode)} title="Confirm change" />
            </Stack>
          </Grid>
        </Grid>
      </FormControl>
      </AccordionDetails>
        </Accordion>
      )}


      <Accordion sx={{mt:"20px"}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Publishing Channel</AccordionSummary>
      <AccordionDetails>
      <Typography variant="body2">
        If you publish your own tours, these details control what is displayed publically about you.
      </Typography>
      <ChannelEditor />
      </AccordionDetails>
        </Accordion>

      <RequireGroups names={["SuperAdmins"]}>
        <Accordion sx={{mt:"20px"}}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Internal Properties</AccordionSummary>
          <AccordionDetails>
            {user && advancedPayload && (
              <>
            <div>User ID: {user?.username}</div>
            <div>Sub: {advancedPayload!["sub"] ?? ""}</div>
            <div>Phone: {user.attributes?.phone_number}</div>
            <div>Groups: {advancedPayload!["cognito:groups"] ?? ""}</div>
            </>
            )}
            <Box className="token-container" mt="20px">
              <TextField fullWidth label="token" multiline rows={1} value={jToken}></TextField>
            </Box>

            <Box className="payload-container" mt="20px">
              <TextField fullWidth label="Payload" multiline rows={10} value={JSON.stringify(advancedPayload)}></TextField>
            </Box>
          </AccordionDetails>
        </Accordion>
      </RequireGroups>

    </PageBox>
  );
}

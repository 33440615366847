import React from 'react';

export const unformatAndTruncateRichContent = (input:string, maxChars:number):string => {

    input = truncateContent(input, maxChars);

    // Replace anything inside rounded brackets
    input = input.replace(/((\([^()]*\)))/g, "");

    // Replace anything inside angled brackets
    input = input.replace(/((<[^()]*>))/g, "");

    // Replace any of these characters
    input = input.replace(/((#|\*|\[|\]|\(|\)|<|>|!|\||\^))/g, "");

    // Replace these characters with a single space
    input = input.replace(/((\r|\n|\t))/g, " ");

    // Remove double spaces
    while (input.includes("  ")) {
      input = input.replace("  "," ");
    }


    return input;
  }

  export const truncateContent = (input:string, maxChars:number):string => {

    if (input.length > maxChars) {
      input = input.substring(0, maxChars) + "…";
    }

    return input;
  }




  export const isValidEmailAddress = (email:string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  export const renderParagraphs = (text: string): React.ReactNode[] => {
    const lines = text.split(/\r\n|\r|\n/).filter((line) => line.trim() !== ''); // Split at \r\n, \r, or \n and filter out empty lines
    return lines.map((line, index) => (<p key={index}>{line}</p>) as React.ReactNode);
  };
import { PropsWithChildren } from "react"
import "./StripePane.css"
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, SvgIcon, SxProps, Theme, Typography, alpha, useTheme } from "@mui/material";
import { TTTheme } from "@mui/material/styles/createPalette";

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface StripePaneProps {
    title?: string;
    stripeColor?: any;
    sx?: SxProps;
    defaultExpanded?: boolean;
}

// const CustomExpandIcon = () => {
//   return (
//     <Box
//       sx={{
//         '.Mui-expanded & > .collapsIconWrapper': {
//           display: 'none',
//         },
//         '.expandIconWrapper': {
//           display: 'none',
//         },
//         '.Mui-expanded & > .expandIconWrapper': {
//           display: 'block',
//         },
//       }}
//     >
//       <div className="expandIconWrapper">
//         <ExpandMoreIcon sx={{color: "#ffffff"}} />
//       </div>
//       <div className="collapsIconWrapper">
//         <AddIcon sx={{color: "#ffffff"}} />
//       </div>
//     </Box>
//   )
// }

export default function StripePane(props: PropsWithChildren<StripePaneProps>) {
    const theme = useTheme<TTTheme>();
    const theColor = props.stripeColor ?? theme.palette.secondary.light;

    return (
      <Accordion sx={ {backgroundColor: "transparent"}}
      defaultExpanded={props.defaultExpanded ?? true}
      >
        <AccordionSummary 
          className="stripe-pane-accordion-summary"
            expandIcon={ <ExpandMoreIcon sx={{color: "#ffffff"}} />}
            sx={{ backgroundColor: theColor, 
            maxHeight: "15px", m: "0px", p: "0px 15px 0px 15px", 
            borderRadius: "10px 10px 0px 0px", color: "white" }}>
          {props.title}
        </AccordionSummary>
        <AccordionDetails>
          {props.children}
        </AccordionDetails>
      </Accordion>
    );
}

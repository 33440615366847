import { Stack, Box } from "@mui/material";
import CrudeScaleTextBox from "components/controls/CrudeScaleTextBox";
import ReactMarkdown from "react-markdown";
import "./AvatarSpeaking.css";
import rehypeRaw from "rehype-raw";

import speech_bub_vec from "../../assets/tavatars/speech-bub-vector.svg";

interface SpeechBubbleProps {
  contentFormatted: string;
  showTypingAnimation?: boolean;
}

function SpeechBubble(props: SpeechBubbleProps) {
  const Typing = () => (
    <Stack className="typing" ml="auto" mr="auto" width="80px" height="32px" p="10px" borderRadius="20px" direction="row" alignItems="center" justifyContent="space-between" sx={{ background: "#e6e6e6" }}>
      <Box className="typing__dot"/>
      <Box className="typing__dot" />
      <Box className="typing__dot" />
    </Stack>
  );

  return (
    <Stack
      direction="column"
      justifyContent="center"
      // EXPANDS HORIZONTALLY
      flexGrow={1}
      sx={{
        ml: "-55px",
        backgroundImage: `url('${speech_bub_vec}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"
      }}>
      <CrudeScaleTextBox sx={{ m: "15px 15px 25px 65px", color: "#555555", overflow: "hidden" }} text={props.contentFormatted}>
        {props.showTypingAnimation ? (
          <Typing />
        ) : (
          <ReactMarkdown 
            // TODO: Using the rehype plugin does raise a potential XSS issue as it allows arbitrary HTML to be rendered as a workaround for our markdown editor inserting Img tags and UL tags
            rehypePlugins={[rehypeRaw] as any}>
            {props.contentFormatted}
          </ReactMarkdown>
        )}
      </CrudeScaleTextBox>
    </Stack>
  );
}

interface AvatarSpeakingProps {
  avatarUrl: string;
  contentFormatted: string;
  showTypingAnimation?: boolean;
}

export default function AvatarSpeaking(props: AvatarSpeakingProps) {
  return (
    <Stack direction="row" width="calc(100% + 20px)" maxWidth="calc(100% + 20px)" alignItems="center" justifyContent="space-between" gap={0} sx={{ p: 0, m: "0px 0px -20px -20px", overflow: "hidden" }}>
      {/* AVATAR IMAGE */}
      <Box component="img" src={props.avatarUrl} sx={{ width: "150px", minWidth: "150px", height: "190px", mb: "12px" }} />

      {/* START OF SPEECH BUBBLE COLUMN */}
      <SpeechBubble showTypingAnimation={props.showTypingAnimation} contentFormatted={props.contentFormatted} />
    </Stack>
  );
}

import { ttRoutingResponse } from "data/model/geo/ttRoutingResponse";
import L, { LatLngBounds, LatLngLiteral, LocationEvent, LocationEventHandlerFn, polyline } from "leaflet";
import { useEffect, useState } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { GeoJSON } from 'react-leaflet';
import { LineString, MultiLineString } from 'geojson';

var polyUtil = require('polyline-encoded');

export interface MapPolylinesProps {
  encodedPolyline?: string;
}

export default function MapPolylines(props: MapPolylinesProps) {
  const map = useMap();

  const [polyline, setPolyline] = useState<L.Polyline<LineString | MultiLineString, any>>();

  useEffect(() => {
    if (!map) return;    

    if (polyline) {
      polyline.remove();
    }

    

    if (props.encodedPolyline) {
      var decodedPolyline = polyUtil.decode(props.encodedPolyline);
      setPolyline(L.polyline(decodedPolyline, { color: '#DE191E', weight: 5 }));
    }

  }, [props.encodedPolyline, map]);

  useEffect(() => {
    polyline?.addTo(map);
  }, [polyline]);

  return <></>;
}

import { Box, Button, CircularProgress, LinearProgress, Stack, SxProps, Typography, useTheme } from "@mui/material";
import { TTTheme } from "@mui/material/styles/createPalette";

interface ProgressButtonProps {
  waiting?: boolean;
  disabled?: boolean;
  title: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  sx?: SxProps;
  size?: "small" | "medium" | "large";
  variant?: "text" | "outlined" | "contained";
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function ProgressButton(props: ProgressButtonProps) {
  // const theme = useTheme<TTTheme>();

  return (
    <Button sx={props.sx} size={props.size} color={props.color} variant={props.variant ?? "contained"} onClick={props.onClick} disabled={props.waiting || props.disabled}>
      <Stack direction="column" alignItems="center">
        <Typography variant="button" lineHeight="inherit">{props.title}</Typography>
        {props.waiting ? <LinearProgress color="inherit" sx={{ width: "100%", mt: "-5px" }} /> : <Box sx={{ height: "0px" }}></Box>}
      </Stack>
    </Button>
  );
}

import { Box, Typography } from "@mui/material";
import { PageBox } from "components/chrome/PageBox";
import PageTitle from "components/chrome/PageTitle";
import { Link } from "react-router-dom";


export default function TermsPage() {
  return (
    <PageBox>
      <PageTitle>Terms & Conditions</PageTitle>

      <Box>
      
{/* Customer Definition */}
      <Typography variant="h5" sx={{ mt: "12px" }}>1. Introduction</Typography>
      <Typography variant="body2">
        These Terms and Conditions apply to a person who purchases a ticket from Treasure Tours, via the website or any other applicable means ("Customer").  By making
        such a purchase you acknowledge and accept these Terms and Conditions.
      </Typography>

      {/* Identity and Location */}
      <Typography variant="h5" sx={{ mt: "12px" }}>2. Identity and Location:</Typography>
      <Typography variant="body2">
        "Treasure Tours" is a sole trading operation based at 17 Wrotham Road, Barnet, EN5 4LE, United Kingdom.
      </Typography>

      
      {/* Product Description */}
      <Typography variant="h5" sx={{ mt: "12px" }}>3. Product Description:</Typography>
      <Typography variant="body2">
        When a Customer purchases a ticket from Treasure Tours, they are granted access to participate in an online treasure hunt, hereafter referred to as a 'Tour.' Each ticket provides single-use access for one or more participants, via a single, shared web-enabled device. The content and duration of the Tour are specified in the relevant product description.
        Tickets are non-transferable as they are linked to a single account/login.
      </Typography>

{/* Service Availability */}
<Typography variant="h5" sx={{ mt: "12px" }}>4. Service Availability:</Typography>
      <Typography variant="body2">
        Treasure Tours strives to make Tours available for play at all times. However, we do not guarantee uninterrupted access due to factors beyond our control, including but not limited to technical issues, maintenance, or unforeseen circumstances. We make every effort to minimize disruptions and will communicate any known downtime in advance.
      </Typography>

      {/* Refund Policy */}
      <Typography variant="h5" sx={{ mt: "12px" }}>5. Refund Policy:</Typography>
      <Typography variant="body2">
        Treasure Tours strives to deliver excellent customer satisfaction.
        A refund request may be made via our official website contact form within 30 days of purchase, solely in the event that a Tour cannot be taken due to technical malfunctions or other issues directly attributable to Treasure Tours. 
        Refunds will not be given due to circumstances beyond our control, including but not limited to poor weather conditions.
        All other refund requests will be considered at our sole discretion. Refunds may not be granted if the Tour has already been accessed or completed.
      </Typography>

      {/* Intellectual Property */}
      <Typography variant="h5" sx={{ mt: "12px" }}>6. Intellectual Property:</Typography>
      <Typography variant="body2">
        All content, including but not limited to text, images, and designs, associated with Treasure Tours is the intellectual property of Treasure Tours. Customers are granted a limited license for personal use only and may not reproduce, distribute, or otherwise exploit the content without explicit permission. Additionally, we may use other Third-Party Intellectual Property under license. Please refer to our <a href="/app/credits">credits</a> page for further details regarding third-party IP and attributions.
      </Typography>

      {/* Customer Responsibility / Safety */}
      <Typography variant="h5" sx={{ mt: "12px" }}>7. Customer Responsibility / Safety:</Typography>
      <Typography variant="body2">
        Customers are responsible for their own safety and welfare during the experience, as well as that of the people they are with. Any map directions provided are purely a guideline and are not a substitute for taking proper care and attention when navigating terrain.
      </Typography>

      {/* Privacy Policy */}
      <Typography variant="h5" sx={{ mt: "12px" }}>8. Privacy Policy:</Typography>
      <Typography variant="body2">
        Treasure Tours values your privacy. Personal information collected during transactions will be used solely for the purpose of providing the Tour and corresponding with you and will not be shared with third parties without consent. For more details, please refer to our <a href="/app/privacy">Privacy Policy</a>.
      </Typography>

      {/* Contact Information */}
      <Typography variant="h5" sx={{ mt: "12px" }}>9. Contact Information:</Typography>
      <Typography variant="body2">
        For inquiries, support, or additional information, please contact us via the <a href="/app/contact">contact form</a> or by mail at the registered address.
      </Typography>

      <Typography variant="body2" sx={{ mt: "12px" }}>
        These terms are subject to change, and Customers are encouraged to review them periodically.
      </Typography>
    

      <Typography variant="caption" sx={{ mt: "12px" }}>
        Last updated: 14 December 2023
      </Typography>

      </Box>
    </PageBox>
  );
}

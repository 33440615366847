import { Box, SxProps } from "@mui/material";
import { PropsWithChildren } from "react";




interface CrudeScaleTextBoxProps {
    sx?: SxProps;
    text: string;
}

export default function CrudeScaleTextBox(props: PropsWithChildren<CrudeScaleTextBoxProps>) {

    const calcFontSize = ():string => {
        if (props.text.length < 10) {
            return "40px";
        } else if (props.text.length < 20) {
            return "32px";
        } else if (props.text.length < 40) {
            return "26px";
        } else if (props.text.length < 100) {
            return "24px";
        } else if (props.text.length < 150) {
            return "18px";
        } else if (props.text.length < 200) {
            return "17px";
        } else {
            return "16px";
        } 
    }

    return (
        <Box fontSize={calcFontSize()} fontFamily="Quicksand, sans-serif" sx={props?.sx}>
            {props.children}
        </Box>
    );
}
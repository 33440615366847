import { LatLngLiteral } from "leaflet";
import mediaItem from "./mediaItem";
import { waypoint } from "./waypoint";
import { channel } from "./channel";

export type tourPlayModes = "RepeatUntilCorrect"|"OneGoPerQuestion"|"FreeAnswersNoFeedback";
export type tourPublishingStatuses = "Unpublished"|"PublicUnlisted"|"PublicListed"|"Disabled";

export const helpForPlayMode = (mode:tourPlayModes):string => {
  switch (mode) {
    case "RepeatUntilCorrect":
      return "The player must keep trying each question until they get the answer right.";

    case "OneGoPerQuestion":
      return "The player only gets one attempt at each question, whether they are right or not."

    case "FreeAnswersNoFeedback":
    default:
      return "The player answers each question but is not told during the quiz whether they were right or not."
  }
};


interface tourSerial {
  id: number;
    name: string;
    channel: channel;
    description: string;
    blurb: string;
    preTourInstructions?: string;

    durationMins?: number;  // 0-1200
    durationMinsStated?: number;  // 0-1200
    distanceMetres?: number; // 0 - 100000
    distanceMetresStated?: number; // 0 - 100000

    tourDifficulty?: number; // 0-5
    travelDifficulty?: number; // 0-5
    ratingScore?: number; // 0-5

    placeCountryCode?: string;
    placeName?: string;
    placeLocation?: LatLngLiteral;

    playMode: tourPlayModes;
    canSkipQuestions: boolean;
    canRequestHints: boolean;
    penaliseMultipleAttempts: boolean;
    giveCorrectAnswerOnFailure: boolean;
    penaliseHints: boolean;
    giveHintOnWrongAnswer: boolean;

    requiresTicket: boolean;
    ticketPrice?: number;
    ticketPriceCurrencyCode?: string;
    ticketPriceFormatted: string; // calculated on server, always present

    publishingStatus: tourPublishingStatuses;
    publishURL?: string;
    isLocked: boolean;
    lockedMessage: string|undefined;
    completedMessage: string;
    totalHintsPermitted: number|undefined;
    mediaItems: mediaItem[];
    themeColourHexCode: string;

    backgroundMediaItem?: mediaItem;
    defaultTavatarMediaItem?: mediaItem;

}

interface tour extends tourSerial {
  
  isBlank: boolean;
}


  export const BLANK_TOUR:tour = {
    name: '',
    channel: {},
    description: '',
    blurb: '',
    placeCountryCode: 'gb',
    placeName: 'United Kingdom',
    completedMessage: '',
    isLocked: false,
    publishingStatus: "Unpublished",
    canSkipQuestions: true,
    canRequestHints: true,
    penaliseMultipleAttempts: true,
    giveCorrectAnswerOnFailure: true,
    penaliseHints: false,
    giveHintOnWrongAnswer: true,
    playMode: "RepeatUntilCorrect",
    lockedMessage: 'Hunt Locked',
    requiresTicket: false,
    totalHintsPermitted: undefined, // unlimited hints
    mediaItems: [],
    id: 0,
    themeColourHexCode: '',
    isBlank: true,
    ticketPriceFormatted: ""
  };


export default tour
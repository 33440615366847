import {
  useContext,
  useEffect,
  useState
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./HuntMonitor.css";

import GroupsIcon from '@mui/icons-material/Groups';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import { useConfirm } from "material-ui-confirm";
import liveHuntStatus, { BLANK_LIVE_HUNT_STATUS } from "data/model/liveHuntStatus";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Container, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import game from "data/model/game";
import { formatSeconds } from "utils/utils-time";
import useAPI from "services/useHunterApi";



function HuntMonitor() {
  let { tourId } = useParams();
  const navigate = useNavigate();
  
  const [statusBoard, setStatusBoard] = useState<liveHuntStatus>(BLANK_LIVE_HUNT_STATUS);
  
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();

  // Grab the latest board
  // Fetch a list of the questions for this hunt on first render
  useEffect(() => {
    callAPI<void, liveHuntStatus>(
      `/api/monitor/livehuntstatus/tour/${tourId}`,
      "GET"
    )
    .then((s) => {
      setStatusBoard(s)
    })
    .catch (error => {})
    
  }, [tourId]);

  const handleCloseButton = () => {
      navigate('/create');
  }

  const gameWithID = (tid:string):game => {
    return statusBoard.games.filter(t => t.id===tid)[0];
  };


  return (
    <div className="hunt-monitor-container">
      <h3>Monitoring {statusBoard.huntSummary.name}</h3>

      {statusBoard.progresses.map((p) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack direction="row" sx={{marginTop: "15px"}}>
              <GroupsIcon />
              <Typography variant="h5">
                &nbsp;
                {gameWithID(p.gameId).name}
              </Typography>
            </Stack>

            <Container sx={{ flexGrow: "1", marginTop: "10px" }}>
              <LinearProgress
                variant="buffer"
                color="secondary"
                value={(p.viewingQuestionIndex / p.totalQuestions) * 100}
                valueBuffer={(p.questionsAnswered / p.totalQuestions) * 100}
              />
              <Typography color="primary">
                {`Q${p.questionsAnswered ?? "?"}/${p.totalQuestions ?? "?"}`}
              </Typography>
            </Container>

            <Stack direction="column">
              <Chip label={`${p.totalHintsRemaining} hints left`} />
              <IconButton
                size="small"
                color={p.hasFinished === true ? "success" : "warning"}>
                {formatSeconds(p.elapsedSeconds)}
                {p.hasFinished === true && <EmojiEventsIcon />}
                {p.hasFinished !== true && <DirectionsRunIcon />}
              </IconButton>
              <Typography></Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {p.hasFinished === true && (
              <div>
                This team has finished!
                <br />
                This team played for {formatSeconds(p.elapsedSeconds)}
                <br />
                They finished {formatSeconds(p?.secondsSinceFinished ?? 0)} ago.
              </div>
            )}

            {p.hasFinished !== true && (
              <div>
                This team has been playing for {formatSeconds(p.elapsedSeconds)}
                <br />
                They have progressed as far as Q{p.questionsAnswered}
                {p.viewingQuestionIndex < p.questionsAnswered && (
                  <strong>
                    &nbsp;but are currently viewing a prior question, Q
                    {p.viewingQuestionIndex}
                  </strong>
                )}
                <br />
                They last progressed{" "}
                {formatSeconds(p.secondsSinceLastProgressed)} ago.
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default HuntMonitor;

import { Box } from "@mui/material";

interface BigGlyphBoxProps {
  glyph: string;
}

export default function BigGlyphBox(props: BigGlyphBoxProps) {
  return (
    <Box
      position="relative"
      border="solid #eeeeee 1px"
      fontSize="40px"
      lineHeight="2"
      textAlign="center"
      borderRadius="5px"
      width="100%"
      sx={{
        mt: "20px",
        aspectRatio: "12 / 7",

        backgroundImage: "linear-gradient(to right, #243949 0%, #517fa4 100%)",
        overflow: "hidden",
        wordWrap: "break-word"
      }}
      color="blue"
      alignItems="center">
      <Box component="div" height="200%" width="250%" sx={{ opacity: "0.1", transform: "rotate(45deg)" }}>
        {`${props.glyph}\u00A0\u00A0\u00A0`.repeat(200)}
      </Box>

      <Box
        fontSize="200px"
        width="100%"
        sx={{
          aspectRatio: "12 / 7",
          position: "absolute",
          top: "50%", // Centering vertically
          left: 0,
          transform: "translateY(-50%)", // Centering vertically
          textShadow: "0 10px 10px rgb(0 0 0 / 0.4)"
        }}>
        {/* CENTERED HORIZONTALLY AND VERTICALLY */}
        {props.glyph}
      </Box>
    </Box>
  );
}

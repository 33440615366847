import {
  Box,
  ButtonGroup,
  styled
} from "@mui/material";

export const QImageBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "60px", // Must set here, then can change dynamically. If this is not set here, the control collapses in on itself due to absolute positioning with an unknown height.  
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.7
    }
  }
}));


export const QImageButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  borderStyle: "solid",
  borderWidth: 1,
  borderRadius: 5
}));

export const QImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%"
});

export const QImageControls = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: "24px",
  top: 0,
  bottom: 0,
  paddingLeft: "30px",
  paddingRight: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "6px;",
  color: theme.palette.common.white
}));


export const QImageTopRightControls= styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  paddingTop: "2px",
  paddingRight: "2px",
  overflow: "hidden",
  display: "flex",
  alignItems: "start",
  justifyContent: "end",
  color: theme.palette.common.white
}));


export const QImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.25,
  transition: theme.transitions.create("opacity")
}));


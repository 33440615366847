import { FunctionComponentElement, PropsWithChildren, ReactComponentElement } from "react";
import "./BorderedSection.css";
import { Box, Stack, SvgIcon, Theme, Typography, useTheme } from "@mui/material";
import { TTTheme } from "@mui/material/styles/createPalette";

interface CaptionedIconProps {
  icon?: any;
  imgSrc?: string;
  svgComponent?: React.FunctionComponent;
  caption?: string;
}

export default function CaptionedIcon(props: CaptionedIconProps) {
  const theme = useTheme<TTTheme>();

  return (
    <Stack
      direction="column"
      gap="10px"
      alignItems="center"
      sx={{
        width: "140px",
        fontSize: "52px"
      }}
      color={theme.palette.common.white}>
      {props.imgSrc && <Box component="img" color="inherit" width="52px" src={props.imgSrc} sx={{ fill: "#ffffff" }} />}

      {props.svgComponent && <Box sx={{ fill: "#ffffff" }} color="inherit" fontSize="52px"><props.svgComponent /></Box>}

      {props.icon && <SvgIcon fontSize="inherit" color="inherit" component={props.icon} />}

      <Typography variant="h6" color="inherit">
        {props.caption}
      </Typography>
    </Stack>
  );
}

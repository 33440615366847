import { PropsWithChildren } from "react"
import "./BorderedSection.css"
import { Box, SvgIcon, SxProps, Typography } from "@mui/material";


interface BorderedSectionProps {
    icon?: JSX.Element;
    title?: string;
    sx?: SxProps;
}

export default function BorderedSection(props: PropsWithChildren<BorderedSectionProps>) {

    return (
        <Box component="div" className="bs-main" sx={props.sx && props.sx}>
            <div className="bs-header">
                <div className="bs-headerBorderBefore"></div>
                {(props.icon || props.title) && (
                    <div className="bs-headerTitle">
                        {/* {props.icon && <SvgIcon component={props.icon} />} */}
                        <Typography variant="caption" >
                            {props.title && <span>{props.title}</span>}
                        </Typography>
                        
                        
                    </div>
                )}
                <div className="bs-headerBorderAfter"></div>
            </div>
            <div className="bs-childrenContainer">{props.children}</div>
        </Box>
    );
}
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Box, CircularProgress, LinearProgress, Stack, SxProps, Typography } from "@mui/material";
import useAPI from "services/useHunterApi";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FastForwardIcon from "@mui/icons-material/FastForward";
import LockIcon from '@mui/icons-material/Lock';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

import { startGameAction } from "data/startGameActions";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useConfirm } from "material-ui-confirm";


interface StartActionButtonProps {
  action: startGameAction;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  waiting?: boolean;
}

export function StartActionButton(props: StartActionButtonProps) {
  return (
    <Button variant="contained" onClick={props.onClick} disabled={props.waiting}>
            {props.action.actionType === "PlayFree" ? <PlayArrowIcon /> : props.action.actionType === "PlayUsingTicket" ? <PlayArrowIcon /> : props.action.actionType === "ResumePreviousGame" ? <FastForwardIcon /> : props.action.actionType === "Locked" ? <LockIcon /> : <ShoppingBasketIcon />}
      <Stack direction="column" alignItems="center" ml="6px">
        <Typography variant="button" fontSize="15px" fontWeight="900" lineHeight="inherit">
          {props.action.actionLabel}
        </Typography>
        {props.waiting ? <LinearProgress color="inherit" sx={{ width: "100%", mt: "-5px" }} /> : <Box sx={{ height: "0px" }}></Box>}
        <Typography variant="body2" fontSize="9px" mt="-3px">
          {props.action.actionSublabel}
        </Typography>
      </Stack>
    </Button>
  );
}

interface PlayGameButtonsProps {
  tourId: string;
  sx?: SxProps;
  onNewInternalURL: (url: string) => void;
  onNewExternalURL: (url: string) => void;
}

export default function PlayGameButtons(props: PlayGameButtonsProps) {
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const confirm = useConfirm();

  const [startActions, setStartActions] = useState<startGameAction[]>([]);
  const [gettingCheckout, setGettingCheckout] = useState(false);
  const { authStatus } = useAuthenticator((context) => [context.route]);

  useEffect(() => {
   getPossibleStartActions();
  }, []);

  // Get possible ways to play this tour
  const getPossibleStartActions = () => {

    if (authStatus === "authenticated") {
    
      callAPI<void, startGameAction[]>(`/api/play/game/queryaction/tour/${props.tourId}`, "GET")
      .then((a) => {
        setStartActions(a);
      })
      .catch((error) => {});
    } else {

      callAPI<void, startGameAction[]>(`/api/public/play/game/queryaction/tour/${props.tourId}`, "GET")
      .then((a) => {
        setStartActions(a);
      })
      .catch((error) => {});
    }
  };

  const handleClickLocked = (msgText: string) => {

    confirm({
      title: "Tour Locked",
      description: msgText,
      confirmationText: "OK",
      hideCancelButton: true
    })
      .then(() => {
      })
      .catch(() => {
        /* User cancelled the close */
      });
  };

  const handleClickActionButton = (action: startGameAction) => {
    switch (action.actionType) {
      case "PlayFree":
      case "PlayUsingTicket":
        props.onNewInternalURL(`/play/tour/${props.tourId}/newgame`);
        break;

      case "ResumePreviousGame":
        props.onNewInternalURL(`/play/tour/${props.tourId}/g/${action.gameID}/`);
        break;

      case "Locked":
        handleClickLocked(action.detail ?? "This tour is currently locked - please check back at a later time.");
        break;

      case "BuyTicket":
        props.onNewInternalURL(`/app/tours/${props.tourId}/purchase/start`); 
        break;
    }
  };

  return (
    <Stack direction="column" sx={props.sx} gap="8px" width="100%">
      {startActions.length < 1 ? (
        <Button variant="contained" sx={{height: "48px", minWidth:"140px"}}>
          <CircularProgress color="inherit" size="30px" />
        </Button>
      ) : startActions.map((s, index) => (
        <StartActionButton
          key={`sab-${index}`}
          action={s}
          onClick={() => {
            handleClickActionButton(s);
          }}
          waiting={s.actionType === "BuyTicket" ? gettingCheckout : false}
        />
      ))}
    </Stack>

  );
}
import { Box } from "@mui/material";
import styledMediaItem from "data/model/styledMediaItem";

interface ImageBoxProps {
  smi: styledMediaItem;
}

export default function StyledMediaBox(props: ImageBoxProps) {
  return (
    <Box
      component="img"
      width="100%"
      border="1px solid #e5e5e5"
      borderRadius="5px"
      sx={{
        aspectRatio: "12 / 7",
        objectFit: props?.smi?.style?.fit?.toLowerCase() ?? ""
      }}
      alt={props.smi.item.title}
      src={props.smi.item.url}
    />
  );
}

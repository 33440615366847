import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { ChangeEvent, Fragment, useState } from "react";
import customButton, { customButtonActions } from "data/model/gameResponseMessageButton";

interface CustomButtonDefProps {
  key?: string;
  item: customButton;
  onUpdateItem: (updatedItem: customButton) => void;
}

export default function CustomButtonDef(props: CustomButtonDefProps) {
  const handleChangeTextValue = (e: ChangeEvent<HTMLInputElement>) => {
    props.onUpdateItem({
      ...props.item,
      [e.target.name]: e.target.value
    });
  };

  const handleChangeCustomButtonAction = (newValue: customButtonActions) => {
    props.onUpdateItem({
      ...props.item,
      action: newValue
    });
  };

  return (
    
        <Fragment key={props.key}>
        <Stack direction="column" gap="6px" width="100%" alignItems="stretch">

        {/* ROW ONE */}
        <Stack direction="row" gap="5px" justifyContent="space-between">
        <FormControl fullWidth>
        <InputLabel id="action-label">Type</InputLabel>
        <Select
          size="small"
          id="questionType"
          value={props.item.action as string}
          labelId="action-label"
          label="Type"
          onChange={(e) => {
            handleChangeCustomButtonAction(e.target.value as customButtonActions);
          }}>
          <MenuItem value={"Link"}>Link</MenuItem>
          <MenuItem value={"DisplayContent"}>Display Text</MenuItem>
        </Select>
        </FormControl>

        <FormControl fullWidth sx={{flexGrow:1}}>
        <TextField variant="outlined" size="small" label="Label" name="label" value={props.item.label ?? ""} onChange={handleChangeTextValue} />
        </FormControl>

        </Stack>


        {/* ROW TWO */}
        <Stack direction="row" gap="5px">

        <FormControl fullWidth>
        <TextField variant="outlined" size="small" label={props.item.action.toString()} name="content" value={props.item.content ?? ""} onChange={handleChangeTextValue} />
        </FormControl>
        </Stack>


        </Stack>


        </Fragment>
  );
}

import {
  useEffect,
  useState
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./HuntResponsesList.css";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlockIcon from "@mui/icons-material/Block";
import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

import { useConfirm } from "material-ui-confirm";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, List, ListItem, Typography } from "@mui/material";
import game from "data/model/game";
import huntResponses, { BLANK_HUNT_RESPONSES } from "data/model/monitoring/huntResponses";
import useAPI from "services/useHunterApi";



function HuntResponsesList() {
  let { tourId } = useParams();
  const location = useLocation();
  const confirm = useConfirm();
  const navigate = useNavigate();
  
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [filterQuestions, setFilterQuestions] = useState(true);
  const [huntResponses, setHuntResponses] = useState<huntResponses>(BLANK_HUNT_RESPONSES);

  // Grab the latest board
  // Fetch a list of the responses for this hunt on first render
  useEffect(() => {
    callAPI<void, huntResponses>(
      `/api/monitor/huntresponses/tour/${tourId}`,
      "GET"
    )
      .then((s) => {
        setHuntResponses(s)
      })
  }, [tourId]);



  const handleCloseButton = () => {
      navigate('/create');
  }

  const teamWithID = (tid:string):game => {
    return huntResponses.games.filter(t => t.id===tid)[0];
  };

  const filteredResponses = filterQuestions ?
    huntResponses.responses.filter(r => r.questionType !== "AcknowledgementOnly") :
    huntResponses.responses;

  return (
    <div className="hunt-monitor-container">
      <h3>Responses for {huntResponses.huntSummary.name}</h3>

      <FormGroup>
      <FormControlLabel control={<Checkbox defaultChecked
          checked={filterQuestions}
          onChange={() => {
            setFilterQuestions(!filterQuestions);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
      />} label="Only questions" />
    </FormGroup>

      {filteredResponses.map((r, index) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>

            {r.questionType === "AcknowledgementOnly" && (
              <InfoIcon />
            )}
            {r.questionType === "FreeText" && (
              <QuestionAnswerIcon />
            )}
            {r.questionType === "MultiChoice" && (
              <SpeakerNotesIcon />
            )}
            {r.questionType === "EndofQuestions" && <BlockIcon />}
          
                
            <Typography sx={{marginLeft: "10px"}}>
              {r.questionContentFormatted.length < 50 ? r.questionContentFormatted : r.questionContentFormatted.substring(0,49)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Typography>
              Correct Answer(s):
              {r.correctAnswers.map(a => (
                <p>{a}</p>
              ))}
            </Typography>

            <Typography>
              Team answers:
            </Typography>

            <List>
              { r.gameResponses.map(tr => (
                <ListItem>
                  <p>
                    {teamWithID(tr.gameId).name} - 
                    {tr.responseValue}
                    (
                      {tr.estimatedScore}
                    )
                  </p>
                </ListItem>
              ))}              
            </List>

          </AccordionDetails>
        </Accordion>
      ))}

      
    </div>
  );
}

export default HuntResponsesList;

import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";

import theme from "data/model/compose/theme";
import { useContext, useEffect, useState } from "react";
import useAPI from "services/useHunterApi";

import { truncateContent } from "utils/utils-strings";


export interface ScenarioSelectProps {
  scenarioId?: number;
  onUpdateScenarioId: (value?:number) => void;
}

export default function ScenarioSelect(props: ScenarioSelectProps) {
  const { callAPI } = useAPI();
  const [themes, setThemes] = useState<theme[]>([]);
  const [selectedThemeId, setSelectedThemeId] = useState<number|undefined>();

  // Get themes from server on first-load
  useEffect(() => {
    callAPI<void, theme[]>(`/api/theme/user/me`, "GET").then((t) => {
      setThemes(t);
    }).catch(() => {});;
  }, []);

  const selectedTheme = themes.find(t => t.id === selectedThemeId);
  const selectedScenario = selectedTheme?.scenarios.find(s => s.id === props.scenarioId);

  return (
    <Stack direction="column" gap="10px">
      
      <FormControl fullWidth>
      <InputLabel id="theme-label">Theme</InputLabel>
      <Select
        value={selectedThemeId ?? ''}
        labelId="theme-label"
        label="Theme"
        fullWidth
        onChange={(e) => {
          setSelectedThemeId(e.target.value as number) 
          }}>
           {themes.map((t) => (
              
                <MenuItem key={`th-${t.id}`} value={t.id}>
                  {t.name}
                </MenuItem>
              
           ))}
      </Select>
    </FormControl>

    <FormControl fullWidth>
      <InputLabel id="scenario-label">Scenario</InputLabel>
      <Select
        value={props.scenarioId ?? ''}
        labelId="scenario-label"
        label="Scenario"
        fullWidth
        onChange={(e) => {
          props.onUpdateScenarioId(e.target.value as number);
          }}>
           {selectedTheme?.scenarios.map((s) => (
                <MenuItem key={`th-${s.id}`} value={s.id}>
                  {s.title}
                </MenuItem>
           ))}
      </Select>
      <Typography variant="caption">
        { truncateContent(selectedScenario?.situationSummary ?? '', 150) }
      </Typography>
    </FormControl>
      
    </Stack>

  );
}

import "./BorderedSection.css"
import { Box, SxProps, styled } from "@mui/material";

import homeBackground from "../../assets/home-background_65q.jpg";
import { PropsWithChildren } from "react";

interface PageBoxProps {
  sx?: SxProps;
}

export function PageBox(props: PropsWithChildren<PageBoxProps>) {
  return (

    <Box
    sx={{
      backgroundImage: `url(${homeBackground})`,
      backgroundPosition: 'top left',
      
      ...props.sx
    }}>
      <Box sx={{
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        m: { xs: "18px", sm: "32px" },
        p: { xs: "4px", sm: "6px" },
      }}>
        {props.children}
      </Box>
    </Box>
);


}
{/* 

export const PageBox = styled(Box)(({ theme }) => ({

  
  backgroundImage: `url(${homeBackground})`,
  backgroundPosition: 'top left',

  // backgroundColor: "#fafafa",

  [theme.breakpoints.up("xs")]: {
    padding: "18px"
  },
  [theme.breakpoints.up("sm")]: {
    padding: "32px"
  },
    
  }));
   */}
import { Box } from "@mui/material";
import { PageBox } from "components/chrome/PageBox";
import PageTitle from "components/chrome/PageTitle";
import { Link } from "react-router-dom";

import reactLeafletIcon from "assets/react-leaflet-icon-transparent-500x500.png";

export const SITE_TITLE = "Treasure Tours";

export default function CreditsPage() {
  return (
    <PageBox>
      <PageTitle>Credits</PageTitle>

      <Box>
        <h4>Site Content</h4>
        <div>Flag imagery used from <a href="https://flagpedia.net">Flagpedia</a></div>
        
        <hr />
        <h4>Components</h4>
        <Box component="img" height="60px" src={reactLeafletIcon } />Mapping by <a href="https://react-leaflet.js.org/docs/extra-logo-branding/">react leaflet</a> 
        <hr />
        <h4>Imagery</h4>
        <p>Many of the photographs and images on this site come from the wonderful contributors to Unsplash and Pexel</p>
        <p>Be sure to explore their works.</p>
        <ul>
          <li>
            Photo by <a href="https://unsplash.com/@gabiontheroad?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Gabriella Clare Marino</a> on{" "}
            <a href="https://unsplash.com/photos/man-in-blue-t-shirt-and-blue-denim-jeans-holding-black-dslr-camera-3jnxBQx8tiE?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
          </li>
          <li>Photo of woman holding a Smartphone by <a href="https://www.pexels.com/photo/photo-of-a-woman-holding-smartphone-3776852/">Andrea Piacquadio on Pexels</a></li>
          <li>
            Photo of kids in wellies by <a href="https://unsplash.com/@profwicks?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Ben Wicks</a> on{" "}
            <a href="https://unsplash.com/photos/four-children-standing-on-dirt-during-daytime-iDCtsz-INHI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
          </li>
          <li>
            Images in <Link to="/app/home/medialibrary">Media Library</Link> are credited within the metadata for each image - click on the 'Edit' icon to display the appropriate credit.
          </li>
        </ul>
        <hr />
        <h4>Third Party Software</h4>
        <div>For details of third party software licenses, click <a href="/app/licences">here</a></div>
      </Box>
    </PageBox>
  );
}

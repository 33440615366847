import {
  useEffect,
  useState
} from "react";

import { CSVLink } from "react-csv";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./HuntResponsesGrid.css";

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import DownloadIcon from '@mui/icons-material/Download';

import { useConfirm } from "material-ui-confirm";

import { Checkbox, FormControlLabel, FormGroup, IconButton, Stack } from "@mui/material";
import huntResponses, { BLANK_HUNT_RESPONSES } from "data/model/monitoring/huntResponses";
import { Headers, LabelKeyObject } from "react-csv/components/CommonPropTypes";
import useAPI from "services/useHunterApi";



function HuntResponsesGrid() {
  let { tourId } = useParams();
  const location = useLocation();
  const confirm = useConfirm();
  const navigate = useNavigate();
  
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [filterQuestions, setFilterQuestions] = useState(true);
  const [huntResponses, setHuntResponses] = useState<huntResponses>(BLANK_HUNT_RESPONSES);

  // Grab the latest board
  // Fetch a list of the responses for this hunt on first render
  useEffect(() => {
    callAPI<void, huntResponses>(
      `/api/monitor/huntresponses/tour/${tourId}`,
      "GET"
    )
      .then((s) => {
        setHuntResponses(s)
      })
      .catch (error => {})
  }, [tourId]);

  const filteredResponses = filterQuestions ?
    huntResponses.responses.filter(r => r.questionType !== "AcknowledgementOnly") :
    huntResponses.responses;

  // *************
  // COLUMNS / HEADERS
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 50, editable: false},
    { field: 'qid', headerName: 'Qx', width: 50, editable: false},
    { field: 'content', headerName: 'Preview', width: 100, editable: false},
    { field: 'correctAnswers', headerName: 'Correct Answers', width: 140, editable: false}
  ];

  // Add in three columns per team
  const responseColumns: GridColDef[] = huntResponses.games.flatMap((t) => {
    return [
      {
      field: `resp-game-${t.id}`,
      headerName: `Tm ${t.name} Ans.`,
      width: 200
    },
    {
      field: `score-game-${t.id}`,
      headerName: `Tm ${t.name} Score`,
      width: 90
    },
    {
      field: `hints-game-${t.id}`,
      headerName: `Tm ${t.name} Hints`,
      width: 90
    }
    ];
  });
    
  columns.push(...responseColumns);

  // *************
  // ROWS
  const rows:any[] = [];

  // Add in one row per question
  var iid: number = 1;
  for (let qr of filteredResponses) {

      var saneQuestionContent = qr.questionContentFormatted;
      saneQuestionContent = saneQuestionContent.replaceAll('\n', '');
      saneQuestionContent = saneQuestionContent.replaceAll('\r', '');
      saneQuestionContent = saneQuestionContent.replaceAll('\t', '');

    // First 4 columns
    var newRow:any = {
      id: iid,
      qid: `Q${qr.questionId}`,
        content: (saneQuestionContent.length < 150 ? saneQuestionContent : saneQuestionContent.substring(0,149)),
      correctAnswers: qr.correctAnswers.join(",")
    };

    // Add in three columns per team
    for (let t of huntResponses.games) {
      var findTeamResponse = qr.gameResponses.find(tr => tr.gameId === t.id);
      newRow[`resp-game-${t.id}`] = (findTeamResponse) ?
          findTeamResponse.responseValue : ' ';

      newRow[`score-game-${t.id}`] = (findTeamResponse) ?
      findTeamResponse.estimatedScore : ' ';

      newRow[`hints-game-${t.id}`] = qr.hintsGivenTogameIds.includes(t.id) ?
      -1 : 0;
    }

    rows.push(newRow);
    iid += 1;
  }

  // HEADERS (FOR CSV)
  const csvHeaders: Headers = columns.map((c) => (
    {
    label: c.headerName,
    key: c.field
    } as LabelKeyObject
  ));

  return (
    <div className="hunt-monitor-container">
      <h3>Responses for {huntResponses.huntSummary.name}</h3>

      <FormGroup>
        <Stack direction="row" spacing={2} sx={{ padding: "0 10px 0 10px", backgroundColor: "Menu"}}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                checked={filterQuestions}
                onChange={() => {
                  setFilterQuestions(!filterQuestions);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Only questions"
          />


        <IconButton>
          <CSVLink data={rows} headers={csvHeaders}>
            <DownloadIcon />
          </CSVLink>
        </IconButton>
        </Stack>

      </FormGroup>

      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 }
          }
        }}
        pageSizeOptions={[5, 10, 20]}
      />
    </div>
  );
}

export default HuntResponsesGrid;

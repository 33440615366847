import { AppBar, Dialog, DialogTitle, IconButton, Stack, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import mediaItem from "data/model/mediaItem";
import MediaLibrary from "./MediaLibrary";
import { mediaLibraryQuery, mediaLibrarySelectQuery } from "data/model/mediaLibraryQuery";

interface MediaLibraryDialogProps {
  open: boolean;
  fullScreen?: boolean;
  onRequestCancel: () => void;
  onConfirmMedia: (media: mediaItem) => void;
  query?: mediaLibraryQuery;
}

function MediaLibraryDialog(props: MediaLibraryDialogProps) {

  return (
    <Dialog open={props.open} fullScreen={props.fullScreen} keepMounted={false} fullWidth maxWidth={"xl"}>
        <AppBar position="sticky">
          <DialogTitle>
            <Stack direction="row" gap={{ xs: "4px", md: "20px" }} alignItems="center" justifyContent="start" sx={{ pl: "20px", pr: "20px", width: "100%", height: "30px" }}>
              <Typography variant="h4">Media Library</Typography>

              <IconButton
            color="inherit"
              sx={{ml: "auto"}} // Pushes it to the right
              onClick={props.onRequestCancel}>
              <CloseIcon color="inherit" />
            </IconButton>
             
             
            </Stack>
          </DialogTitle>
        </AppBar>

      <MediaLibrary
        sx={{p: {xs: "10px", md: "20px"}}}
        onRequestCancel={props.onRequestCancel}
        onConfirmMedia={props.onConfirmMedia}
        query={props.query}
      />

    </Dialog>      

  );
}

export default MediaLibraryDialog;

import React, { ChangeEvent, useEffect } from "react";

import {
  Button,
  ButtonGroup,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  TextField} from "@mui/material";


import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ErrorIcon from '@mui/icons-material/Error';

import Typography from "@mui/material/Typography";
import { immutableMove } from "utils/utils-arrays";

import "./CannedResponsesEditor.css"

interface CannedResponsesEditorProps {
  array: string[];
  correctArray: string[];
  onUpdateArrays: (updatedArray:string[], updatedCorrectArray:string[]) => void;
}

export default function CannedResponsesEditor(props: CannedResponsesEditorProps) {

  const handleChangeCannedResponse = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const oldValue = props.array[index].slice();
    //  .map to create a new array, replacing the item at the same index when the condition is met.

    props.onUpdateArrays( 
      (props.array.map((item, i) =>
      i === index ? e.target.value : item)),  // Also update the array of correct responses; if we find the string, update it

      (props.correctArray.map((item) =>
    item === oldValue ? e.target.value : item
      ))
    );
  }

  const handleClickAddCannedResponseButton = () => {
    // Clone the canned responses and add a new blank entry (unless the final item is already blank)
    const moreCannedResponses: string[] = Object.assign(
      [],
      props.array
    );
    if (
      moreCannedResponses.length === 0 ||
      moreCannedResponses[moreCannedResponses.length - 1].length > 0
    ) {
      moreCannedResponses.push("");
    }

    props.onUpdateArrays(moreCannedResponses, props.correctArray);
  };

  const handleClickCannedButtonDelete = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    const newCannedResponses: string[] = Object.assign(
      [],
      props.array
    );
    newCannedResponses.splice(index, 1);

    const newCorrectResponses: string[] = Object.assign(
      [],
      props.correctArray
    );
    const findIndex = newCorrectResponses.indexOf(
      props.array[index]
    );
    if (findIndex >= 0) {
      newCorrectResponses.splice(findIndex, 1);
    }

    props.onUpdateArrays(newCannedResponses, newCorrectResponses);
  };

  const handleClickCannedButtonMove = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
    delta: number
  ) => {
    const newCannedResponses = immutableMove(
      props.array as [],
      index,
      index + delta
    );

    props.onUpdateArrays(newCannedResponses, props.correctArray);
  };

  const handleClickMarkCannedResponseCorrect = (
    event: React.MouseEvent<HTMLButtonElement>,
    cr: string
  ) => {
    const i = props.correctArray.indexOf(cr.toLowerCase());

    const newCorrectResponses: string[] = Object.assign(
      [],
      props.correctArray
    );
    if (i < 0) {
      // add it in
      newCorrectResponses.push(cr.toLowerCase());
    } else {
      // remove it
      newCorrectResponses.splice(i, 1);
    }

    props.onUpdateArrays(props.array, newCorrectResponses);
  };



  return (
    <Container disableGutters
    >
    <div className="canned-responses">
      <List dense>
        {props.array.map((r, index) => (
          <ListItem
           disablePadding
            key={`cr-li-${index}`}
            secondaryAction={
              <ButtonGroup>
                <IconButton
                  disabled={index === 0}
                  aria-label="Move up"
                  onClick={(e) =>
                    handleClickCannedButtonMove(e, index, -1)
                  }>
                  <ArrowCircleUpIcon />
                </IconButton>
                <IconButton
                  disabled={
                    index ===
                    props.array.length - 1
                  }
                  aria-label="Move down"
                  onClick={(e) =>
                    handleClickCannedButtonMove(e, index, 1)
                  }>
                  <ArrowCircleDownIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="Delete"
                  onClick={(e) =>
                    handleClickCannedButtonDelete(e, index)
                  }>
                  <DeleteForeverIcon />
                </IconButton>
              </ButtonGroup>
            }>
            <ListItemAvatar>
              <IconButton
                aria-label="Mark as correct"
                onClick={(e) =>
                  handleClickMarkCannedResponseCorrect(e, r)
                }>
                {props.correctArray.includes(r.toLowerCase()) && (
                  <CheckCircleIcon className="circle-icon-correct" />
                )}
                {!props.correctArray.includes(r.toLowerCase()) && (
                  <CheckCircleOutlineIcon />
                )}
              </IconButton>
            </ListItemAvatar>

            <TextField
              autoFocus={ r === "" && index === (props.array.length-1) }
              className="canned-response"
              key={index}
              id="contentFormatted"
              label="Choice"
              variant="filled"
              value={r}
              onChange={(e) =>
                handleChangeCannedResponse(e, index)
              }
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleClickAddCannedResponseButton();
                  ev.preventDefault();
                }
              }}
            />
          </ListItem>
        ))}
      </List>

      <Button
        fullWidth
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        onClick={handleClickAddCannedResponseButton}>
        Add Choice
      </Button>

      {props.array.length > 0 && props.correctArray.length < 1 && (

        <Typography color="red" sx={{mt: "6px"}} >
          <ErrorIcon color="warning" sx={{marginRight: "6px"}} />
          You have not marked a choice as correct
        </Typography>
      )}
    </div>
  </Container>

                    
  );
}

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { useConfirm } from "material-ui-confirm";

import { Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';

import useAPI from "services/useHunterApi";

import { rating } from "data/model/rating";
import StarBox from "components/play/StarBox";
import { formatDateAsPretty } from "utils/utils-time";


interface TourRatingsProps {
  tourId: string;
}

export default function TourRatings(props:TourRatingsProps) {

  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [ratings, setRatings] = useState<rating[]|undefined>(undefined);

  // Get ratings on first load 
  useEffect(() => {
    console.log('calling API');
    callAPI<void, rating[]>(`/api/rating/tour/${props.tourId}`, "GET")
      .then((rs) => {
        console.log('setting rs');
        setRatings(rs);
      })
      .catch((error) => {});
  }, [props.tourId]);


  if (ratings === undefined) {
    return (<Box sx={{m: {xs: "12px", sm: "16px"}, height: "500px"}}>
        <Stack direction="column" gap="10px" sx={{width: "100%"}}>
  
          <Skeleton variant="rectangular" animation="wave" width="30px" height="30px" sx={{alignSelf:"end"}}></Skeleton>
          <Skeleton variant="rectangular" animation="wave" width="100%" height="100px"></Skeleton>

          <Skeleton variant="rectangular" animation="wave" width="200px" height="40px" sx={{alignSelf:"start"}}></Skeleton>
          <Skeleton variant="rectangular" animation="wave" width="150px" height="40px" sx={{alignSelf:"start"}}></Skeleton>

          <Skeleton variant="rectangular" animation="wave" width="100%" height="230px" sx={{mt: "20px"}}></Skeleton>

        </Stack>
    </Box>)
  } else {
    return (
       <>
       <Box>        
        <List>
          {ratings.map((r, index) => (
            <ListItem key={`rat-${index}`}>
              <ListItemAvatar color="secondary">
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
            <ListItemText
            primary={r.reviewText}
            secondary={`${r.userName} - ${formatDateAsPretty(r.creationDateTime)}`}
            />
            <Box minWidth="190px" fontSize="20px">
              <StarBox score={r.ratingScore} />
            </Box>
            </ListItem>
          ))}
          
        </List>

       </Box>

       </>
    );
  }
}

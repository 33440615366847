import { Outlet } from "react-router-dom";
import AppHeader from "components/chrome/AppHeader";
import { Box, Stack, ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import AppFooter from "components/chrome/AppFooter";
import { TTTheme } from "@mui/material/styles/createPalette";
import ttThemeOptions from "./TTThemeOptions";



export default function LayoutAppRoot() {
  const ttTheme: TTTheme = createTheme(ttThemeOptions) as TTTheme;

  responsiveFontSizes(ttTheme);


  return (
    <Stack
      width="100dvw" // THIS IS THE MAIN CONSTRAINT FOR MOBILE DEVICES TO PREVENT THE APP EXTENDING HORIZONTALLY BEYOND BOUNDS
      maxWidth="1360px" // THIS IS FOR DESKTOPS TO PREVENT AN UGLY SITE
      direction="column"
      gap="0px"

      sx={{
        backgroundColor: "#fafafa",
        mt: { xs: "0px", md: "25px" },
        mb: { xs: "0px", md: "25px" },
        borderRadius: { xs: "0px", md: "10px" }
      }}>
      <ThemeProvider theme={ttTheme}>
        <AppHeader />
        <Outlet />
        <AppFooter />
      </ThemeProvider>
    </Stack>
  );
}

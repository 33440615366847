import { AppBar, Box, Button, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import "./AppHeader.css";

import logo from "./logo.svg";
import { PropsWithChildren, useState } from "react";

import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import RequirePermission from "components/auth/RequirePermission";

import logoNoStrapBigOnDark from "../../assets/logo/tt7-logo-nostrap-big-on-dark.png"

const drawerWidth = 240;
export const SITE_TITLE = "Treasure Tours";

interface NavItem {
  name: string;
  href: string;
}

export default function AppHeader(props: PropsWithChildren) {
  const navItems: NavItem[] = [];

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { authStatus, signOut } = useAuthenticator((context) => [context.authStatus, context.signOut]);

  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Nav Items
  navItems.push({
    name: "Discover",
    href: "/app/tours"
  });
  if (authStatus === "authenticated") {
    navItems.push({
      name: "Dashboard",
      href: "/app/home"
    });
  } else {
    // Any nav items solely for logged-out users
    navItems.push({
      name: "Sign in",
      href: "/app/home"
    });
  }

  const sideDrawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {SITE_TITLE}
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, i) => (
          <ListItem key={`db-${i.toString()}`} disablePadding>
            <ListItemButton component={Link} to={item.href} sx={{ textAlign: "center" }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="sticky"
        elevation={0}
        sx={{
          color: "#ffffff"
        }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon />
          </IconButton>

          <Button href="/app" sx={{ mr: "auto" }}>
            <img src={logoNoStrapBigOnDark} alt="main logo" height="20px" />
          </Button>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item, i) => (
              <Button size="large" color="inherit" component={Link} to={item.href} key={`bi-${i.toString()}`} sx={{ textTransform: "none" }}>
                {item.name}
              </Button>
            ))}
          </Box>
          <div>
            <IconButton size="large" color="inherit" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenuOpen}>
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}>
              {authStatus === "authenticated"
                ? [
                    <MenuItem key="prof" component={Link} to="/app/profile" onClick={handleMenuClose}>
                      Profile
                    </MenuItem>,
                    <MenuItem key="dsh" component={Link} to="/app/home/edit" onClick={handleMenuClose}>
                      Dashboard
                    </MenuItem>,
                    <MenuItem key="mli" component={Link} to="/app/home/medialibrary" onClick={handleMenuClose}>
                      Media Library
                    </MenuItem>,
                    <RequirePermission key="tcom" name="ComposeAI">
                    <MenuItem key="tcom2" component={Link} to="/app/home/themeeditor" onClick={handleMenuClose}>
                      Theme Composer
                    </MenuItem></RequirePermission>,
                    <MenuItem key="sgno" onClick={signOut}>
                      Sign out
                    </MenuItem>
                  ]
                : [
                    <MenuItem key="signin" component={Link} to="/app/home" onClick={handleMenuClose}>
                      Sign in
                    </MenuItem>
                  ]}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth
            }
          }}>
          {sideDrawer}
        </Drawer>
      </nav>
    </Box>
  );
}

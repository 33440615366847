import {
  LatLngExpression,
  LatLngLiteral,
  latLng
} from "leaflet";
import {
  MapContainer,
  TileLayer} from "react-leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import DynamicMarker from "./DynamicMarker";
import MapController from "./MapController";
import { useContext, useState } from "react";
import GeocoderLeafletControl from "./GeocoderLeafletControl";
import { LocateControl } from "./LocateControl";
import { SBAlert, SBAlertContext } from "components/edit/SBAlertContext";


interface MapPickerProps {
  position?: LatLngLiteral;
  defaultCenter?: LatLngLiteral;
  height?: string|number;
  onUpdatePosition: (newPosition: LatLngLiteral) => void;
}

const DEFAULT_MAP_POINT:LatLngLiteral = { lat: 53.48718, lng: -1.6211774715588714};
const DEFAULT_HEIGHT:string = "100px";

export default function MapPicker(props: MapPickerProps) {

  const [startZoom, setStartZoom] = useState<number>((props.position || props.defaultCenter) ? 15 : 6);
  const {alert, setAlert} = useContext(SBAlertContext);

  const handleDynamicMarkerUpdatePosition = (newPosition: LatLngExpression) => {
    let ll = latLng(newPosition);
    props.onUpdatePosition({
      lat: ll.lat,
      lng: ll.lng
    });
  };

  return (
      <MapContainer
        center={props.position ?? props.defaultCenter ?? DEFAULT_MAP_POINT}
        zoom={startZoom}
        style={{ height: props.height ?? DEFAULT_HEIGHT, borderBottom: "solid 1px gray" }}
        scrollWheelZoom={true}>
        <MapController/>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <FullscreenControl forceSeparateButton position="topright" />
        <DynamicMarker
          position={props.position}
          onUpdatePosition={handleDynamicMarkerUpdatePosition}
        />
        <LocateControl 
          position="bottomright" 
          setView={"untilPanOrZoom"} //  Options are false, 'once', 'always', 'untilPan', or 'untilPanOrZoom'
          clickBehaviour={ {inView: 'setView', outOfView: 'setView', inViewNotFollowing: 'setView'}}  // 'stop', 'setView' or name of a key to inherit, e.g. {inView: 'stop', outOfView: 'setView', inViewNotFollowing: 'inView'}
          highAccuracy={true}
          onLocationError={(e, control) => {
            setAlert(
              new SBAlert({
                message: "Couldn't get your location - check your device's location permission settings for this app.",
                logMessage: `Couldnt get location ${e.message}`,
                severity: "warning",
                autoHideDuration: 5500
              })
            );
          }}
        />
        <GeocoderLeafletControl
          onUpdatePosition={handleDynamicMarkerUpdatePosition} 
        />
      </MapContainer>
  );
}

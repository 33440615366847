import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, CardHeader, CircularProgress, LinearProgress, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import useAPI from "services/useHunterApi";

import { TTTheme } from "@mui/material/styles/createPalette";
import { createTourProgress } from "data/model/compose/createTourProgress";
import { useEffect, useState } from "react";

interface stepDef {
  name: string;
  icon: any;
  component: any;
}

export default function CreateTourMonitor() {
  let { tourId } = useParams();
  const navigate = useNavigate();

  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [tourProgress, setTourProgress] = useState<createTourProgress>({ progressPercentage: 0, progressStage: "..." });

  const theme = useTheme<TTTheme>();
  const mqXSOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const checkProgress = () => {
    callAPI<void, createTourProgress>(`/private/api/createtourwizard/async/tour/${tourId}/progress`, "GET")
      .then((t) => {
        setTourProgress(t);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // Set up an interval to call checkProgress every 7 seconds
    const intervalId = setInterval(checkProgress, 7000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Card sx={{ margin: { xs: "0px", sm: "50px" } }}>
      <CardContent>
        <Stack direction="column">
        <Typography variant="h1" color="primary">
          Creating your Tour
        </Typography>
        <Typography variant="body2" mt="20px">
          This can take a couple of minutes.
        </Typography>
        <hr />
        <LinearProgress variant="determinate" value={tourProgress.progressPercentage}
            color="secondary" sx={{ width: "100%", height: "5px" }} />
            
        <Typography variant="h4" mt="20px" color="secondary" textAlign="center">
              {tourProgress.progressStage}
              {tourProgress.progressPercentage < 100 && (
              <CircularProgress variant="indeterminate" color="inherit" size="20px" sx={{ml: "8px"}} />
              )}
        </Typography>
        <Button sx={{mt: "20px"}} variant="contained" href={`/app/home/edit/tour/${tourId}`} disabled={tourProgress.progressPercentage < 100}>
          Go to tour
        </Button>
        {tourProgress.progressPercentage >= 100 && (
        <Typography variant="caption" mt="10px" textAlign="center">
          It can take up to 30 seconds for a tour's image/thumbnail to become available
        </Typography>
        )}
        </Stack>
      </CardContent>
    </Card>
  );
}

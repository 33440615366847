import React, {  } from "react";
import workingQuestion from "../../data/model/workingQuestion";
import ResponseMultiChoice from "./ResponseMultiChoice";
import Box from "@mui/material/Box";
import { Stack, SxProps, TextField } from "@mui/material";

import mediaItem from "data/model/mediaItem";
import AvatarSpeaking from "./AvatarSpeaking";
import StyledMediaBox from "components/controls/StyledMediaBox";
import WaypointDisplayMap from "./WaypointDisplayMap";
import BorderedSection from "components/chrome/BorderedSection";



interface WorkingQuestionSpaceProps {
  question: workingQuestion | undefined;
  defaultTavatarMediaItem?: mediaItem;
  onChangeResponseValue: (a: string) => void;
  onChangeResponseIndex: (a: number) => void; // used for canned responses
  sx?: SxProps;
}

function WorkingQuestionSpace(props: WorkingQuestionSpaceProps) {

  const onChangeFreetextValue = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    var maxLength = props.question?.responseMaxLength ?? 500;
    if (maxLength < 1) maxLength = 500;

    if (e.target.value.length <= maxLength) {
      props.onChangeResponseValue(e.target.value);
    }
  };


  return (
    // NB - HEIGHT OF THIS STACK MUST NOT BE CLAMPED, IT HAS TO BE ALLOWED TO EXTEND OUT THE PARENT DIV WHICH WILL SCROLL
    <Stack direction="column" justifyContent="flex-start" alignContent="start" gap="20px" width="100%" fontSize="1.05em" lineHeight="1.2" fontFamily="'Quicksand', sans-serif"
    sx={props.sx}>
      {/* AVATAR AND SPEECH BUBBLE */}
      <AvatarSpeaking avatarUrl={props.question?.tavatarMediaItem?.urlSmall ?? props.defaultTavatarMediaItem?.urlSmall ?? ""} 
      contentFormatted={props.question?.contentFormatted ?? ""}/>

      {/* MEDIA BOX */}
      {props.question?.contentStyledMedia && (
      <StyledMediaBox smi={props?.question?.contentStyledMedia} />
      )}

      {/* MAP FOR ANY INCLUDED WAYPOINT */}
      {props.question?.waypoint && props.question?.showWaypointMap && 
        <WaypointDisplayMap waypoint={props.question?.waypoint} />
      }

      {props.question?.responseType === "MultiChoice" && (
        <ResponseMultiChoice
          cannedResponses={props.question.cannedResponses}
          selectedIndex={props.question.responseIndex}
          onPushOption={(i: number) => {
            props.onChangeResponseIndex(i);
          }}
        />
      )}

      {props.question?.responseType === "FreeText" && (
        <BorderedSection
        title="YOUR ANSWER"
          sx={{
            width: "100%",
            p: "3px",
            backgroundColor: "rgba(0, 0, 0, 0.7);"
          }}>
          <TextField
            sx={{
              width: "100%",
              background: "none",
              color: "#e5e5e5",
              padding: "6px",
              border: "none",
            }}
            multiline
            minRows={1}
            maxRows={6}
            placeholder={props.question?.placeholder ?? "Type an answer"}
            value={props.question.responseValue}
            onChange={onChangeFreetextValue}
          />
        </BorderedSection>
      )}
    </Stack>
  );
}

export default WorkingQuestionSpace;

import React, { useEffect, useMemo, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { BLANK_CLOCK_PROPS, ITitlebarContext, TitlebarContext, TBClockProps } from "components/play/TitlebarContext";
import { formatSeconds } from "utils/utils-time";
import { Box, PaletteOptions, Stack, ThemeProvider, Typography, createTheme, responsiveFontSizes, useTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { TTTheme } from "@mui/material/styles/createPalette";
export interface layoutGameRootProps {}

function LayoutGameRoot(props: layoutGameRootProps) {
  // We store the state for the titlebar
  const [clockProps, setClockProps] = useState<TBClockProps>(BLANK_CLOCK_PROPS);
  const [tbTitle, setTBTitle] = useState<string>("");
  const [clockText, setClockText] = useState<string>("");
  const [bgurl, setBGUrl] = useState<string>("");
  const tbAccesssors = useMemo<ITitlebarContext>(() => ({ clockProps: clockProps, setClockProps: setClockProps, title: tbTitle, setTitle: setTBTitle, bgurl: bgurl, setBGUrl: setBGUrl }), [clockProps, tbTitle, bgurl]);

  // Ticking Clock
  useEffect(() => {
    const handle = setInterval(() => {
      if (clockProps.isClockActive) {
        const msSinceUpdate = Date.now() - clockProps.clientTimestamp!;
        const secSinceUpdate = Math.trunc(msSinceUpdate / 1000);
        const newValue = clockProps.elapsedSecs + secSinceUpdate;
        setClockText(formatSeconds(newValue)); // stored in an independent state var to avoid child re-renders on every tick
      }
    }, 1000);
    // useEffect can return a cleanup function to be called on unmount
    return () => {
      clearInterval(handle);
    }; //  Clear the timer on unmount
  }, [clockProps]);

  const theme = useTheme<TTTheme>();

  const whiteColor = theme.palette.common.white;

  // https://mui.com/material-ui/customization/theme-components/#theme-default-props
  // https://mui.com/material-ui/customization/palette/#adding-new-colors
  const myPalette: PaletteOptions = {
    primary: {
      main: "#DE191E"
    },
    secondary: {
      main: "#145899"
    },
    mode: "dark"
  };

  // https://mui.com/material-ui/customization/typography/
  const myType: TypographyOptions = {
    fontFamily: "Quicksand, sans-serif",
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightLight: 200,

    h1: {
      fontSize: "2.1rem",
      fontWeight: 700,
      lineHeight: 1.35
    },
    h2: {
      fontSize: "1.8rem",
      fontWeight: 700,
      lineHeight: 1.3
    },
    h3: {
      fontSize: "1.6rem",
      fontWeight: 700,
      lineHeight: 1.2
    },
    h4: {
      fontSize: "1.4rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h5: {
      fontSize: "1.2rem",
      fontWeight: 600,
      lineHeight: 1.2
    },
    h6: {
      fontSize: "1.1rem",
      fontWeight: 500,
      lineHeight: 1.2
    },
    body1: {
      fontSize: "1.1rem",
      fontWeight: 400,
      lineHeight: 1.3
    },
    body2: {
      fontSize: "1.0rem",
      fontWeight: 400,
      lineHeight: 1.25
    },
    caption: {
      fontSize: "0.9rem",
      fontWeight: 500,
      lineHeight: 1.25
    },
    button: {
      fontSize: "0.9rem",
      fontWeight: 700
    }
  };

  const gameTheme = responsiveFontSizes(
    createTheme({
      palette: myPalette,
      typography: myType
      // components: {
      //   MuiAppBar: {
      //     defaultProps: {
      //     }
      //   },
      // },
    })
  );

  return (
    // WHOLE PAGE BACKGROUND - GREY SHADOW (COULD PIMP UP)
    <Stack direction="column" width="100dvw" height="100dvh" justifyContent="center" sx={{ backgroundColor: "#555555" }}>
      {/* // GAME STACK  */}
      <Stack
        direction="column"
        width="100%"
        height="100%"
        maxWidth="500px"
        maxHeight="1000px"
        ml="auto"
        mr="auto"
        sx={{
          background: `no-repeat center url('${bgurl}')`,
          backgroundSize: "cover",
          boxShadow: "0 10px 10px rgb(0 0 0 / 0.2)"
        }}>
        {/* TITLE BAR */}
        <Stack direction="row" p="2px 10px 2px 10px" height="20px" fontSize="12px" fontWeight="bold" sx={{ backgroundColor: "#eeeeee", color: "#555555" }}>
          <Box component={Link} to="/app/tours" flexGrow={1} color="#555555" sx={{ textDecoration: "none" }}>
            ■&nbsp;{tbTitle}
          </Box>

          <Box fontFamily="'Courier New', Courier, monospace" color="#555555" sx={{ textDecoration: "none" }}>
            &nbsp;■&nbsp;
            {clockProps.isClockActive ? clockText : "--:--:--"}
            &nbsp; ■
          </Box>
        </Stack>

        <ThemeProvider theme={gameTheme}>
          <TitlebarContext.Provider value={tbAccesssors}>
            <Outlet />
          </TitlebarContext.Provider>
        </ThemeProvider>
      </Stack>
    </Stack>
  );
}

export default LayoutGameRoot;

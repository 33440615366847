import { LatLngLiteral } from "leaflet";

  // Same as a string literal, but can be enumerated
export const irlItemTypeValues = ['Unknown', 
'Inscription', 
'Landmark', 
'CulturalBuilding', 
'Pub', 
'ReligiousBuilding',
'Shopping',
'CulturalConsumer',
'TransportStop',
'StreetName'
] as const;
export type irlItemTypes = typeof irlItemTypeValues[number];

export const defaultPoiFetchTypes:irlItemTypes[] = ["CulturalBuilding","Inscription","Landmark","ReligiousBuilding","TransportStop"];

export default interface irlItem {
    id: string;

    itemType: irlItemTypes;

    dataSource: string;
    shortDescription: string;
    longDescription: string;

    location: LatLngLiteral;

    // Ephemeral (not in DB, so not for plaques for example)
    rating?: number;
    numberOfReviews?: number;
    reviews: string[];

    webURL?: string;
  }

import { Box, Button, Container, Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import Diversity3Icon from "@mui/icons-material/Diversity3";
import ExtensionIcon from "@mui/icons-material/Extension";
import FestivalIcon from "@mui/icons-material/Festival";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import CaptionedIcon from "components/chrome/CaptionedIcon";

import { ReactComponent as AdultChildIcon } from "../../assets/escalator_warning_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as FollowSignsIcon } from "../../assets/follow_the_signs_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as MobileFriendlyIcon } from "../../assets/mobile_friendly_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as PiggyBankIcon } from "../../assets/savings_FILL0_wght400_GRAD0_opsz24.svg";

import picFamPhone from "../../assets/photo/fam-phone-canal-2.png";
import picBlurPark from "../../assets/photo/blurred_city_bg.jpg";
import picBlurredSkyline from "../../assets/photo/blur_city_skyline.jpg";
import picFamPhone2 from "../../assets/photo/fam-phone-2.jpg";

import logoShortBigOnDark from "../../assets/logo/tt7-logo-short-big-on-dark@2x.png";

import ToursBrowser from "./ToursBrowser";
import { TTTheme } from "@mui/material/styles/createPalette";

export default function LandingApp() {
  const theme = useTheme<TTTheme>();

  return (
    <>
      <Stack direction="column" spacing={0}>
        {/* HERO ROW */}
        <Box
          sx={{
            backgroundImage: `url(${picFamPhone})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "start",
            textAlign: "left" // Align text to the left within the Hero Box
          }}>
          {/* HERO TEXT */}
          <Box
            sx={{
              background: "rgba(0, 0, 0, 0.5)", // Black with 50% opacity
              padding: { xs: "50px 30px 50px 20px", sm: "100px 50px 100px 50px" },
              width: { xs: "85%", sm: "65%" }, // Restricting width to left-hand 50%
              maxWidth: "600px" // Adding a maxWidth to prevent the box from becoming too wide
            }}>
            <Stack direction="column">
              <Box component="img" src={logoShortBigOnDark} width={{ xs: "300px", sm: "340px" }} alt="TT Logo" />
              <Typography variant="h5" fontWeight={700} color="#eeeeee" sx={{ mt: "10px" }}>
                <p>Treasure Tours are fun, self-guided walking tours and treasure hunts for curious people.</p>
                <p>Explore cities, parks & landmarks with just your smartphone and your imagination.</p>
                <p>Follow the clues, discover amazing sights and enjoy exploring in a whole new way.</p>
              </Typography>
              <Button href="/app/tours" variant="contained" size="small" sx={{ p: "10px" }}>
                Nearby Tours
              </Button>
            </Stack>
          </Box>
        </Box>

        {/* SECTION 2 - FEATURES */}
        <Grid container m={0} p="30px" spacing={2} sx={{ backgroundColor: "rgb(41,110,176)", background: "radial-gradient(circle, rgba(41,110,176,1) 0%, rgba(20,88,153,1) 100%)" }}>
          <Grid xs={6} sm={3} justifyContent="center" textAlign="center">
            <CaptionedIcon svgComponent={FollowSignsIcon} caption="Fun and interactive exploring" />
          </Grid>

          <Grid xs={6} sm={3} justifyContent="center" textAlign="center">
            <CaptionedIcon svgComponent={AdultChildIcon} caption="Suitable for everyone" />
          </Grid>

          <Grid xs={12} display={{ xs: "block", sm: "none" }} height="20px"></Grid>

          <Grid xs={6} sm={3} justifyContent="center" textAlign="center">
            <CaptionedIcon svgComponent={PiggyBankIcon} caption="Always Affordable" />
          </Grid>

          <Grid xs={6} sm={3} justifyContent="center" textAlign="center">
            <CaptionedIcon svgComponent={MobileFriendlyIcon} caption="No paper or pen required" />
          </Grid>
        </Grid>

        {/* SECTION 3 - SCREENSHOTS */}
        <Grid container m={0} p="15px 20px 0px 20px" spacing={0} justifyContent="space-evenly" sx={{ background: `no-repeat center url(${picBlurPark})`, backgroundPosition: "center", backgroundSize: "cover" }}>
          <Grid xs={12} sm={6} textAlign="center">
            <Box component="img" height="294px" src="/landing-phoneshot-L.png" alt="Screenshot on a phone of treasure tours game, showing a map" />
          </Grid>

          {/* <Grid xs={12} sm={0} textAlign="center" sx={{ width: "100%", height: "2px", backgroundColor: "#555555", display: { xs: "block", sm: "none" } }}></Grid> */}

          <Grid xs={0} sm={6} display={{ xs: "none", sm: "block" }} textAlign="center">
            <Box component="img" height="294px" src="/landing-phoneshot-R.png" alt="Screenshot on a phone of treasure tours game, showing a quiz question with multi-choice answers" />
          </Grid>
        </Grid>
        {/* END SECTION 3 */}

        {/* SECTION 4 - PIC & QUOTE */}
        <Grid container>
          <Grid xs={12} sm={6}>
            <Box component="img" src={picFamPhone2} height="500px" width="100%" sx={{ objectFit: "cover", objectPosition: "center" }} />
          </Grid>

          <Grid xs={12} sm={6}>
            <Stack
              direction="column"
              m={0}
              p="30px"
              height={{ xs: "340px", sm: "500px" }}
              textAlign="center"
              justifyContent="center"
              fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
              gap="20px"
              color={theme.palette.common.white}
              sx={{ backgroundColor: "rgb(41,110,176)", background: "radial-gradient(circle, rgba(41,110,176,1) 0%, rgba(20,88,153,1) 100%)" }}>
              <Typography variant="inherit" color="inherit">
                — A unique way to explore a new city, or see a familiar place in a whole new light.
              </Typography>

              <Typography variant="inherit" color="inherit">
                — Fun, engaging questions and clear, walking instructions and maps.
              </Typography>

              <Typography variant="inherit" color="inherit">
                — Self-guided, with fascinating facts and top local tips.
              </Typography>

              <Typography variant="inherit" color="inherit">
                — Go at your own pace or beat the clock: something for everyone.
              </Typography>

              <Stack direction="row" pt="20px" pb="20px" ml="auto" mr="auto">
                <Button href="/app/tours" variant="contained" size={"small"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, p: { xs: "10px", sm: "25px" } }}>
                  Discover Tours
                </Button>

                <Button href="/app/home/new/tour/0" variant="contained" size="small" sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, ml: "16px", p: { xs: "10px", sm: "25px" } }}>
                  Create your own
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        {/* FEATURED TOURS */}
        <Stack
          sx={{
            backgroundImage: `url(${picBlurredSkyline})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "start",
            textAlign: "left" // Align text to the left within the Hero Box
          }}>
          <Typography variant="h1" color={theme.palette.primary.main} fontSize="2.6em" textAlign="center" pt="20px" sx={{ textShadow: "0 1px 1px rgb(0 0 0 / 0.3)" }}>
            Featured Tours
          </Typography>
          <ToursBrowser sx={{ p: "20px", width: "100%" }} />
        </Stack>
      </Stack>
      {/* END MAIN PAGE STACK */}

      <div className="section-actions"></div>
    </>
  );
}

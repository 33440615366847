export const AWS_AUTH_CONFIG = {
  Auth: {
    // Amazon Cognito Region
    region: 'eu-west-2',
    // Amazon Cognito User Pool ID
    userPoolId: 'eu-west-2_BvmjGiZ5I',

    // Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: '50cdk5fthq33frui0mmc9buuge',

    // Hosted UI configuration
    oauth: {
      domain: 'treasuretours.auth.eu-west-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
       redirectSignIn: 'https://treasuretours.org/postlogin', // must match what's configured in Cognito
       redirectSignOut: 'https://treasuretours.org/app', // must match what's configured in Cognito
       clientId: '50cdk5fthq33frui0mmc9buuge',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
}
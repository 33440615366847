
// Utils to help prevent iOS autozoom.  See
// https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone?answertab=modifieddesc#tab-top

const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    if (content != null) {
      // eslint-disable-next-line no-useless-escape
      let re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
          content = content.replace(re, 'maximum-scale=1.0');
      } else {
          content = [content, 'maximum-scale=1.0'].join(', ')
      }

      el.setAttribute('content', content);
  }
  }
};

export const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
export const checkIsIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent);

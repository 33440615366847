import tour, { BLANK_TOUR } from "./tour";
import progress from "./progress";
import game from "./game";

interface liveHuntStatus {
  huntSummary: tour;
  updatedTime: Date;
  progresses: progress[];
  games: game[];
}

export const BLANK_LIVE_HUNT_STATUS = {
  huntSummary: BLANK_TOUR,
  updatedTime: new Date(),
  progresses: [],
  games: []
};

export default liveHuntStatus;

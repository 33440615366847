import Game from "./components/play/Game";
import Dashboard from "./components/edit/Dashboard";
import TourEditor from "./components/edit/TourEditor";
import NoPage from "./components/NoPage";


import HuntPicker from "components/monitor/HuntPicker";
import HuntMonitor from "components/monitor/HuntMonitor";

import HuntResponsesList from "components/monitor/HuntResponsesList";
import HuntResponsesGrid from "components/monitor/HuntResponsesGrid";

import {  Outlet, Route, ScrollRestoration, createBrowserRouter, createRoutesFromElements, useNavigate } from "react-router-dom";
import RequireAuth from "components/auth/RequireAuth";
import LayoutHomeRoot from "components/home/LayoutHomeRoot";
import LayoutGameRoot from "components/play/LayoutGameRoot";
import LayoutAppRoot from "components/app/LayoutAppRoot";
import LandingApp from "components/app/LandingApp";
import AccountProfile from "components/app/AccountProfile";
import { useEffect, useState } from "react";
import CreditsPage from "components/pages/CreditsPage";
import CreateTourWizard from "components/edit/CreateTourWizard";
import NewGame from "components/play/NewGame";
import DiscoverToursPage from "components/pages/DiscoverToursPage";
import PrivacyPage from "components/pages/PrivacyPage";
import MediaLibraryPage from "components/pages/MediaLibraryPage";
import ContactPage from "components/pages/ContactPage";
import LicencesPage from "components/pages/LicencesPage";
import RequirePermission from "components/auth/RequirePermission";
import ThemeEditorPage from "components/edit/compose/ThemeEditorPage";
import CreateTourMonitor from "components/edit/CreateTourMonitor";
import TourPage from "components/pages/TourPage";
import TermsPage from "components/pages/TermsPage";
import TourPurchase from "components/play/TourPurchase";
import PostLogin from "components/app/PostLogin";
import TourReport from "components/edit/TourReport";



function RedirectTo(props:{url: string}) {
  const navigate = useNavigate();
  useEffect(() => {
      navigate(props.url, { replace: true });
  });
return (<></>);
}

export const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      
      <Route path="postlogin" element={<PostLogin />} />

      <Route index element={<RedirectTo url="/app"  />} />
      <Route path="foulds" element={<RedirectTo url="/app/tours/4" />} />

      <Route path="play" element={<RequireAuth><LayoutGameRoot/></RequireAuth>} >
        <Route index element={<NoPage />} />
        <Route path="tour/:tourId/newgame/:isPreview?/:startIndex?" element={<NewGame />} />
        <Route path="tour/:tourId/g/:gameId" element={<Game />} />
      </Route>

      <Route path="app" element={<><ScrollRestoration /><LayoutAppRoot/></>} >

        <Route index element={<LandingApp />} />
        <Route path="profile" element={<RequireAuth><AccountProfile /></RequireAuth>} />

        <Route path="tours" element={<DiscoverToursPage />} />
        <Route path="tours/:tourId/purchase/:purchaseFlowStage" element={<RequireAuth><TourPurchase /></RequireAuth>} />
        <Route path="tours/:tourId" element={<TourPage />} />
        

        <Route path="credits" element={<CreditsPage />} />
        <Route path="licences" element={<LicencesPage />} />
        <Route path="privacy" element={<PrivacyPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="terms" element={<TermsPage />} />

        <Route path="home" element={<RequireAuth><LayoutHomeRoot/></RequireAuth>} >
          <Route index element={< RedirectTo url="/app/home/edit"/>} />

          <Route path="medialibrary" element={<MediaLibraryPage />} />
          <Route path="themeeditor" element={<RequirePermission name="ComposeAI"><ThemeEditorPage /></RequirePermission> } />
          <Route path="new/tour/:tourId" element={<CreateTourWizard />} />
          <Route path="new/tour/:tourId/await" element={<CreateTourMonitor />} />
          <Route path="edit" element={<Dashboard />} />
          <Route path="edit/tour/:tourId" element={<TourEditor />} />

          <Route path="report/tour/:tourId" element={<TourReport />} />

          <Route path="monitor" element={<HuntPicker />} />
          <Route path="monitor/tour/:tourId" element={<HuntMonitor />} />
          <Route path="monitor/responseslist/tour/:tourId" element={<HuntResponsesList />} />
          <Route path="monitor/responsesgrid/tour/:tourId" element={<HuntResponsesGrid />} />

        </Route>
      </Route>

      <Route path="*" element={<NoPage />} />
    </Route>
  )
);


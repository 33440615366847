import React, { useState } from "react";

import { Authenticator } from "@aws-amplify/ui-react";
import { ConfirmProvider } from "material-ui-confirm";
import { Snackbar, Alert } from "@mui/material";

import { SBAlert, SBAlertContext } from "components/edit/SBAlertContext";

import { appRouter } from "AppRouter";
import { RouterProvider } from "react-router-dom";

function App() {
  const [alert, setAlert] = useState(new SBAlert({ open: false }));

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setAlert(new SBAlert({ severity: alert.severity, open: false }));
  };

  return (
    <ConfirmProvider>
      <SBAlertContext.Provider value={{ alert: alert, setAlert: setAlert }}>
        <Authenticator.Provider>
          {/* THE MAIN APPLICATION */}
          <RouterProvider router={appRouter} />
        </Authenticator.Provider>
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alert.open} autoHideDuration={alert.autoHideDuration} onClose={handleClose}>
          <Alert onClick={handleClose} severity={alert.severity}>
            {alert.message || ""}
          </Alert>
        </Snackbar>
      </SBAlertContext.Provider>
    </ConfirmProvider>
  );
}

export default App;

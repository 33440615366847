import ReactDOMServer from "react-dom/server";

import { DivIcon } from "leaflet";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MuseumIcon from '@mui/icons-material/Museum';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import BungalowIcon from '@mui/icons-material/Bungalow';
import WineBarIcon from '@mui/icons-material/WineBar';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SignpostIcon from '@mui/icons-material/Signpost';
import CurtainsClosedIcon from '@mui/icons-material/CurtainsClosed';

import "react-leaflet-fullscreen/styles.css";
import "./IRLDivIcon.css";
import irlItem, { irlItemTypes } from "data/model/geo/irlItem";


export const getIRLDivIcon = (i:irlItem) => {

  return new DivIcon({
        className: "some-class-required",
        html: ReactDOMServer.renderToString(
          <div className="funky-icon">
            {iconForIRLItemType(i.itemType)}
          </div>
        ),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
        popupAnchor: [0, -15] // Set the anchor point for popups
      });
      
};

export const iconForIRLItemType = (i:irlItemTypes) => {
  switch (i) {

    case "Inscription":
      return (<PrivacyTipIcon fontSize="inherit" color="inherit" />);

    case "Landmark":
      return (<AccountBalanceIcon fontSize="inherit" color="inherit" />);

    case "CulturalBuilding":
      return (<MuseumIcon fontSize="inherit" color="inherit" />);

    case "Pub":
      return (<WineBarIcon fontSize="inherit" color="inherit" />);

    case "ReligiousBuilding":
      return (<CurtainsClosedIcon fontSize="inherit" color="inherit" />);
      
    case "Shopping":
      return (<ShoppingBasketIcon fontSize="inherit" color="inherit" />);

    case "CulturalConsumer":
      return (<ShoppingBagIcon fontSize="inherit" color="inherit" />);

    case "TransportStop":
      return (<DirectionsTransitIcon fontSize="inherit" color="inherit" />);
      
    case "StreetName":
      return (<SignpostIcon fontSize="inherit" color="inherit" />);

    case "Unknown":
    default:
      return (<MuseumIcon fontSize="inherit" color="inherit" />);
  }
}

import { Box, Button, Typography } from "@mui/material";
import { PageBox } from "components/chrome/PageBox";
import PageTitle from "components/chrome/PageTitle";

export const SITE_TITLE = "Treasure Tours";

export default function LicencesPage() {
  return (
    <PageBox>
      <PageTitle>Third Party Software</PageTitle>

      <Box>
        <Typography variant="body2" sx={{ mb: "30px" }}>
          <p>This web application makes use of various third party software libraries under licence.</p>
          <p>A list of the third party software used is downloadable below in CSV format, including details of applicable licences and authors.</p>
        </Typography>
        <Button sx={{ ml: "auto", mr: "auto" }} href="/licences.csv">
          View details
        </Button>
      </Box>
    </PageBox>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tour from "../../data/model/tour";

import { useConfirm } from "material-ui-confirm";

import List from "@mui/material/List";

import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import useAPI from "services/useHunterApi";
import NoDataPane from "components/chrome/NoDataPane";
import usePermissions from "hooks/usePermissions";
import { TTTheme } from "@mui/material/styles/createPalette";
import TourListItem from "./TourListItem";

interface TourCollectionEditorProps {}


function TourCollectionEditor(props: TourCollectionEditorProps) {
  const { hasPermissionFor } = usePermissions();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [tours, setTours] = useState<tour[]>();

  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();

  const theme = useTheme<TTTheme>();
  const mqSMOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  // On startup, Retrieve tours belonging to logged in user only
  useEffect(() => {
    callAPI<null, tour[]>(`/api/tour/user/me`, "GET")
      .then((data) => {
        setTours(data);
      })
      .catch((error) => {});
  }, []);


  const handleClickDeleteTourButton = (event: React.MouseEvent<HTMLButtonElement>, index: number, tourId: number) => {
    confirm({
      title: "Delete this tour",
      description: "This will permanently delete this tour. Are you sure?",
      confirmationText: "Delete"
    })
      .then(() => {
        callAPINoResponseBody<void>(`/api/tour/${tourId}`, "DELETE")
          .then(() => {
            const fewerTours: tour[] = Object.assign([], tours);
            fewerTours.splice(index, 1);
            setTours(fewerTours);
          })
          .catch((error) => {});
      })
      .catch(() => {
        /* User cancelled the deletion */
      });
  };

  const handleClickDuplicateTourButton = (event: React.MouseEvent<HTMLButtonElement>, index: number, tourId: number) => {
    confirm({
      title: "Delete this tour",
      description: "This will duplicate this entire tour, including the questions. Are you sure?",
      confirmationText: "Duplicate"
    })
      .then(() => {
        callAPI<void, tour>(`/api/tour/${tourId}/copy/duplicatequestions/true`, "GET")
          .then((t) => {
            const moreTours: tour[] = Object.assign([], tours);
            moreTours.push(t);
            setTours(moreTours);
          })
          .catch((error) => {});
      })
      .catch(() => {
        /* User cancelled the deletion */
      });
  };

  function AddTourButton() {
    return (
      <Button fullWidth variant="contained" 
      href='/app/home/new/tour/0'
      endIcon={<AddIcon />}>
      Create New Tour
    </Button>
    );
  }  


  return isLoading ? (
    <Stack direction="column" gap="10px" width="100%" sx={{ p: "25px" }}>
      <Skeleton key="s1" variant="rectangular" animation="wave" width="100%" height={36}></Skeleton>
      <Skeleton key="s2" variant="rectangular" animation="wave" width="100%" height={36}></Skeleton>
      <Skeleton key="s3" variant="rectangular" animation="wave" width="100%" height={36}></Skeleton>
    </Stack>
  ) : tours && tours!.length > 0 ? (
    <>
      <List dense={mqSMOrDown} sx={{ width: "100%" }}>
        {tours?.map((t, index) => (
          <TourListItem key={`tli-${t.id}`} tour={t} hideChannelName href={`/app/home/edit/tour/${t.id}`} >
            <Button href={`/app/home/edit/tour/${t.id}`} color='secondary' size="small" variant="outlined">
              Edit
            </Button>
            <IconButton aria-label="Duplicate" onClick={(e) => handleClickDuplicateTourButton(e, index, t.id)}>
              <ContentCopyIcon color="secondary" />
            </IconButton>
            <IconButton aria-label="Delete" onClick={(e) => handleClickDeleteTourButton(e, index, t.id)}>
              <DeleteForeverIcon color="secondary" />
            </IconButton>
          </TourListItem>
        ))}
      </List>
      <AddTourButton />
    </>
  ) : (
    <>
      <NoDataPane icon={<BrowserNotSupportedIcon />} sx={{height: "200px"}} title="No tours" subtitle="Create your own hunts for others to play!" />
      <AddTourButton />
    </>
  );
}

export default TourCollectionEditor;

import React, { ChangeEvent, EventHandler, useContext, useState } from "react";
import { AppBar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Stack, TextField, Typography } from "@mui/material";

import mediaItem from "data/model/mediaItem";

import useAPI from "services/useHunterApi";
import { SBAlertContext, SBAlert } from "./SBAlertContext";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import { useConfirm } from "material-ui-confirm";
import CollectionChipEditor from "./CollectionChipEditor";

interface MediaEditorDialogProps {
  open: boolean;
  media: mediaItem;
  onUpdateMedia: (media: mediaItem) => void;
  onDeleteMedia: (deletedMediaID: string) => void;
  onRequestClose: () => void;
}

export default function MediaEditorDialog(props: MediaEditorDialogProps) {
  const [editingMedia, setEditingMedia] = useState<mediaItem>(props.media);

  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const confirm = useConfirm();

  const { alert, setAlert } = useContext(SBAlertContext);

  const handleChangeFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
    const newMedia: mediaItem = {
      ...editingMedia,
      isDirty: true,
      [e.target.name]: e.target.value // [..] denotes use the 'name' JSX element value
    };

    setEditingMedia(newMedia);
  };

  const handleTagsUpdated = (newTags: string[]) => {
    const newMedia = Object.assign({}, editingMedia);
    newMedia.tags = newTags;
    newMedia.isDirty = true;

    setEditingMedia(newMedia);
  };

  const handleDeleteMedia = () => {
    if (editingMedia) {
      confirm({
        title: "Delete this media item",
        description: "This will permanently delete this media item. Are you sure?",
        confirmationText: "Delete"
      })
        .then(() => {
          setIsDeleting(true);
          const deletionID = editingMedia.id;
          callAPINoResponseBody<null>(`/api/media/${deletionID}`, "DELETE")
            .then(() => {
              setIsDeleting(false);
              props.onDeleteMedia(deletionID);
            })
            .catch(() => {
              setIsSaving(false);
            });
        })
        .catch(() => {
          /* User cancelled the deletion */
        });
    }
  };

  const handleOnSave = () => {
    if (editingMedia) {
      // push selected item to API

      setIsSaving(true);
      callAPINoResponseBody<mediaItem>(`/api/media`, "PUT", editingMedia)
        .then(() => {
          setIsSaving(false);
          setAlert(
            new SBAlert({
              message: "Saved media successfully.",
              logMessage: `Saved media item ${editingMedia.id ?? 0} OK`,
              severity: "success",
              autoHideDuration: 1800
            })
          );

          props.onUpdateMedia({
            ...editingMedia,
            isDirty: false
          } as mediaItem);
        })
        .catch(() => {
          setIsSaving(false);
        });
    }
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} open={props.open}>
      <AppBar position="sticky">
        <DialogTitle>
          <Stack direction="row" gap={{ xs: "4px", md: "20px" }} alignItems="center" justifyContent="start" sx={{ pl: "20px", pr: "20px", width: "100%", height: "30px" }}>
            <Typography variant="h4">Image Details</Typography>

            {/* <IconButton
            color="inherit"
              sx={{ml: "auto"}} // Pushes it to the right
              onClick={handleClose}>
              <CloseIcon color="inherit" />
            </IconButton>
              */}
          </Stack>
        </DialogTitle>
      </AppBar>

      {editingMedia ? (
        <>
          <DialogContent>
            <FormControl fullWidth disabled={!editingMedia.canEditMetadata}>
              <Stack direction="column" gap="20px" flexGrow="1" minHeight="600px" paddingTop="10px">
                <TextField disabled={!editingMedia.canEditMetadata} label="Title" helperText="Your title for this image. Not published." name="title" value={editingMedia.title ?? ""} onChange={handleChangeFieldValue} />
                <TextField
                  multiline
                  disabled={!editingMedia.canEditMetadata}
                  rows="3"
                  label="Description"
                  name="description"
                  helperText="Description of the image. Provided to accessibility users."
                  value={editingMedia.description ?? ""}
                  onChange={handleChangeFieldValue}
                />
                <TextField disabled label="Original file name" name="originalFileName" value={editingMedia.originalFileName ?? ""} />
                <CollectionChipEditor disabled={!editingMedia.canEditMetadata} collection={editingMedia.tags ?? []} maxCollectionLength={15} itemLabel="Tag" onUpdateCollection={handleTagsUpdated} />
                {!editingMedia.canEditMetadata && (
                  <Typography variant="body2" color="InfoText">
                    <InfoIcon color="info" /> You can't edit this metadata as it was uploaded by somebody else.
                  </Typography>
                )}

                {editingMedia.canEditMetadata && (
                  <Button
                    variant="text"
                    size="small"
                    sx={{
                      borderColor: "#dd0000"
                    }}
                    onClick={() => {
                      handleDeleteMedia();
                    }}>
                    <Stack direction="row" alignItems="center" gap="12px" color="red">
                      Delete forever
                      <Box sx={{ width: "20px", height: "100%", display: "flex", alignItems: "center" }}>{isDeleting ? <CircularProgress color="inherit" size="20px" /> : <DeleteForeverIcon color="inherit" />}</Box>
                    </Stack>
                  </Button>
                )}
              </Stack>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                props.onRequestClose();
              }}>
              Cancel
            </Button>
            <Button
              disabled={!editingMedia.isDirty}
              variant="contained"
              onClick={() => {
                handleOnSave();
              }}>
              <Stack direction="row" alignItems="center" gap="12px">
                Save
                <Box sx={{ width: "20px", height: "100%", display: "flex", alignItems: "center" }}>{isSaving ? <CircularProgress color="inherit" size="20px" /> : <SaveIcon color="inherit" />}</Box>
              </Stack>
            </Button>
          </DialogActions>
        </>
      ) : (
        <DialogContent>
          <Stack height="100%" direction="column" alignItems="center" justifyContent="space-evenly" padding="30px">
            <Typography variant="body2">No media selected</Typography>
          </Stack>
        </DialogContent>
      )}
    </Dialog>
  );
}

import { useState } from "react";
import { Button, IconButton, Tooltip, Typography, Dialog, DialogContent, DialogTitle, Stack, DialogActions, AppBar } from "@mui/material";

import RoomIcon from "@mui/icons-material/Room";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { QImageBackdrop, QImageBox, QImageButtonGroup, QImageControls, QImageTopRightControls } from "./QImageControls";
import MapPicker from "components/geo/MapPicker";
import { waypoint } from "data/model/waypoint";
import { LatLngLiteral } from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MapController from "components/geo/MapController";

interface WaypointProps {
  fullScreen?: boolean;
  collapsedHeight?: string | number;
  expandedHeight?: string | number;
  hideStylingControls?: boolean;
  waypoint?: waypoint;
  showMap?: boolean;
  defaultCenter?: LatLngLiteral;
  onUpdateStyledWaypoint: (shouldShowMap: boolean|undefined, waypoint: waypoint|undefined) => void;
  onRequestDeleteWaypoint: () => Promise<boolean>;
}

export default function Waypoint(props: WaypointProps) {
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);

  const mlDialogRequestCancel = () => {
    setIsPickerOpen(false);
  };

  const handleClickDeleteImage = () => {
    if (props.onRequestDeleteWaypoint) {
      props.onRequestDeleteWaypoint().then((shouldDelete: boolean) => {
        // If user clicks 'Delete'
        if (shouldDelete) {
          props.onUpdateStyledWaypoint(undefined, undefined);
        }
      });
    }
  };

  const handleMapPickerUpdatePosition = (newPosition: LatLngLiteral) => {

    const isNewWaypoint = props.waypoint === undefined;

    const newWaypoint: waypoint = {
      name: props.waypoint?.name,
      location: newPosition
    };

    // If we've just added a waypoint then defalut to showing it on the map
    props.onUpdateStyledWaypoint(props.showMap ?? isNewWaypoint, newWaypoint);
  };

  const myHeight = props.waypoint ? props.expandedHeight ?? "100px" : props.collapsedHeight ?? "60px";

  const showMapToPlayers = props.showMap !== undefined && props.showMap === true;

  return (
    <>
      <QImageBox sx={{ height: myHeight, borderBottom: "1px solid gray" }}>
        {props.waypoint?.location && (
          <MapContainer
            center={props.waypoint?.location} // no fallback required, location is non-null here
            zoom={15}
            zoomControl={false}
            dragging={false}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 0, // behind controls
              height: "100%"
            }}
            scrollWheelZoom={true}>
            <MapController
              center={props.waypoint?.location} // no fallback required, location is non-null here
            />
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={props.waypoint.location} />
          </MapContainer>
        )}

        <QImageBackdrop className="MuiImageBackdrop-root" />

        {props.waypoint && (
          <QImageTopRightControls>
            <IconButton color="inherit" onClick={handleClickDeleteImage}>
              <CloseIcon />
            </IconButton>
          </QImageTopRightControls>
        )}

        <QImageControls>
          <Button
            variant="outlined"
            startIcon={<RoomIcon />}
            color="inherit"
            onClick={() => {
              setIsPickerOpen(true);
            }}>
            {props.waypoint !== undefined ? "Edit waypoint" : "Add waypoint"}
          </Button>

          {props.waypoint && !props.hideStylingControls && (
            <QImageButtonGroup size="small">
              <Tooltip title={showMapToPlayers ? "Show map to players" : "Don't show map to players"}>
                <IconButton color="inherit" onClick={() => props.onUpdateStyledWaypoint(!showMapToPlayers, props.waypoint)}>
                  {showMapToPlayers ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Tooltip>
            </QImageButtonGroup>
          )}
        </QImageControls>
      </QImageBox>

      <Dialog fullWidth maxWidth={"md"} fullScreen={props.fullScreen} open={isPickerOpen}>
        <AppBar position="sticky">
          <DialogTitle>
            <Stack direction="row" gap={{ xs: "4px", md: "20px" }} alignItems="center" justifyContent="start" sx={{ width: "100%", height: "30px" }}>
              <Typography variant="h4">Edit waypoint</Typography>
              <IconButton
                color="inherit"
                sx={{ ml: "auto" }} // Pushes it to the right
                onClick={() => {
                  mlDialogRequestCancel();
                }}>
                <CloseIcon color="inherit" />
              </IconButton>
            </Stack>
          </DialogTitle>
        </AppBar>

        <DialogContent sx={props.fullScreen ? { m: "0", p: "0" } : { m: "0", p: "0", height: "600px" }}>
          <MapPicker position={props.waypoint?.location} defaultCenter={props.defaultCenter} height="100%" 
          onUpdatePosition={handleMapPickerUpdatePosition} />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "transparent" }}>
          <Button
            variant="contained"
            disabled={!props.waypoint}
            onClick={() => {
              mlDialogRequestCancel();
            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

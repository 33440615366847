import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Stack, useTheme } from "@mui/material";
import markerSource from "data/model/geo/markerSource";
import useAPI from "services/useHunterApi";
import ExplorerMap from "./ExplorerMap";
import StripePane from "components/chrome/StripePane";
import question, { QuestionResponseTypes } from "data/model/question";
import irlItem from "data/model/geo/irlItem";
import { useContext, useRef, useState } from "react";
import ProgressButton from "components/controls/ProgressButton";
import { SBAlert, SBAlertContext } from "./SBAlertContext";

import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import contentComposerOptions from "data/model/compose/contentComposerOptions";
import NSComposeContentRequestEditorDialog from "./compose/NSComposeContentRequestEditorDialog";
import { composeContentRequest, irlItemsJobDefinition, nsJobDefinition } from "data/model/compose/composeContentRequest";
import { DEFAULT_COMPOSE_OPTIONS, DEFAULT_NS_JOBDEF, DEFAULT_QC_JOBDEF } from "./compose/blankComposeOptions";
import { TTTheme } from "@mui/material/styles/createPalette";
import { LatLngExpression, LatLngLiteral } from "leaflet";
import RequirePermission from "components/auth/RequirePermission";

interface QuestionPlannerProps {
  tourId: number;
  questionsMarkersData: markerSource[];
  selectedQuestionLocation?: LatLngLiteral;
  onGeneratedNewQuestions: (newQuestions: question[]) => void;
  onTourRouteUpdated: (newDuration?:number, newDistance?:number) => void;
  onMovedQuestionMarker: (marker: markerSource, newPosition: LatLngLiteral) => void;
}

export default function QuestionPlanner(props: QuestionPlannerProps) {
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const theme = useTheme<TTTheme>();
  const [selectedIRLItems, setSelectedIRLItems] = useState<irlItem[]>([]);

  const [dropmarkerPosition, setDropmarkerPosition] = useState<LatLngLiteral | undefined>();
  
  const { alert, setAlert } = useContext(SBAlertContext);

  const [nOptionsDialogOpen, setNOptionsDialogOpen] = useState(false);
  const [nccRequest, setNccRequest] = useState<composeContentRequest<nsJobDefinition>>({
    jobDefinition: DEFAULT_NS_JOBDEF,
    options: DEFAULT_COMPOSE_OPTIONS,
    tourID: props.tourId
  });

  const [addBlankMenuOpen, setAddBlankMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setAddBlankMenuOpen(false);
  };

  const handleMenuListClick = (responseType: QuestionResponseTypes) => {
    setAddBlankMenuOpen(false);
    handleAddBlankQuestionForSelectedItems(responseType);
  };

  const handleAddBlankQuestionForSelectedItems = async (responseType: QuestionResponseTypes) => {
    // Get the location, either from first selected IRL item or selected user marker
    var newLocation = dropmarkerPosition ?? selectedIRLItems[0]?.location ?? undefined;

    if (newLocation !== undefined) {
      // Add a new question at the correct position
      try {
        var q = await callAPI<void, question>(`/api/question/create/tour/${props.tourId}`, "POST");
        q.contentFormatted = "Head towards this point.";
        q.waypoint = {
          location: newLocation as LatLngLiteral, 
          name: ""
        };
        q.responseType = responseType;
        q.showWaypointMap = true;

        // For IRLitems, record in the question the details of the point of information.
        if (selectedIRLItems[0]) {
          const i = selectedIRLItems[0];
          q.contentPrivateNotes = `Question generated at the location of ${i.shortDescription} - ${i.longDescription}`;
        }

        props.onGeneratedNewQuestions([q]);

        // Clear the user marker
        setDropmarkerPosition(undefined);

        // Save the new question content on server (NB not its position/order in the tour)
        await callAPINoResponseBody<question>(`/api/question`, "PUT", q);
      }
      catch { }
    }
  };


  const callComposeNarrative = () => {
    callAPI<composeContentRequest<nsJobDefinition>, question[]>(`/private/api/compose/narrative/questions`, "POST", nccRequest)
      .then((questions) => {
        props.onGeneratedNewQuestions(questions);
      })
      .catch(() => {
        console.log("Caught API failure composing question.");
        showComposeErrorAlert();
      });
  };

  const composeQuestionsForSelectedItems = () => {
    if (selectedIRLItems.length > 0) {
      callComposeQuestionsForItems(selectedIRLItems, DEFAULT_COMPOSE_OPTIONS);
    }
  };

  const callComposeQuestionsForItems = (items: irlItem[], options: contentComposerOptions) => {
    callAPI<composeContentRequest<irlItemsJobDefinition>, question[]>(`/private/api/compose/irlitems`, "POST", {
      options: options,
      jobDefinition: {
        ...DEFAULT_QC_JOBDEF,
        items: items
      },
      tourID: props.tourId
    })
      .then((questions) => {
        props.onGeneratedNewQuestions(questions);
      })
      .catch(() => {
        console.log("Caught API failure composing question.");
        showComposeErrorAlert();
      });
  };

  const showComposeErrorAlert = () => {
    setAlert(
      new SBAlert({
        message: "Couldn't auto-generate questions.",
        severity: "error",
        autoHideDuration: 3500
      })
    );
  };

  return (
    <>
      <StripePane title="Planning Assistant">
        <ExplorerMap
          questionMarkersData={props.questionsMarkersData}
          dropmarkerPosition={dropmarkerPosition}
          selectedQuestionLocation={props.selectedQuestionLocation}
          onDropmarkerPositionChange={(newPos: LatLngLiteral | undefined) => setDropmarkerPosition(newPos)}
          selectedIRLItems={selectedIRLItems}
          onSelectedIRLItems={(selectedItems: irlItem[]) => {
            setSelectedIRLItems(selectedItems);
          }}
          onTourRouteUpdated={props.onTourRouteUpdated}
          onMovedQuestionMarker={props.onMovedQuestionMarker}
        />

        <Stack direction="row" gap="6px" justifyContent="space-between"  sx={{ mt: "8px" }}>
          
            <Button
              ref={anchorRef}
              color="primary"
              variant="outlined"
              size="small"
              endIcon={<ArrowDropDownIcon />}
              sx={{ lineHeight: 0.95 }}
              disabled={isLoading || (selectedIRLItems.length < 1 && dropmarkerPosition === undefined)}
              onClick={() => {
                setAddBlankMenuOpen(true);
              }}>
              Add blank question here
            </Button>
            <Popper
              sx={{
                zIndex: 1
              }}
              open={addBlankMenuOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" dense>
                        <MenuItem onClick={() => handleMenuListClick("AcknowledgementOnly")}>
                          <InfoIcon color="secondary" />&nbsp;
                          Add Info/Instruction
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuListClick("MultiChoice")}>
                          <SpeakerNotesIcon color="secondary" />&nbsp;
                          Add Multi-choice
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuListClick("FreeText")}>
                          <QuestionAnswerIcon color="secondary" />&nbsp;
                          Add Free text
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <RequirePermission name="ComposeAI">
              <>
                <ProgressButton
                  sx={{ color: theme.palette.tertiary.main, borderColor: theme.palette.tertiary.main, lineHeight: 0.95 }}
                  variant="outlined"
                  size="small"
                  disabled={selectedIRLItems.length < 1}
                  waiting={isLoading}
                  onClick={() => {
                    composeQuestionsForSelectedItems();
                  }}
                  title="Generate Question from POI"
                />

                <ProgressButton
                  sx={{ color: theme.palette.tertiary.main, borderColor: theme.palette.tertiary.main, lineHeight: 0.95 }}
                  variant="outlined"
                  size="small"
                  waiting={isLoading}
                  onClick={() => {
                    setNOptionsDialogOpen(true);
                  }}
                  title="Generate Narrative"
                />
              </>
            </RequirePermission>
          
        </Stack>
      </StripePane>
      <NSComposeContentRequestEditorDialog
        open={nOptionsDialogOpen}
        request={nccRequest}
        onUpdate={(newRequest: composeContentRequest<nsJobDefinition>) => {
          setNccRequest(newRequest);
        }}
        onRequestCancel={() => {
          setNOptionsDialogOpen(false);
        }}
        onRequestProceed={() => {
          setNOptionsDialogOpen(false);
          callComposeNarrative();
        }}
      />
    </>
  );
}

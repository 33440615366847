import { ChangeEvent } from "react";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";

import { nsJobDefinition } from "data/model/compose/composeContentRequest";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ScenarioSelect from "./ScenarioSelect";
import scenario from "data/model/compose/scenario";

interface NSJobDefinitionProps {
  jobDefinition: nsJobDefinition;
  onUpdate: (value: nsJobDefinition) => void;
}

export default function NSJobDefinition(props: NSJobDefinitionProps) {
  const handleChangeFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeFieldNameAndValue(e.target.name, e.target.value);
  };

  const handleChangeFieldNameAndValue = (name: string, value: any) => {
    const newDefinition: nsJobDefinition = {
      ...props.jobDefinition,
      [name]: value
    };

    props.onUpdate(newDefinition);
  };

  return props.jobDefinition ? (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" gap="10px">
          <RecordVoiceOverIcon fontSize="small" />
          <Typography variant="caption">Narrative Generation</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Stack direction="column" gap="20px" flexGrow="1" paddingTop="10px">

            <ScenarioSelect
              scenarioId={props.jobDefinition.scenarioId}
              onUpdateScenarioId={ (value?: number) => {
              handleChangeFieldNameAndValue("scenarioId", value);
            }}
            />

              <FormControlLabel
              control={
                <Switch
                  checked={props.jobDefinition.shouldGenerateTourThumbnail}
                  onChange={(e) => { handleChangeFieldNameAndValue("shouldGenerateTourThumbnail", e.target.checked) } }
                />
              }
              label="Create thumbnail image"
              />

            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
              <InputLabel id="lab-quantity">Number of narrative update points</InputLabel>
              <Select
                value={props.jobDefinition.narrativePointQuantity}
                labelId="lab-quantityt"
                label="Number of narrative update points"
                name="narrativePointQuantity"
                fullWidth
                onChange={(e) => handleChangeFieldNameAndValue(e.target.name, e.target.value)}>
                <MenuItem value={"One"}>One (Start only)</MenuItem>
                <MenuItem value={"Three"}>Three (Start, Middle End)</MenuItem>
                <MenuItem value={"Five"}>Five (Start, Quarterly)</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={props.jobDefinition.shouldGenerateNarrativePointImages}
                  onChange={(e) => { handleChangeFieldNameAndValue("shouldGenerateNarrativePointImages", e.target.checked) } }
                />
              }
              label="Create narrative images"
              />

            <TextField
              fullWidth
              value={props.jobDefinition.placeName}
              label="Place name"
              helperText="Where is the player when they're completing the hunt"
              placeholder="e.g. the East quarter of Berlin"
              name="placeName"
              onChange={handleChangeFieldValue}
            />
          </Stack>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
}

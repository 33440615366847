import { Icon, Stack, SxProps, Typography } from "@mui/material";
import BrowserNotSupportedIcon from "@mui/icons-material/BrowserNotSupported";

export interface NoDataPaneProps {
  icon: any;
  title?: string;
  subtitle?: string;
  sx?: SxProps;
}

export default function NoDataPane(props: NoDataPaneProps) {
  return (
    <Stack direction="row" sx={props.sx} alignItems="center" justifyContent="center">
      <Stack direction="column" textAlign="center" margin="30px" padding="20px" >
        <Icon color="inherit" fontSize="large" sx={{ opacity: 0.5, ml: "auto", mr: "auto", height: "40px" }}>
          {props.icon ?? <BrowserNotSupportedIcon />}
        </Icon>
        <Typography variant="h6">{props.title ?? "No data"}</Typography>
        {props.subtitle && <Typography variant="body2">{props.subtitle}</Typography>}
      </Stack>
    </Stack>
  );
}

import ButtonGroup from "@mui/material/ButtonGroup";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";

import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { Menu, useMediaQuery, useTheme } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';


interface TourQuestionsEditorMenuProps {
  onClickQuestionButtonMove: (delta: number) => void;
  onClickQuestionButtonDelete: () => void;
  onClickQuestionButtonDuplicate: () => void;
  disableMoveUp?: boolean;
  disableMoveDown?: boolean;
}

export default function TourQuestionsEditorMenu(props: TourQuestionsEditorMenuProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();

  const theButtons = (
    <ButtonGroup sx={{ fontSize: { xs: "20px", sm: "24px" }, pl: "6px", pr: "6px" }}>
      {/* <IconButton size="small" color="primary" sx={{ fontSize: "inherit" }} disabled={props.disableMoveUp} aria-label="Move up" onClick={(e) => { props.onClickQuestionButtonMove(-1); setMenuAnchorEl(undefined);} }>
        <ArrowDropDownCircleIcon fontSize="inherit" sx={{ transform: "rotate(180deg)" }} />
      </IconButton>
      <IconButton size="small" color="primary" sx={{ fontSize: "inherit" }} disabled={props.disableMoveDown} aria-label="Move down" onClick={(e) => { props.onClickQuestionButtonMove(1); setMenuAnchorEl(undefined);} }>
        <ArrowDropDownCircleIcon fontSize="inherit" />
      </IconButton> */}
      <IconButton size="small" color="primary" sx={{ fontSize: "inherit" }} aria-label="Duplicate" onClick={(e) => {props.onClickQuestionButtonDuplicate(); setMenuAnchorEl(undefined); }}>
        <ContentCopyIcon fontSize="inherit" />
      </IconButton>
      <IconButton size="small" color="primary" sx={{ fontSize: "inherit" }} aria-label="Delete" onClick={(e) => {props.onClickQuestionButtonDelete(); setMenuAnchorEl(undefined); }}>
        <DeleteForeverIcon fontSize="inherit" />
      </IconButton>
    </ButtonGroup>
  );

  return <>
      <IconButton
        onClick={(e) => {
          setMenuAnchorEl(e.currentTarget);
        }}>
        <MoreVertIcon color="secondary" />
      </IconButton>

      <Menu anchorEl={menuAnchorEl} 
        open={menuAnchorEl !== undefined}
        onClose={() => setMenuAnchorEl(undefined)}
        >
        {theButtons}
      </Menu>
    </>
    ;
}

import { PropsWithChildren } from "react";
import usePermissions, { featureNames as permissionNames } from "hooks/usePermissions";

export interface RequireGroupProps {
  name: permissionNames;
}

export default function RequirePermission(props: PropsWithChildren<RequireGroupProps>) {
  const { hasPermissionFor } = usePermissions();
  return <>{hasPermissionFor(props.name) && props.children}</>;
}

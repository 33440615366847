import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import "./HintManagerDialog.css";
import workingQuestion from "../../data/model/workingQuestion";
import rehypeRaw from "rehype-raw";
import ResponseMultiChoice, { ResponseMultiChoiceProps } from "./ResponseMultiChoice";
import Box from "@mui/material/Box";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@mui/material";
import progress from "data/model/progress";
import styledMediaItem from "data/model/styledMediaItem";

interface HintManagerDialogProps {
  progress: progress;
  isOpen: boolean;
  isLoading: boolean;
  hintFormatted?: string;
  hintMedia?: styledMediaItem;
  onHintClosed: () => void;
  onRequestHint: () => void;
}

function HintManagerDialog(props: HintManagerDialogProps) {
  const totalHints = props.progress.totalHintsRemaining + props.progress.totalHintsUsed;

  const hasUnlimitedHints = props.progress.totalHintsRemaining === null;

  const hintContentAvailable = props.hintFormatted || props.hintMedia;

  const MediaBox = props.hintMedia ? (
    <Box
      component="img"
      width="100%"
      border="1px solid #e5e5e5"
      borderRadius="5px"
      sx={{
        aspectRatio: "12 / 7",
        objectFit: props?.hintMedia?.style?.fit?.toLowerCase() ?? ""
      }}
      alt={props.hintMedia.item.title}
      src={props.hintMedia.item.urlMedium}
    />
  ) : (
    <></>
  );

  return (
    <Dialog open={props.isOpen} fullWidth maxWidth="sm">
      {hintContentAvailable && (
        <>
          <DialogTitle>Hint</DialogTitle>
          <DialogContent dividers sx={{ fontSize: "3vw;" }}>
            <Typography>
              <ReactMarkdown>{props.hintFormatted ?? "--"}</ReactMarkdown>
              {MediaBox}
            </Typography>
          </DialogContent>
        </>
      )}

      {!hintContentAvailable && (
        <>
          <DialogTitle>Unlock hint</DialogTitle>
          <DialogContent dividers sx={{ fontSize: "3vw;" }}>
            <div className="hint-dialog-content">
              {(props.progress?.totalHintsRemaining === null || props.progress?.totalHintsRemaining === undefined) && <Typography>You have unlimited hints</Typography>}
              {(props.progress?.totalHintsRemaining !== null || props.progress?.totalHintsRemaining !== undefined) && (
                <Typography>
                  You have&nbsp;
                  {props.progress?.totalHintsRemaining}
                  &nbsp; hint{props.progress.totalHintsRemaining !== 1 ? "s" : ""} remaining.
                </Typography>
              )}

              {props.progress.totalHintsRemaining > 0 && (
                <div>
                  <Typography>Do you wish to use one now?</Typography>
                  <Typography variant="body2" sx={{ marginTop: "20px" }}>
                    ⚠️ Using a hint will cost you one point.
                  </Typography>
                </div>
              )}

              {props.isLoading && <LinearProgress />}
            </div>
          </DialogContent>
        </>
      )}

      <DialogActions>
        <Button onClick={props.onHintClosed}>Close</Button>
        {!props.hintFormatted && (
          <Button disabled={(!hasUnlimitedHints && props.progress.totalHintsRemaining < 1) || props.isLoading} onClick={props.onRequestHint}>
            Unlock Hint
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default HintManagerDialog;

import { Dialog, DialogTitle, DialogContent, Typography, Button, Stack, IconButton, DialogActions, Grid } from "@mui/material";
import tour from "data/model/tour";
import TourShareCard from "./TourShareCard";

export interface TourShareDialogProps {
  open: boolean;
  tour: tour;
  onRequestClose: () => void;
}

export default function TourShareDialog(props: TourShareDialogProps) {

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="sm"
      onClose={() => {
        props.onRequestClose();
      }}>
      <DialogTitle>Share tour with others</DialogTitle>
      <DialogContent dividers>
      
        <TourShareCard tour={props.tour} />

      </DialogContent>
      <DialogActions>
        <Button variant="contained"
        onClick={() => props.onRequestClose()}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

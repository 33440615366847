import { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, ButtonGroup, ClickAwayListener, Grow, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Stack } from "@mui/material";

import CloudSyncIcon from "@mui/icons-material/CloudSync";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import irlItem, { irlItemTypeValues, irlItemTypes } from "data/model/geo/irlItem";
import { Check } from "@mui/icons-material";
import { iconForIRLItemType } from "components/geo/IRLDivIcon";

interface GetPoiButtonProps {
  disabled?: boolean;
  selectedTypes: irlItemTypes[];
  onUpdateSelectedTypes: (newTypes: irlItemTypes[]) => void;
  onClick: () => void;
}

export default function GetPoiButton(props: GetPoiButtonProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpen(false);
    props.onClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (id: irlItemTypes) => {
    var newArray: irlItemTypes[] = [...props.selectedTypes];

    var itemPos = newArray.findIndex((i) => i === id);
    if (itemPos >= 0) {
      newArray.splice(itemPos, 1);
    } else {
      newArray.push(id);
    }

    props.onUpdateSelectedTypes(newArray);
  };

  const menuItem = (ir: irlItemTypes, index: number) => {
    if (ir === "Unknown") return <></>;

    var isSelected = props.selectedTypes.find((st) => st === ir) !== undefined;
    return (
      <MenuItem key={`ir-${index}`} onClick={(e) => handleMenuItemClick(ir)}>
        <ListItemIcon>{isSelected ? <Check /> : <></>}</ListItemIcon>
        <ListItemText color="#333333">
          {iconForIRLItemType(ir)}
          &nbsp;
          {ir}
        </ListItemText>
      </MenuItem>
    );
  };

  return (
    <>
      <ButtonGroup variant="outlined" disabled={props.disabled} ref={anchorRef} aria-label="split button">
        <Button startIcon={<CloudSyncIcon />} onClick={handleClick}>
          Get POI
        </Button>
        <Button size="small" aria-controls={open ? "split-button-menu" : undefined} aria-expanded={open ? "true" : undefined} aria-haspopup="menu" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" dense autoFocusItem>
                  {irlItemTypeValues.filter((ir) => ir !== "Unknown").map((ir, index) => menuItem(ir, index))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Stack, TextField, Typography } from "@mui/material";

import ContentComposerOptions from "./ContentComposerOptions";
import contentComposerOptions from "data/model/compose/contentComposerOptions";
import NSJobDefinition from "./NSJobDefinition";
import { composeContentRequest, nsJobDefinition as nsJobDefinition } from "data/model/compose/composeContentRequest";

interface NSComposeContentRequestEditorDialogProps {
  open: boolean;
  request: composeContentRequest<nsJobDefinition>;

  onUpdate: (request: composeContentRequest<nsJobDefinition>) => void;
  onRequestCancel: () => void;
  onRequestProceed: () => void;
}

export default function NSComposeContentRequestEditorDialog(props: NSComposeContentRequestEditorDialogProps) {
  return (
    <Dialog fullWidth maxWidth={"sm"} open={props.open}>
      <AppBar position="sticky">
        <DialogTitle>
          <Stack direction="row" gap={{ xs: "4px", md: "20px" }} alignItems="center" justifyContent="start" sx={{ pl: "20px", pr: "20px", width: "100%", height: "30px" }}>
            <Typography variant="h4">Generate Themed Narrative</Typography>
          </Stack>
        </DialogTitle>
      </AppBar>

      {props.request && (
        <>
          <DialogContent>
            <FormControl fullWidth>
              <Stack direction="column" gap="20px" flexGrow="1" minHeight="350px" paddingTop="10px">
                <ContentComposerOptions
                  options={props.request.options}
                  onUpdateOptions={(newOptions: contentComposerOptions) => {
                    const newRequest: composeContentRequest<nsJobDefinition> = {
                      tourID: props.request.tourID,
                      jobDefinition: props.request.jobDefinition,
                      options: newOptions
                    };
                    props.onUpdate(newRequest);
                  }}
                />

                <NSJobDefinition
                  jobDefinition={props.request.jobDefinition}
                  onUpdate={(newDefinition: nsJobDefinition) => {
                    const newRequest: composeContentRequest<nsJobDefinition> = {
                      tourID: props.request.tourID,
                      jobDefinition: newDefinition,
                      options: props.request.options
                    };
                    props.onUpdate(newRequest);
                  }}
                />

                <TextField
                  label="Tour ID"
                  helperText="Tour to place generated questions into."
                  name="tourID"
                  value={props.request.tourID}
                  onChange={(e) => {
                    const newRequest: composeContentRequest<nsJobDefinition> = {
                      tourID: Number(e.target.value),
                      jobDefinition: props.request.jobDefinition,
                      options: props.request.options
                    };
                    props.onUpdate(newRequest);
                  }}
                />
              </Stack>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                props.onRequestCancel();
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                props.onRequestProceed();
              }}>
              Proceed
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

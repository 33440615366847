import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import useAPI from "services/useHunterApi";
import { PageBox } from "components/chrome/PageBox";
import PageTitle from "components/chrome/PageTitle";

import imgPixelThanks from "../../assets/pixel2-thanks_150w.png";
import imgPixelCancelled from "../../assets/pixel2-gutted_150w.png";

const GOOGLE_TAG_KEY = "AW-11454463977";
const GOOGLE_CONVERSION_SNIPPET_KEY = "HMicCLWk2IEZEOn_9NUq";

type purchaseFlowStages = "start" | "ended" | "cancelled";

interface TourPurchaseParams {
  purchaseFlowStage?: purchaseFlowStages;
  tourId?: string;
}

interface urlDict {
  url: string;
}

interface TourPurchaseProps {}

export default function TourPurchase(props: TourPurchaseProps) {
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [gettingCheckout, setGettingCheckout] = useState(false);
  const [wasError, setWasError] = useState(false);

  const { tourId, purchaseFlowStage = "none" } = useParams() as TourPurchaseParams;

  useEffect(() => {
    if (tourId && purchaseFlowStage === "start") {
      createCheckout();
    }

    if (tourId && purchaseFlowStage === "ended") {
      registerConversion();
    }
  }, [tourId, purchaseFlowStage]);

// https://support.google.com/google-ads/answer/7548399?hl=en-GB
const registerConversion = () => {
  const tempWindow: any = window;
    if (typeof tempWindow.gtag !== 'undefined') {
      tempWindow.gtag('config', GOOGLE_TAG_KEY);
      tempWindow.gtag('event', 'conversion', {
        'send_to': `${GOOGLE_TAG_KEY}/${GOOGLE_CONVERSION_SNIPPET_KEY}`
        // 'value': 4.99,
        // 'currency': 'GBP'
    });
    }
}

  const createCheckout = () => {
    setGettingCheckout(true);
    callAPI<void, urlDict>(`/api/pay/tour/${tourId}/quantity/1`, "GET")
      .then((dict) => {
        setGettingCheckout(false);
        window.location.href = dict.url;
      })
      .catch((error) => {
        setGettingCheckout(false);
        setWasError(true);
      });
  };

  // PRETTY THIS UP!
  return (
    <PageBox>
      <PageTitle>Purchase Tour</PageTitle>
      <Stack direction="column" gap="8px" width="100%" alignContent="center" justifyContent="center">
        {purchaseFlowStage === "start" && (
          <Box minHeight="500px">
            {gettingCheckout && (
              <Box alignSelf="center" minHeight="500px" textAlign="center">
                <CircularProgress size="50px" sx={{mt: "150px", ml: "auto", mr: "auto"}} />
              </Box>
            )}

            {wasError && (
              <Box p="60px">
                <Typography variant="h3">Checkout error</Typography>
                <Typography variant="body1">Sorry, we've had some issues contacting our payment provider. This is usually just a temporary problem; please refresh the page or try again later.</Typography>
              </Box>
            )}
          </Box>
        )}

        {purchaseFlowStage === "ended" && (
          <Box>
            <Stack direction="column">
              <Box component="img"
              src={imgPixelThanks}
              ml="auto"
              mr="auto"
              width="300px"
              sx={{aspectRatio: "1 / 1"}}
              />
            <Typography variant="h2">Thanks for your purchase</Typography>
            <Typography mt="20px" variant="body1">
              We're generating your ticket - it should appear in your dashboard very shortly.
            </Typography>
            <Typography mt="10px" variant="body2">
              Your ticket can only be used to play once - so only start the game when you're assembled and ready!
            </Typography>
            <Typography mt="20px" variant="caption">
              A receipt has been emailed to the address you specified at the time of purchase.
            </Typography>
            <Button component={Link} to="/app/home/edit" variant="contained" sx={{mt: "30px"}}>
              Go to dashboard
            </Button>
            </Stack>

          </Box>
        )}


          {purchaseFlowStage === "cancelled" && (
          <Box>
             <Stack direction="column">
              <Box component="img"
              src={imgPixelCancelled}
              ml="auto"
              mr="auto"
              width="300px"
              sx={{aspectRatio: "1 / 1"}}
              />
            <Typography variant="h2">Cancelled</Typography>
            <Typography mt="20px" variant="body1">
              Your purchase didn't take place and no money has been taken from any of your payment methods.
            </Typography>
            <Typography mt="20px" variant="body1">
              If you'd like to try to purchase again, tap the button below.
            </Typography>
            
            <Button component={Link} to={`/app/tours/${tourId}/purchase/start`} variant="contained" sx={{mt: "30px"}}>
              Try purchase again
            </Button>
            </Stack>
          </Box>
        )}

      </Stack>
    </PageBox>
  );
}

import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import gameResponseMessage from "data/model/gameResponseMessage";
import AvatarSpeaking from "./AvatarSpeaking";
import { useEffect, useState } from "react";
import BigGlyphBox from "./BigGlyphBox";
import StyledMediaBox from "components/controls/StyledMediaBox";
import { BLANK_MEDIA_ITEM_STYLE } from "data/model/mediaItemStyle";

import LaunchIcon from "@mui/icons-material/Launch";
import InfoIcon from "@mui/icons-material/Info";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

import customButton, { customButtonActions } from "data/model/gameResponseMessageButton";
import { useConfirm } from "material-ui-confirm";

interface GameResponseMsgProps {
  avatarUrl: string;
  msg: gameResponseMessage;
  typingDelaySecs?: number;
}

export default function GameResponseMsg(props: GameResponseMsgProps) {
  const [isWaiting, setIsWaiting] = useState(false);
  const [moreInfoDialogOpen, setMoreInfoDialogOpen] = useState(false);
  const [moreInfoDialogContent, setMoreInfoDialogContent] = useState<string | undefined>();

  const confirm = useConfirm();

  const iconForAction = (action: customButtonActions) => {
    switch (action) {
      case "DisplayContent":
        return <StickyNote2Icon />;

      default:
      case "Link":
        return <LaunchIcon />;
    }
  };

  const handleClickMsgActionButton = (btn: customButton) => {
    switch (btn.action) {
      case "Link":
        if (btn.content) {
          confirmOpenExternalLink(btn.content);
        }
        break;

      default:
      case "DisplayContent":
        if (btn.content && btn.content?.length > 0) {
          setMoreInfoDialogContent(btn.content);
          setMoreInfoDialogOpen(true);
        }
    }
  };

  const confirmOpenExternalLink = (url: string) => {
    if (url) {
      confirm({
        title: "Open external link",
        description: "This will open an external web link in another browser tab.\r\n\r\nYou'll need to come back to this browser tab to continue your game.",
        confirmationText: "OK"
      })
        .then(() => {
          // Open a new tab with the specified URL
          window.open(url, "_blank_msglink");
        })
        .catch(() => {
          /* User cancelled the opening of the link */
        });
    }
  };

  // When we get a new message.. ..start waiting
  useEffect(() => {
    if (props.typingDelaySecs) {
      setIsWaiting(true);

      // Wait for N seconds before setting isWaiting back to false
      const timeoutId = setTimeout(() => {
        setIsWaiting(false);
      }, props.typingDelaySecs * 1000);

      // Cleanup the timeout to avoid potential memory leaks
      return () => clearTimeout(timeoutId);
    } else {
      if (isWaiting) {
        setIsWaiting(false);
      }
    }
  }, [props.msg]);

  return (
    <Box>
      <AvatarSpeaking showTypingAnimation={isWaiting} avatarUrl={props.avatarUrl} contentFormatted={props.msg.bodyFormatted} />

      {props.msg && !isWaiting && (
        <>
          <Box height="20px" />

          {props.msg.media ? (
            <StyledMediaBox
              smi={{
                item: props.msg.media,
                style: BLANK_MEDIA_ITEM_STYLE
              }}
            />
          ) : (
            <BigGlyphBox glyph={props.msg.glyph} />
          )}

          {props.msg.buttons && props.msg.buttons.length > 0 && (
            <Stack direction="column" mt="20px" gap="10px">
              {props.msg.buttons.map((b, index) => (
                <>
                  <Button
                    key={`msgb-${index}`}
                    fullWidth
                    onClick={() => handleClickMsgActionButton(b)}
                    size="small"
                    startIcon={iconForAction(b.action)}
                    color="secondary"
                    variant="contained"
                    sx={{
                      border: "1px solid #eeeeee"
                    }}>
                    {b.label}
                  </Button>
                </>
              ))}

              <Dialog open={moreInfoDialogOpen}>
                    <DialogContent>
                      {moreInfoDialogContent}
                    </DialogContent>
                    <DialogActions>
                      <Button
                      onClick={() => { setMoreInfoDialogOpen(false); }}
                      >
                        Close
                      </Button>
                    </DialogActions>

              </Dialog>
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}

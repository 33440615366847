import { useRef, useState } from "react";
import { gameSnapshotActions } from "../../data/model/gameSnapshot";

import { Link } from "react-router-dom";
import { Badge, Box, Button, IconButton, LinearProgress, Menu, Stack, Tooltip } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { ButtonGroup } from "@aws-amplify/ui-react";
import RequirePermission from "components/auth/RequirePermission";

interface GameFooterProps {
  actions?: gameSnapshotActions[];
  isLoading: boolean;
  isLoadingHint: boolean;
  showHintButton: boolean;
  totalHintsRemaining?: number;
  disableHintButton?: boolean;
  disableNextSubmitButton?: boolean;
  disableSkipButton?: boolean;
  disableBackButton?: boolean;
  viewing: number;
  onClickAction: (action: gameSnapshotActions) => void;
}

export default function GameFooter(props: GameFooterProps) {
  const settingsButton = useRef<HTMLButtonElement>(null);
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Stack direction="column" gap="5px" height="70px" width="100%" pt="10px" sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <Stack direction="row" gap="5px" width="100%" p="0px 12px 6px 6px" alignItems="center" justifyContent="space-between">
        <ButtonGroup gap={0}>
          <IconButton
            ref={settingsButton}
            onClick={(e) => {
              setSettingsMenuAnchorEl(e.currentTarget);
            }}>
            <MoreVertIcon />
          </IconButton>
          <RequirePermission name="AlphaGameTesting">
            <IconButton disabled={props.disableBackButton} onClick={() => props.onClickAction("Back")}>
              <ArrowBackIcon />
            </IconButton>
          </RequirePermission>
        </ButtonGroup>

        <Menu
          anchorEl={settingsMenuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={() => {
            setSettingsMenuAnchorEl(null);
          }}
          open={settingsMenuAnchorEl !== null}
          sx={{ p: "25px", mb: "15px" }}>
          <Button size="medium" variant="contained" href="/app/home/edit" sx={{ m: "6px 12px 6px 12px" }}>
            Pause game
          </Button>
        </Menu>

        {props.showHintButton && (
          <Badge
            badgeContent={props.totalHintsRemaining?.toString() ?? "♾️"}
            color="secondary"
            sx={{
              opacity: props.disableHintButton ? 0.5 : 1
            }}>
            <Button size="medium" variant="contained" disabled={props.disableHintButton} endIcon={<LightbulbIcon />} onClick={() => props.onClickAction("Hint")}>
              Hint
            </Button>
          </Badge>
        )}

        {/* BEGIN ACTIONS */}
        {props.actions && (
          <>
            {props.actions.includes("Skip") && (
              <Button size="medium" variant="contained" disabled={props.disableSkipButton} endIcon={<NextPlanIcon />} onClick={() => props.onClickAction("Skip")}>
                Skip
              </Button>
            )}

            {(props.actions.includes("Submit") || props.actions.includes("Next")) && ( // Our game client treats submit and next as the same thing, i.e. a submission with no value
              <Button size="medium" variant="contained" disabled={props.disableNextSubmitButton} endIcon={<ArrowForwardIcon />} onClick={() => props.onClickAction("Submit")}>
                {props.actions.includes("Submit") && <>Submit</>}
                {props.actions.includes("Next") && <>Next</>}
              </Button>
            )}

            {props.actions.includes("OK") && (
              <Button size="medium" variant="contained" onClick={() => props.onClickAction("Submit")}>
                OK
              </Button>
            )}

            {props.actions.includes("Exit") && (
              <Link to={`/app/home/edit`}>
                <Button size="medium" variant="contained">
                  Exit
                </Button>
              </Link>
            )}
          </>
        )}
        {/* END ACTIONS */}
      </Stack>

      <Box height="5px" minHeight="5px">
        <LinearProgress variant={props.isLoading ? "indeterminate" : "determinate"} value={props.viewing} />
      </Box>
    </Stack>
  );
}

import tour from "../../data/model/tour";

import { List, ListItem, Typography } from "@mui/material";

export default function TourFacts(props: { tour: tour }) {

  var outputDuration;
  if (props.tour.durationMinsStated) {
    const m = props.tour.durationMinsStated;
    if (m === 60) {
      outputDuration = "1 hour"
    } else if (m === 90) {
      outputDuration = "1½ hours"
    } else if (m === 120) {
      outputDuration = "2 hours"
    } else if (m === 150) {
      outputDuration = "2½ hours"
    } else {
      const h = Math.floor(m/60);
      outputDuration = `${h}-${h+1} hours`;
    }
  } else if (props.tour.durationMins) {
    const m = props.tour.durationMins;
    if (m <= 15) {
      outputDuration = "15 mins";
    } else if (m <= 90) { // from 15-90
      var nQuarters = Math.ceil(m/15) * 15;
      outputDuration = `${nQuarters} mins`
    } else if (m <= 120) { // from 90-120 onwards
      outputDuration = "1½-2 hours"
    } else if (m <= 150) { // from 120-150 onwards
      outputDuration = "2-2½ hours"
    } else if (m <= 180) { // from 150-180 onwards
      outputDuration = "2½-3 hours"
    } else  { // more than 3 hours
      const h = Math.floor(m/60);
      outputDuration = `${h}-${h+1} hours`;
    } 
  } else {
    outputDuration = "1½-2 hours";
  }


  var outputDistance:string|undefined;
  var met = props.tour.distanceMetresStated ??  props.tour.distanceMetres ?? undefined;

  if (met === undefined) {
    outputDistance = undefined;
  } else if (met <= 5000) {
    const km = (met / 1000).toFixed(1);
    outputDistance = `${km}km`
  } else {
    const km = (met / 1000).toFixed(0);
    outputDistance = `${km}km`
  }
  

  return (
    <List sx={{m: 0, p: 0}}>
      <ListItem sx={{ m: 0, p: 0 }}>
        <Typography variant="body2" color="inherit">
          &bull; {props.tour.placeName ?? "Local tour"}
        </Typography>
      </ListItem>
      <ListItem sx={{ m: 0, p: 0 }}>
        <Typography variant="body2" color="inherit">
          &bull; Every day
        </Typography>
      </ListItem>
      <ListItem sx={{ m: 0, p: 0 }}>
        <Typography variant="body2" color="inherit">
          &bull; {outputDistance ? `${outputDistance},` : ""} {outputDuration}
        </Typography>
      </ListItem>
    </List>
  );
}

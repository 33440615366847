import tour from "../../data/model/tour";

import { Box, Button, Card, CardActions, CardContent, CardMedia, Link, Stack, Typography } from "@mui/material";
import { truncateContent } from "utils/utils-strings";
import TourFacts from "components/pages/TourFacts";
import { DEFAULT_TOUR_SCORE } from "components/pages/TourPage";
import StarRating from "components/controls/StarRating";

export interface TourShowcaseProps {
  tour: tour;
}

export default function TourShowcase(props: TourShowcaseProps) {
  const bgSourceUrl = props.tour?.mediaItems && props.tour.mediaItems.length > 0 ? props.tour.mediaItems[0].urlLarge : "";

  // Default tour
  const scoreNumber = props.tour?.ratingScore ?? DEFAULT_TOUR_SCORE;

  return (
    <Card key={`h-${props.tour.id}`}>
      <Box sx={{ position: "relative" }}>
        <CardMedia sx={{ height: { xs: 220, sm: 230, md: 240 } }} image={bgSourceUrl} title={props.tour.name} />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            bgcolor: "rgba(0, 0, 0, 0.42)",
            color: "#ffffff",
            pt: "12px",
            pb: "12px",
            pl: "16px",
            pr: "16px"
          }}>
          <Typography variant="h3" sx={{ fontSize: { xs: "18px", sm: "20px", md: "22px" }, lineHeight: 1.0, letterSpacing: "-0.2px", textShadow: "0 7px 7px rgb(0 0 0 / 0.4)" }}>
            {props.tour.name}
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            color: "#ffffff",
            padding: "20px"
          }}></Box>
      </Box>

      <CardContent sx={{ borderBottom: "solid 1px black", backgroundColor: "rgb(106,106,106)", background: "radial-gradient(circle, rgba(106,106,106,1) 0%, rgba(66,66,66,1) 100%)", 
            color: "#eeeeee", p: "15px 20px 0px 20px", m:"0px" }}>
        <Stack direction="row" justifyContent="space-between" alignItems="start">
          <TourFacts tour={props.tour} />

          <Stack direction="column" alignItems="end">
            <StarRating score={scoreNumber} />
            <Typography variant="caption">by {props.tour.channel.name}</Typography>
          </Stack>
        </Stack>

        <Typography variant="body1" sx={{ mt: "15px" }}>
          {truncateContent(props.tour.description, 80)}
        </Typography>
        {props.tour.isLocked && (
          <Typography variant="body1" color="red" sx={{ marginTop: "20px" }}>
            {props.tour.lockedMessage}
          </Typography>
        )}

        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: "20px"}}>
          
          <Box >
          <Typography variant="caption" fontSize="18px" fontWeight="500">
          {props.tour.ticketPriceFormatted}
          </Typography>
          </Box>
        <Button
          component={Link}
          href={props.tour.isLocked ? "" : `/app/tours/${props.tour.id}`}
          
          disabled={props.tour.isLocked}
          variant="contained"
          size="medium">
          More Info
        </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}

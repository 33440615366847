import { PropsWithChildren, useEffect, useState } from "react";
import tour from "../../data/model/tour";
import { useNavigate, useParams } from "react-router-dom";
import useAPI from "services/useHunterApi";

import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, CardMedia, ImageList, ImageListItem, Stack, Typography } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

import PlayGameButtons from "components/play/PlayGameButtons";
import StarRating from "components/controls/StarRating";
import TourShareCard from "components/edit/TourShareCard";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Carousel from "react-material-ui-carousel";
import TourFactsStrip from "./TourFactsStrip";
import { renderParagraphs } from "utils/utils-strings";

export const DEFAULT_TOUR_SCORE: number = 4;

interface FAQAccordionProps {
  title: string;
}

export function FAQAccordion(props: PropsWithChildren<FAQAccordionProps>) {
  return (
    <Accordion sx={{ backgroundColor: "#777777", color: "#ffffff" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}>{props.title}</AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}

interface TourPageParams {
  tourId?: string;
}

interface TourPageProps {}

export default function TourPage(props: TourPageProps) {
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [tour, setTour] = useState<tour>();
  const navigate = useNavigate();

  const { tourId } = useParams() as TourPageParams;

  // Get tour on first load or update
  useEffect(() => {
    callAPI<void, tour>(`/api/public/tour/${tourId}`, "GET")
      .then((t) => {
        setTour(t);
      })
      .catch((error) => {});
  }, [tourId]);

  const bgSourceUrl = tour?.mediaItems && tour.mediaItems.length > 0 ? tour.mediaItems[0].urlLarge : "";

  // Default tour
  const scoreNumber = tour?.ratingScore ?? DEFAULT_TOUR_SCORE;

  return (
    <>
      {tour && (
        <Card sx={{ borderRadius: 0 }}>
          <Box sx={{ position: "relative" }}>
            <Carousel
              sx={{ height: { xs: 350, sm: 400, md: 500 } }}
              animation="slide"
              navButtonsAlwaysVisible={tour?.mediaItems.length > 1}
              cycleNavigation
              stopAutoPlayOnHover
              swipe
              indicatorContainerProps={{
                style: {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  textAlign: "center",
                  width: "100%",
                  opacity: 0.75,
                  zIndex: 1000
                }
              }}
              interval={5000}>
              {tour?.mediaItems.map((m, index) => (
                <CardMedia key={`tmix-${index}`} sx={{ height: { xs: 350, sm: 400, md: 500 } }} image={m.urlLarge} title={m.description} />
              ))}
            </Carousel>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                bgcolor: "rgba(0, 0, 0, 0.3)",
                color: "#ffffff",
                padding: "20px",
                zIndex: 1000
              }}>
              <Typography variant="h1" sx={{ fontSize: { xs: "40px", sm: "50px", md: "60px" }, lineHeight: 0.9, letterSpacing: "-1.5px", textShadow: "0 7px 7px rgb(0 0 0 / 0.4)" }}>
                {tour.name}
              </Typography>
            </Box>
          </Box>
          <CardContent sx={{ borderBottom: "solid 1px black", backgroundColor: "rgb(106,106,106)", background: "radial-gradient(circle, rgba(106,106,106,1) 0%, rgba(66,66,66,1) 100%)", color: "#eeeeee", p: "20px" }}>
            <Grid container>
              <Grid xs={12}>
                <Typography variant="h4">{tour.description}</Typography>
              </Grid>

              <Grid xs={6} mt="10px">
                {tour.channel.name && (
                  <Typography variant="caption" mt="4px">
                    by {tour.channel.name}
                  </Typography>
                )}
              </Grid>

              <Grid xs={6} textAlign="right" mt="4px">
                <Stack direction="column" alignItems="end">
                  <StarRating score={scoreNumber} />
                </Stack>
              </Grid>

              <Grid xs={12} mt={{ xs: "16px", sm: "0px" }}>
                <TourFactsStrip tour={tour} />
              </Grid>

              <Grid xs={12}>
                <Stack direction="row" gap="4px" alignItems="center" fontSize="16px" fontWeight={600}>
                  <SmartphoneIcon />
                  Web-based (iPhone / Android)
                </Stack>
              </Grid>

              <Grid xs={12} mt="20px">
                <Stack direction="column" alignItems="end" m={{xs: "0 0 0 0", sm: "0px 150px 0px 150px"}}>
                  <PlayGameButtons
                    tourId={tourId!}
                    sx={{ mt: "8px" }}
                    onNewExternalURL={(url: string) => {
                      window.location.href = url;
                    }}
                    onNewInternalURL={(url: string) => {
                      navigate(url, { replace: false });
                    }}
                  />
                </Stack>
              </Grid>

              {tour.blurb && (
                <Grid xs={12} mt="20px">
                  <Typography variant="h5" mt="20px">
                    About this Tour
                  </Typography>
                  <Typography variant="body1">{renderParagraphs(tour.blurb)}</Typography>
                </Grid>
              )}
            </Grid>

            <Box maxWidth="600px">
              <Typography variant="h5" mt="20px">
                Useful Information
              </Typography>
              <Typography variant="body2">{tour.preTourInstructions ?? "This tour takes place in the locations shown above - please read the tour description carefully to ensure you arrive in the right place with suitable clothing."}</Typography>
            </Box>

            <ImageList variant="standard" cols={tour.mediaItems.length} sx={{ mt: "20px", mb: "15px", backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
              {tour?.mediaItems.map((m, index) => (
                <ImageListItem
                  key={`tmith-${index}`}
                  sx={{
                    height: "100px",
                    maxHeight: "100px",
                    aspectRatio: "3 / 2",
                    overflow: "hidden",
                    ":hover": {
                      cursor: "pointer"
                    }
                  }}
                  onClick={() => {
                    window.open(m.urlLarge, "_blank");
                  }}>
                  <img src={m.urlMedium} alt={m.description} loading="lazy" />
                </ImageListItem>
              ))}
            </ImageList>

            {/* START FAQ */}
            <Typography variant="h5" mt="20px" mb="10px">
              Frequently Asked Questions
            </Typography>
            <FAQAccordion title="What App or device do I need?">
              All Treasure Tours require a smartphone with an internet connection and a compatible web browser, typically an iPhone or Android device released in 2018 or later. No App download is required. All players usually share a single
              smartphone, although if you have a particularly large group and want to use more than one device, you can purchase a separate ticket on each one.
            </FAQAccordion>
            <FAQAccordion title="Can I see a preview of how it looks / how it works?">
              Absolutely! If you'd like a sneak peak, there is a free Treasure Tours preview at <a style={{ color: '#ff7777' }} href="https://treasuretours.org/play/tour/21/newgame" target="_preview">this link</a> 
              &nbsp;where you can get a flavour of what a tour is like before buying. We think you'll love it.
            </FAQAccordion>
            <FAQAccordion title="How hard are the questions?">
              The questions have been designed to be suitable for all ages, while still posing a fun and interesting challenge. 
              Usually, answers are things you can spot in the real world, like signs, plaques or statues.<br /><br />
              If players are struggling with an answer, many questions allow you to request a hint to make things easier!
              Many hunts also allow you to skip questions entirely.
            </FAQAccordion>
            <FAQAccordion title="Can we stop if we want a break?">
              Yes, the tour can be paused and resumed at a later time - just tap the three dots in the bottom left hand corner of the screen to access the 'Pause game' menu.
            </FAQAccordion>


            
            {/* END FAQ */}

            {/* SHARE BUTTONS */}
            <Stack direction="column" p="15px" mt="20px" bgcolor="rgba(255, 255, 255, 0.25 )" sx={{ borderRadius: "10px" }} alignItems="center">
              <Typography variant="button" textAlign="center">
                Share with
              </Typography>
              <TourShareCard tour={tour} minimal />
            </Stack>
            {/* END SHARE BUTTONS */}
          </CardContent>
        </Card>
      )}
    </>
  );
}

import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
  SxProps
} from "@mui/material";
import mediaItem from "data/model/mediaItem";
import MediaLibraryDialog from "./MediaLibraryDialog";
import styledMediaItem from "data/model/styledMediaItem";

import CloseIcon from '@mui/icons-material/Close';

import ImageIcon from '@mui/icons-material/Image';
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // cover
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture'; // contain
import { BLANK_MEDIA_ITEM_STYLE, mediaFitting } from "data/model/mediaItemStyle";
import { QImageBackdrop, QImageBox, QImageButtonGroup, QImageTopRightControls, QImageControls, QImageSrc } from "./QImageControls";
import { mediaLibraryQuery } from "data/model/mediaLibraryQuery";
import { TTTheme } from "@mui/material/styles/createPalette";

interface StyledMediaItemProps {
  collapsedHeight?: string|number;
  expandedHeight?: string|number;
  hideStylingControls?: boolean;
  hideDeleteButton?: boolean;
  styledItem?: styledMediaItem;
  onUpdateMedia: (media?: styledMediaItem) => void;
  onRequestDeleteMedia?: () => Promise<boolean>;
  mediaLibraryQuery?: mediaLibraryQuery;
  sx?: SxProps;
}

export default function StyledMediaItem(props: StyledMediaItemProps) {
  const [isLibraryDialogOpen, setIsLibraryDialogOpen] = useState<boolean>(false);

  const theme = useTheme<TTTheme>();
  const mqSMOrDown = useMediaQuery(theme.breakpoints.down("sm"));

  const mlDialogRequestCancel = () => {
    setIsLibraryDialogOpen(false);
  };

  const handleClickDeleteImage = () => {

    if (props.onRequestDeleteMedia) {
      props.onRequestDeleteMedia()
      .then((shouldDelete:boolean) => {

        // If user clicks 'Delete'
        if (shouldDelete) {
          props.onUpdateMedia(undefined);
        }
        
      })
    }
  };

  const handleToggleStyleFit = ( newFit:mediaFitting) => {
    if (props.styledItem) {
      const newMedia: styledMediaItem = {
        item: {
          ...props.styledItem.item
        },
        style: {
          ...props.styledItem.style,
          fit: props.styledItem.style.fit = newFit
        }
      };
      props.onUpdateMedia(newMedia);
    }
  };

  const myHeight = props.styledItem ? (props.expandedHeight ?? "100px") : (props.collapsedHeight ?? "60px");

  return (
    <Box sx={props.sx}>    
    <QImageBox sx={{ height: myHeight, borderBottom: "1px solid gray"}}>
      <QImageSrc
        style={{
          backgroundImage: `url(${props.styledItem?.item.url ?? ""})`
        }}
      />
      <QImageBackdrop className="MuiImageBackdrop-root" />
      

      {props.styledItem?.item && (
      <QImageTopRightControls>
        {props.hideDeleteButton || (
          <IconButton 
          color="inherit"
          onClick={handleClickDeleteImage}>
          <CloseIcon />
          </IconButton>
          )}
      </QImageTopRightControls>
      )}

      <QImageControls>
        <Button
        variant="outlined"
        color="inherit"
        startIcon={<ImageIcon />}
        onClick={() => {
          setIsLibraryDialogOpen(true);
        }}
        >
          {(props.styledItem?.item !== undefined) ? "Edit" : "Add"}
        </Button>


        { (!props.hideStylingControls) && (props.styledItem?.item) && (
        <QImageButtonGroup size="small">
          
          <Tooltip title="Cover">
            <IconButton color="inherit"
              sx={{opacity: props.styledItem.style.fit === "Cover" ? "1" : "0.4" }}
              onClick={() => handleToggleStyleFit("Cover")}>
                <OpenInNewIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Contain">
            <IconButton color="inherit"
              sx={{opacity: props.styledItem.style.fit === "Contain" ? "1" : "0.4" }}
              onClick={() => handleToggleStyleFit("Contain")}>
                <PictureInPictureIcon />
            </IconButton>
          </Tooltip>

        </QImageButtonGroup>
        )}
        


      </QImageControls>

    </QImageBox>


    <MediaLibraryDialog
    open={isLibraryDialogOpen}
    fullScreen={mqSMOrDown}
    onRequestCancel={mlDialogRequestCancel}
    onConfirmMedia={(m: mediaItem) => {
      props.onUpdateMedia({
        item: m,
        style: props.styledItem?.style ?? BLANK_MEDIA_ITEM_STYLE
      });
      setIsLibraryDialogOpen(false);
    }}
    query={props.mediaLibraryQuery}
    />
  </Box>
  );
}

import { Box, Button, FormControl, Icon, Select, TextField, Typography } from "@mui/material";
import { PageBox } from "components/chrome/PageBox";
import PageTitle from "components/chrome/PageTitle";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ChangeEvent, useState } from "react";
import useAPI from "services/useHunterApi";
import ProgressButton from "components/controls/ProgressButton";

import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import { isValidEmailAddress } from "utils/utils-strings";

export default function ContactPage() {
  interface contactMessage {
    name: string;
    email: string;
    body: string;
  }

  const [message, setMessage] = useState<contactMessage>({ name: "", email: "", body: "" });
  const [sentMessage, setSentMessage] = useState<boolean>(false);
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();

  const handleChangeFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
    const newMsg: contactMessage = {
      ...message,
      [e.target.name]: e.target.value // [..] denotes use the 'name' JSX element value
    };

    setMessage(newMsg);
  };

  const sendMessage = (msg: contactMessage) => {
    callAPINoResponseBody<contactMessage>(`/private/api/contactmessage`, "PUT", msg)
      .then(() => {
        setSentMessage(true);
      })
      .catch((error) => {});
  };

  const formOK = message.body.trim().length > 1 && message.email.trim().length > 1 && isValidEmailAddress(message.email);

  return (
    <PageBox>
      <PageTitle>Contact Us</PageTitle>

      <Box>
        <p>Need to get in touch? Drop us a line using the form below and we'll be sure to get back to you.</p>

        {sentMessage ? (
          <Box sx={{ mt: "20px", mb: "30px" }} textAlign="center" color="secondary">
            <Box sx={{ mb: "20px" }} color="secondary" fontSize="80px">
              <MarkChatReadIcon color="inherit" fontSize="inherit" />
            </Box>
            <Typography variant="body1">
              <p>Your message has been sent. We'll be sure to get back to you as soon as possible.</p>
            </Typography>
          </Box>
        ) : (
          <FormControl fullWidth disabled={isLoading} sx={{ mt: "20px" }}>
            <Grid container spacing={2} sx={{ ml: "20px", mr: "20px" }}>
              <Grid xs={12} sm={6}>
                <TextField fullWidth disabled={isLoading} label="Your name" name="name" value={message.name} onChange={handleChangeFieldValue} />
              </Grid>
              <Grid xs={12} sm={6}>
                <TextField fullWidth required disabled={isLoading} label="Your email" name="email" type={"email"} value={message.email} onChange={handleChangeFieldValue} />
              </Grid>
              <Grid xs={12}>
                <TextField fullWidth required disabled={isLoading} multiline rows={3} label="Your message" name="body" value={message.body} onChange={handleChangeFieldValue} />
              </Grid>

              <Grid xs={12}>
                <ProgressButton
                  title="Send message"
                  disabled={!formOK}
                  waiting={isLoading}
                  variant="contained"
                  onClick={() => {
                    sendMessage(message);
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
        )}
      </Box>
    </PageBox>
  );
}

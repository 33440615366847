import "./NoPage.css";



function NoPage() {
  
  return (
    <div>
      <h3>We couldn't find this</h3>
      You must be looking for something we don't have. We're sorry.
    </div>
  );
}

export default NoPage;

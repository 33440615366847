import { ChangeEvent } from "react";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Stack, Switch, Typography } from "@mui/material";

import contentComposerOptions from "data/model/compose/contentComposerOptions";
import ComposerStyles from "./ComposerStyles";
import composeStyles from "data/model/compose/tourStyleTypes";
import BorderedSection from "components/chrome/BorderedSection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import HelpIcon from '@mui/icons-material/Help';
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

interface ContentComposerOptionsProps {
  options: contentComposerOptions;
  defaultExpanded?: boolean;
  onUpdateOptions: (options: contentComposerOptions) => void;
}

export default function ContentComposerOptions(props: ContentComposerOptionsProps) {
  const handleChangeFieldValue = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleChangeFieldNameAndValue(e.target.name, e.target.value);
  };

  const handleChangeFieldNameAndValue = (name: string, value: any) => {
    const newOptions: contentComposerOptions = {
      ...props.options,
      [name]: value
    };

    props.onUpdateOptions(newOptions);
  };

  return props.options ? (
    <FormControl fullWidth>
      <Stack direction="column" gap="20px" flexGrow="1" paddingTop="10px">

        <Accordion defaultExpanded={props.defaultExpanded}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack direction="row" gap="10px">
              <EmojiEmotionsIcon fontSize="small" />
              <Typography variant="caption">Styles</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <ComposerStyles
              styles={props.options.style}
              onUpdateStyles={(newStyle: composeStyles) => {
                handleChangeFieldNameAndValue("style", newStyle);
              }}
            />
          </AccordionDetails>
        </Accordion>
      </Stack>
    </FormControl>
  ) : (
    <></>
  );
}

import { BodyContentType, HTTPMethods } from "./hunterApiTypes";
import { useContext, useEffect, useState } from "react";
import { SBAlert, SBAlertContext } from "components/edit/SBAlertContext";

import { Auth, Cache } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

/*
UseAPI React Custom Hook
Provides access to an async method, callAPI, which can be invoked when requested
*/
const useAPI = () => {
  // For access to user JWT Auth Token
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // Display of alerts
  const { alert, setAlert } = useContext(SBAlertContext);

  // When the authStatus changes, get the latest tokens
  useEffect(() => {
    if (authStatus === "authenticated") {
      pullToken();
    }
  }, [authStatus]);

  const pullToken = async () => {
    if (authStatus === "authenticated") {
      try {
        const sesh = await Auth.currentSession();
        const accessToken = await sesh.getAccessToken();
        const jwtToken = await accessToken.getJwtToken();
        return jwtToken;
      } catch {}
    }
    return undefined;
  };

  // An Async Method to send an object of type Q to the API and receive an Object of type R back
  const callAPI = async <Q, R = void>(path: string, method: HTTPMethods, requestObject?: Q, bodyContentType: BodyContentType = "JSON"): Promise<R> => {
    try {
      setIsLoading(true);

      // Set up RequestOptions
      const myToken = await pullToken();
      const requestOptions: RequestInit = createOptions(bodyContentType, requestObject, method, myToken);

      const res = await fetch(path, requestOptions);

      if (res.status === 403) {
        throw new Error("Forbidden");
      }

      if (res.status === 204) {
        // No content, return void
        setIsLoading(false);
        return undefined as R;
      }

      const data: R = await res.json();
      setIsLoading(false);
      return data as R;
    } catch (error: unknown) {
      handleError(error);
      // In case of an error, return undefined as R
      return undefined as R;
    }
  };

  const callAPINoResponseBody = async <Q>(path: string, method: HTTPMethods, requestObject?: Q, bodyContentType: BodyContentType = "JSON"): Promise<void> => {
    setIsLoading(true);

    try {
      // Set up RequestOptions
      const myToken = await pullToken();
      var requestOptions: RequestInit = createOptions(bodyContentType, requestObject, method, myToken);

      const response = await fetch(path, requestOptions);

      setIsLoading(false);

      if (response.status < 200 || response.status > 299) {
        throw new Error(`API status was non-200's (got ${response.status})`);
      }
    } catch (error: any) {
      handleError(error);
    }
  };


  const handleError = (error: any) => {
    var reason: string = "";
    if (error instanceof Error) {
      reason = `${error.name} - ${error?.message ?? ""} ${error?.cause ?? ""}`;
    } else {
      reason = "Unknown error: ";
    }
  
    setError(`An error occurred: ${reason}`);
    setIsLoading(false);
    setAlert(
      new SBAlert({
        message: "Could not communicate with server - please try again later.",
        logMessage: `${reason}`,
        severity: "error",
        autoHideDuration: 3500,
      })
    );
  
    // Re-throw the error to be caught by the caller, otherwise it will believe the API call succeeded
    throw new Error();
  };

  

  return { callAPI, callAPINoResponseBody, isLoading, error };
};




// Assemble the headers, method, encoding type and auth
function createOptions(bodyContentType: string, requestObject: any, method: string, jwtToken?: string) {
  var requestOptions: RequestInit = {};
  var hrs: Headers = new Headers();
  if (jwtToken && jwtToken.length > 0) {
    hrs.append("Authorization", `Bearer ${jwtToken}`);
  }

  switch (bodyContentType) {
    case "FormData":
      requestOptions.body = requestObject; // For FormData, per this article make sure to NOT set the Content-Type header. The browser will set it for you, including the boundary parameter.  https://stackoverflow.com/questions/35192841/how-do-i-post-with-multipart-form-data-using-fetch
      break;

    case "JSON":
    default:
      hrs.append("Content-Type", "application/json");
      requestOptions.body = JSON.stringify(requestObject);
      break;
  }
  requestOptions.headers = hrs;
  requestOptions.method = method;
  return requestOptions;
}

export default useAPI;

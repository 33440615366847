import React, {  
  useEffect,
  useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import tour, {  } from "../../data/model/tour";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, Stack, Step, StepLabel, Stepper, Switch, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import useAPI from "services/useHunterApi";
import StyledMediaItem from "./StyledMediaItem";
import { BLANK_MEDIA_ITEM_STYLE } from "data/model/mediaItemStyle";
import styledMediaItem from "data/model/styledMediaItem";

import MessageIcon from '@mui/icons-material/Message';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import ImageIcon from '@mui/icons-material/Image';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import createTourWizardData, { BLANK_CREATE_TOUR_WIZARD_DATA } from "data/model/createTourWizardData";
import Waypoint from "./Waypoint";
import { waypoint } from "data/model/waypoint";
import { TTTheme } from "@mui/material/styles/createPalette";
import usePermissions from "hooks/usePermissions";
import NSJobDefinition from "./compose/NSJobDefinition";
import { composeContentRequest, nsJobDefinition } from "data/model/compose/composeContentRequest";
import ContentComposerOptions from "./compose/ContentComposerOptions";
import contentComposerOptions from "data/model/compose/contentComposerOptions";
import { DEFAULT_COMPOSE_OPTIONS, DEFAULT_NS_JOBDEF } from "./compose/blankComposeOptions";

interface stepDef {
  name: string;
  icon: any;
  component: any
}

export default function CreateTourWizard() {
  let { tourId } = useParams();
  const navigate = useNavigate();

  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const { hasPermissionFor } = usePermissions();
  const [isSavingHunt, setIsSavingHunt] = useState(false);
  const [wizData, setWizData] = useState<createTourWizardData>(BLANK_CREATE_TOUR_WIZARD_DATA);

  const theme = useTheme<TTTheme>();
  const mqXSOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  // Shortcut accessors - wizdata must be deep cloned as it has nested reference types
  const setCCRequest = (ccRequest: composeContentRequest<nsJobDefinition>) => {
    const newWizData:createTourWizardData = JSON.parse(JSON.stringify(wizData));
    newWizData.composeRequest = ccRequest;
    setWizData(newWizData);
  }
  const setTour = (t: tour) => {
    const newWizData:createTourWizardData = JSON.parse(JSON.stringify(wizData));
    newWizData.tour = t;
    setWizData(newWizData);
  }

  // Create hunt on first load with no huntID
  useEffect(() => {

    if (tourId === "0") {

      callAPI<null, tour>(
        `/api/tour/create`,
        "POST"
      ).then((t) => {
        
        setTour(t);
        navigate(`/app/home/new/tour/${t.id}`);
      })
      .catch (() => {})
    } else {
      callAPI<void, tour>(
        `/api/tour/${tourId}`,
        "GET"
      )
        .then((t) => {
          setTour(t);
        })
        .catch (error => {});
    }
  }, [tourId]);


  const handleUpdateWizDataWaypoint = (shouldShowMap: boolean|undefined, waypoint: waypoint|undefined) => {
    
    const newWizData:createTourWizardData = JSON.parse(JSON.stringify(wizData));
    newWizData.initialWaypoint = waypoint;
    newWizData.shouldCreateFirstStepToWaypoint = shouldShowMap;
    newWizData.tour.placeLocation = waypoint?.location;  // also set the hunt's "location" field
    setWizData(newWizData);
  }


  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (activeStep < numberOfSteps-1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      handleFinish();
    }
  };

  const handleBack = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = () => {

    if (wizData.shouldPopulateUsingComposeRequest) {
      handleFinishAsync();
    }
    else {
      handleFinishSync();
    }
  }

  const handleFinishSync = () => {
    setIsSavingHunt(true);

    callAPINoResponseBody<createTourWizardData>(
      `/private/api/createtourwizard`,
      "PUT",
      wizData
    )
    .then(() => {
      navigate(`/app/home/edit/tour/${wizData.tour.id}`);
      setIsSavingHunt(false);
    })
    .catch (error => {
      setIsSavingHunt(false);
    })
  }

  const handleFinishAsync = () => {
    setIsSavingHunt(true);

    callAPINoResponseBody<createTourWizardData>(
      `/private/api/createtourwizard/async`,
      "PUT",
      wizData
    )
    .then(() => {
      navigate(`/app/home/new/tour/${wizData.tour.id}/await`);
      setIsSavingHunt(false);
    })
    .catch (error => {
      setIsSavingHunt(false);
    })
  }



  // ************** STEPS **************
  var allSteps:stepDef[] = [];



  // STEP - COMPOSE-AI

  if (hasPermissionFor("ComposeAI")) {
    allSteps.push({
      name: "AI-Tour",
      icon:  ( <EmojiEventsIcon color="primary" fontSize="inherit" /> ),
      component: ( <>
        <FormControlLabel
          control={
            <Switch
              checked={wizData.shouldPopulateUsingComposeRequest}
              onChange={(e) => { setWizData({
                ...wizData,
                shouldPopulateUsingComposeRequest: e.target.checked
                })
              }}
            />
          }
          label="Create AI-Tour"
        />

        {wizData.shouldPopulateUsingComposeRequest && (
        <Grid container spacing="10px">
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Stack direction="column" gap="20px" flexGrow="1" minHeight="350px" paddingTop="10px">
              <NSJobDefinition
                jobDefinition={wizData.composeRequest?.jobDefinition ?? DEFAULT_NS_JOBDEF}
                onUpdate={(newDefinition: nsJobDefinition) => {
                  const newRequest: composeContentRequest<nsJobDefinition> = {
                    tourID: Number(tourId),
                    jobDefinition: newDefinition,
                    options: wizData.composeRequest?.options ?? DEFAULT_COMPOSE_OPTIONS
                  };
                  setCCRequest(newRequest);
                }}
              />

              <TextField label="Tour ID" disabled helperText="Tour to place generated questions into." name="tourID" value={tourId} />

              <ContentComposerOptions
                options={wizData.composeRequest?.options ?? DEFAULT_COMPOSE_OPTIONS}
                onUpdateOptions={(newOptions: contentComposerOptions) => {
                  const newRequest: composeContentRequest<nsJobDefinition> = {
                    tourID: Number(tourId),
                    jobDefinition: wizData.composeRequest?.jobDefinition ?? DEFAULT_NS_JOBDEF,
                    options: newOptions
                  };
                  setCCRequest(newRequest);
                }}
              />
            </Stack>
          </FormControl>
        </Grid>
        </Grid>
        )}

      </>)
    })

   }

  // STEP ONE
  allSteps.push({
    name: "Name",
    icon:  ( <MessageIcon color="primary" fontSize="inherit" /> ),
    component: (
      <>
      <Typography variant="body1" sx={{ marginBottom: "20px" }}>
        What's your tour going to be called? You can add a short description if
        you like.
      </Typography>

      <Grid container spacing="10px">
        <Grid item xs={12} md={6}>
          <TextField
            sx={{ marginRight: "20px", marginBottom: "20px" }}
            fullWidth
            autoFocus
            label="Hunt name"
            variant="outlined"
            value={wizData.tour.name}
            onChange={(e) => {
              setTour({ ...wizData.tour, name: e.target.value });
            }}
            onFocus={(event) => {
              const target = event.target;
              setTimeout(() => target.select(), 0);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            multiline
            fullWidth
            label="Description"
            rows={3}
            variant="outlined"
            value={wizData.tour.description}
            onChange={(e) => {
              setTour({ ...wizData.tour, description: e.target.value });
            }}
            onFocus={(event) => {
              const target = event.target;
              setTimeout(() => target.select(), 0);
            }}
          />
        </Grid>
      </Grid>
    </>
    )
  });

  // STEP TWO
  allSteps.push({
    name: "Location",
    icon:  ( <EditLocationAltIcon color="primary" fontSize="inherit" /> ),
    component: (
      <>
        
        <Grid container spacing="10px">
  
          <Grid item xs={12} md={12}>
            <Typography variant="body1">
              Where does your tour begin?
            </Typography>
            <Typography variant="body2" sx={{mb: "10px"}}>
              A waypoint helps players to find the start.
            </Typography>
            
                {/* MAP PICKER */}
                <Waypoint
                  waypoint={wizData.initialWaypoint}
                  fullScreen={mqXSOrDown}
                  collapsedHeight="200px"
                  expandedHeight="200px"
                  hideStylingControls
                  onRequestDeleteWaypoint={() => {
                    handleUpdateWizDataWaypoint(false, undefined);
                    return new Promise<boolean>((resolve, reject) => { return true; })
                  }}
                  onUpdateStyledWaypoint={handleUpdateWizDataWaypoint}
                />
  
              <FormGroup>
              <FormControlLabel
                sx={{ color: "#8888dd" }}
                control={
                  <Switch
                    checked={ wizData.shouldCreateFirstStepToWaypoint ?? false }
                    onChange={
                      (e, checked) => { 
                        const newWizData:createTourWizardData = {
                          ...wizData, 
                          shouldCreateFirstStepToWaypoint: checked,
                          tour: { ...wizData.tour }
                         };                
                       setWizData(newWizData);
                       }
                    }
                  />
                }
                label="Show a map to help players find this"
              />
            </FormGroup>
          </Grid>
  
        </Grid>
      </>)
  });

  // STEP THREE
  allSteps.push({
    name: "Picture",
    icon:   ( <ImageIcon color="primary" fontSize="inherit" /> ),
    component: ( <>
      <Typography sx={{ marginBottom: "20px" }}>
        Choose an image to showcase your tour (optional).
      </Typography>

      <StyledMediaItem
        collapsedHeight="200px" expandedHeight="200px"
        hideStylingControls
        styledItem={
          wizData.tour?.mediaItems && wizData.tour?.mediaItems.length > 0
            ? {
                item: wizData.tour.mediaItems[0],
                style: BLANK_MEDIA_ITEM_STYLE
              }
            : undefined
        }
        onRequestDeleteMedia={() => { return new Promise<boolean>( () => {return true;} ) }}
        onUpdateMedia={(m?: styledMediaItem) => {
          const newHunt: tour = {
            ...wizData.tour,
            mediaItems: m === undefined ? [] : [m.item]
          };
          setTour(newHunt);
        }}
        mediaLibraryQuery={{slug: {exclude: "sys-"}}}
      />
    </>)
  });
  


  // STEP FOUR (DONE)
  allSteps.push({
    name: "Finished",
    icon:  ( <FactCheckIcon color="primary" fontSize="inherit" /> ),
    component: ( <>
      <Typography sx={{ mb: "20px", mt:"16px" }}>
        That's it!
        <br />
        <br />
        You're ready to start adding steps to your tour on the next screen.
        Once you're done, click the switch to publish it so that it can be
        taken by other people.
      </Typography>

      <Button
        size="large"
        variant="contained"
        sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}
        onClick={handleFinish}>
        Create my tour
      </Button>
    </>)
  });



  // Steps
  const numberOfSteps = allSteps.length;
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Card sx={{ margin:{ xs: "0px", sm: "50px"}}}>
      <CardHeader>

      </CardHeader>
      <CardContent>
         <Typography variant="h1" color="primary">
          Create new Tour
        </Typography>
        

      { (wizData.tour?.isBlank || isSavingHunt ) ? (
        <Stack direction="row" sx={{height: "400px"}} alignItems="center" justifyContent="center">
            <CircularProgress />
        </Stack>
      ) 
      :
      (
        <Box sx={{ width: '100%', marginTop: "40px" }}>
        <Stepper activeStep={activeStep} alternativeLabel>

          {allSteps.filter((s, index) => {return index !== allSteps.length-1} ).map((st, index) => (
            <Step key={`sn${index}`}>
            <StepLabel>{st.icon} {st.name}</StepLabel>
            </Step>
          ))}

        </Stepper>

        <Grid container>
        
        {/* STEP ICON */}
        <Grid item xs={12} md={3} 
          textAlign="center"
          sx={ { padding: {xs: "2px", sm: "30px"}, fontSize: "120px", display: {xs: "none", sm:"block"}}}>
          {allSteps[activeStep].icon}
        </Grid>

        {/* STEP CONTENT */}
        <Grid item xs={12} md={9}>
          <Box sx={{ margin: {xs: "15px", sm:"20px"}}}>
          {allSteps[activeStep].component}
          </Box>
        </Grid>

        </Grid>
      
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={handleNext}>
            {activeStep === numberOfSteps-1 ? 'Finish' : 'Next'}
          </Button>
        </Box>

        </Box> 
  )}

      </CardContent>
    </Card>
  );

}

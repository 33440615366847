import { createContext } from 'react';


export interface TBClockProps {
  elapsedSecs: number;
  clientTimestamp: number;
  isClockActive: boolean;
}

export interface ITitlebarContext {
    clockProps: TBClockProps;
    setClockProps: (value:TBClockProps) => void;
    title: string;
    setTitle: (value:string) => void;
    bgurl: string;
    setBGUrl: (value:string) => void;
}

export const BLANK_CLOCK_PROPS: TBClockProps = {
  elapsedSecs: 0,
  clientTimestamp: Date.now(),
  isClockActive: false
};
  

export const TitlebarContext = createContext<ITitlebarContext>({
  clockProps: BLANK_CLOCK_PROPS,
  setClockProps: (value: TBClockProps) => {},
  title: "",
  setTitle: (value: string) => {},
  bgurl: "/game-bg-less.jpg",
  setBGUrl: (value:string) => {}
});
import { Box, Button, Chip, Divider, FormControl, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, TextField, Typography } from "@mui/material";

import theme from "data/model/compose/theme";
import { Fragment, useContext, useEffect, useState } from "react";
import useAPI from "services/useHunterApi";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { PageBox } from "components/chrome/PageBox";
import scenario from "data/model/compose/scenario";
import StripePane from "components/chrome/StripePane";

import ProgressButton from "components/controls/ProgressButton";
import BorderedSection from "components/chrome/BorderedSection";
import StyledMediaItem from "../StyledMediaItem";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import styledMediaItem from "data/model/styledMediaItem";
import { SBAlert, SBAlertContext } from "../SBAlertContext";
import { useConfirm } from "material-ui-confirm";


export interface ThemeEditorProps {}

export default function ThemeEditorPage(props: ThemeEditorProps) {
  const { alert, setAlert } = useContext(SBAlertContext);
  const confirm = useConfirm();
  const { callAPI, callAPINoResponseBody, isLoading } = useAPI();
  const [themes, setThemes] = useState<theme[]>([]);
  const [selectedThemeId, setSelectedThemeId] = useState<number|undefined>();
  const [selectedScenarioId, setSelectedScenarioId] = useState<number|undefined>(undefined);

  // Get themes from server on first-load
  useEffect(() => {
    callAPI<void, theme[]>(`/api/theme/user/me`, "GET").then((t) => {
      setThemes(t);
    }).catch(() => {});;
  }, []);

  const createTheme = () => {
    callAPI<void, theme>(`/api/theme/create`, "POST").then((t) => {
      var moreThemes:theme[] = JSON.parse(JSON.stringify(themes));
      moreThemes.push(t);
      setThemes(moreThemes);
      setSelectedThemeId(t.id);
    }).catch(() => {});
  };

  const createScenario = () => {
    callAPI<void, scenario>(`/api/scenario/create/theme/${selectedThemeId}`, "POST").then((s) => {
      const clonedThemes:theme[] = JSON.parse(JSON.stringify(themes)); // deep clone is necessary
      const clonedSelectedTheme = clonedThemes.find(t => t.id === selectedThemeId);
      clonedSelectedTheme?.scenarios.push(s);

      setSelectedScenarioId(s.id);
      setThemes(clonedThemes);
      
    }).catch(() => {});
  };

  const selectedTheme = themes.find(t => t.id === selectedThemeId);
  const selectedScenario = selectedTheme?.scenarios.find(s => s.id === selectedScenarioId);

  const handleChangeScenarioKeyValue = (key: string, value: any) => {
    if (! selectedScenario) return;

    var newScenario:scenario = {
      ...selectedScenario,
      [key]: value,
      isDirty: (key !== "isDirty") // in all cases make it dirty unless we're specifically setting that key!
    };

    const clonedThemes:theme[] = JSON.parse(JSON.stringify(themes));
    const clonedSelectedTheme = clonedThemes.find(t => t.id === selectedThemeId);
    if (clonedSelectedTheme) {
      clonedSelectedTheme.scenarios = clonedSelectedTheme?.scenarios.map(s => {
        return s.id === selectedScenarioId ? newScenario : s
      });
    }
    setThemes(clonedThemes);    
  };

  const handleChangeThemeKeyValue = (key: string, value: any) => {
    if (! selectedTheme) return;

    const clonedThemes:theme[] = JSON.parse(JSON.stringify(themes));
    const clonedSelectedTheme = clonedThemes.find(t => t.id === selectedThemeId);
    if (clonedSelectedTheme) {
      (clonedSelectedTheme as any)[key] = value;
      if (key !== "isDirty") { // in all cases make it dirty unless we're specifically setting that key!
        clonedSelectedTheme.isDirty = true;
      }
    }
    setThemes(clonedThemes);
  };

  const handleSaveTheme = () => {
    if (!selectedTheme) return;

    callAPINoResponseBody<theme>(`/api/theme`, "PUT", selectedTheme)
    .then(() => {
      setAlert(
        new SBAlert({
          message: "Saved theme OK.",
          severity: "success",
          autoHideDuration: 1500
        }));
        handleChangeThemeKeyValue("isDirty", false);
    })
    .catch(() => {
      setAlert(
        new SBAlert({
          message: "Couldn't save theme - try again later.",
          severity: "error",
          autoHideDuration: 2500
        }));
    });
  }

  const handleDeleteTheme = (event: React.MouseEvent<HTMLButtonElement>, index: number, themeId: number) => {
    confirm({
      title: "Delete this theme",
      description: "This will permanently delete this theme. Are you sure?",
      confirmationText: "Delete"
    })
      .then(() => {
        callAPINoResponseBody<void>(`/api/theme/${themeId}`, "DELETE")
          .then(() => {
            const fewerThemes: theme[] = Object.assign([], themes);
            fewerThemes.splice(index, 1);
            setThemes(fewerThemes);

            // Deselect
            if (selectedThemeId === themeId) {
              setSelectedThemeId(undefined);
            }
          })
          .catch((error) => {});
      })
      .catch(() => {
        /* User cancelled the deletion */
      });
  };


  const handleSaveScenario = () => {
    if (! selectedScenario) return;

    callAPINoResponseBody<scenario>(`/api/scenario`, "PUT", selectedScenario)
    .then(() => { 
      setAlert(
        new SBAlert({
          message: "Saved scenario OK.",
          severity: "success",
          autoHideDuration: 1500
        }));
      handleChangeScenarioKeyValue("isDirty", false);
     })
    .catch(() => {
      setAlert(
        new SBAlert({
          message: "Couldn't save scenario - try again later.",
          severity: "error",
          autoHideDuration: 2500
        }));
    });
  }

  const handleDeleteScenario = (event: React.MouseEvent<HTMLButtonElement>, index: number, scenarioId: number) => {
    confirm({
      title: "Delete this scenario",
      description: "This will permanently delete this scenario. Are you sure?",
      confirmationText: "Delete"
    })
      .then(() => {
        callAPINoResponseBody<void>(`/api/scenario/${scenarioId}`, "DELETE")
          .then(() => {
            const fewerScenarios: scenario[] = Object.assign([], selectedTheme?.scenarios);
            fewerScenarios.splice(index, 1);

            handleChangeThemeKeyValue("scenarios", fewerScenarios);

            // Deselect
            if (selectedScenarioId === scenarioId) {
              setSelectedScenarioId(undefined);
            }
          })
          .catch((error) => {});
      })
      .catch(() => {
        /* User cancelled the deletion */
      });
  };

  

  return (
    <PageBox sx={{minHeight: "400px"}}>
      <Typography variant="h1" color="primary">
        Theme Composer  &nbsp;
        <Chip size="medium" label="Beta" />
      </Typography>

      <Grid container spacing={2} width="100%" sx={{mt: "20px"}}>
        <Grid xs={12} sm={4}>
          {/* COLUMN 1 - THEMES */}
          <StripePane title="Themes" />
          <List sx={{ backgroundColor: "#fafafa" }}>
            {themes.map((t, index) => (
              <Fragment key={`th-${t.id}`}>
                <ListItem secondaryAction={
                  <IconButton color="primary"
                  onClick={(e) => handleDeleteTheme(e, index, t.id)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                }>
                  <ListItemButton selected={t.id === selectedThemeId} onClick={() => setSelectedThemeId((prev) => {
                    if (prev !== t.id) setSelectedScenarioId(undefined);
                    return t.id
                    ;
                  })}>    
                  <ListItemText primary={t.name} />
                  </ListItemButton>
                </ListItem>
                <Divider component="li" />
              </Fragment>
            ))}
          </List>
          <Button variant="outlined" disabled={isLoading} fullWidth onClick={() => createTheme()}>Add theme</Button>
        </Grid>

        <Grid xs={12} sm={4}>          
            <StripePane title="Selected Theme"/>
          {themes && selectedThemeId && (
            <Box sx={{ backgroundColor: "#fafafa" }}>
                <TextField 
                fullWidth
                sx={{mt: "20px"}}
                  label="Name"
                  value={selectedTheme?.name}
                  onChange={(e) => handleChangeThemeKeyValue("name", e.target.value)}
                  />
            <BorderedSection title="Default Avatar" sx={{mt: "20px"}}>
              <StyledMediaItem 
              sx={{m: "10px"}}
              hideStylingControls expandedHeight={200} collapsedHeight={200}
              styledItem={ 
                selectedTheme?.defaultTavatarMedia ?
                {
                item: selectedTheme?.defaultTavatarMedia ?? null,
                style: { fit: "Contain"} // Placeholders
                } : undefined
                }
              onUpdateMedia={(newStyledMedia?:styledMediaItem) => {
                handleChangeThemeKeyValue("defaultTavatarMedia", newStyledMedia?.item);
              }}
              onRequestDeleteMedia={() => { 
                return Promise.resolve(true);
              }}
              mediaLibraryQuery={{slug: {include: "sys-tavatar-"}}}
              />
              </BorderedSection>
              <BorderedSection title="Default Background" sx={{mt: "20px"}}>
              <StyledMediaItem 
              sx={{m: "10px"}}
              hideStylingControls expandedHeight={200} collapsedHeight={200}
              styledItem={ 
                selectedTheme?.defaultBackgroundMedia ?
                {
                item: selectedTheme?.defaultBackgroundMedia ?? null,
                style: {  fit: "Contain"} // Placeholders
                } : undefined
                }
              onUpdateMedia={(newStyledMedia?:styledMediaItem) => {
                handleChangeThemeKeyValue("defaultBackgroundMedia", newStyledMedia?.item);
              }}
              onRequestDeleteMedia={() => { 
                return Promise.resolve(true);
              }}
              mediaLibraryQuery={{slug: {include: "sys-bg-"}}}
              />
              </BorderedSection>
              <ProgressButton sx={{width: "100%"}} variant="contained" size="small" disabled={(!selectedTheme?.isDirty) || isLoading} waiting={isLoading}
                    title="Save" onClick={() => handleSaveTheme()} />
              <Typography variant="h4" sx={{mt: "20px"}}>
                Theme Scenarios
              </Typography>
              <List >
                {themes.find(t => t.id === selectedThemeId)?.scenarios.map((s, index) => (
                  <Fragment key={`sc-${s.id}`}>
                    <ListItem secondaryAction={
                  <IconButton color="primary"
                  onClick={(e) => handleDeleteScenario(e, index, s.id)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                }>
                      <ListItemButton selected={s.id === selectedScenarioId} onClick={() => setSelectedScenarioId(s.id)}>
                        <ListItemText primary={s.title} />
                      </ListItemButton>
                    </ListItem>
                    <Divider component="li" />
                  </Fragment>
                ))}
              </List>
              <Button variant="outlined" disabled={isLoading} fullWidth onClick={() => createScenario()}>Add scenario</Button>
            </Box>
          )}
        </Grid>

        <Grid xs={12} sm={4}>
        <StripePane title="Edit Scenario" sx={{mb: "20px"}}/>
          <Box sx={{ backgroundColor: "#fafafa" }}>
                  {selectedScenario && (
                  <FormControl fullWidth>
                    <Stack direction="column" gap="10px">
                  <TextField
                  label="Title"
                  value={selectedScenario?.title}
                  name="title"
                  onChange={(e) => handleChangeScenarioKeyValue(e.target.name, e.target.value)}
                  />
                  <TextField
                  multiline
                  rows={2}
                  label="Speaker"
                  value={selectedScenario?.speaker}
                  name="speaker"
                  onChange={(e) => handleChangeScenarioKeyValue(e.target.name, e.target.value)}
                  />
                  <TextField
                  multiline
                  rows={5}
                  label="Summary of situation"
                  value={selectedScenario?.situationSummary}
                  name="situationSummary"
                  onChange={(e) => handleChangeScenarioKeyValue(e.target.name, e.target.value)}
                  />
                  <ProgressButton variant="contained" size="small" disabled={(!selectedScenario.isDirty) || isLoading} waiting={isLoading}
                    title="Save" onClick={() => handleSaveScenario()} />
                  </Stack>
                  </FormControl>
                  
                  )}

          </Box>
        </Grid>
      </Grid>
    </PageBox>
  );
}

import { Alert, AlertColor, Snackbar } from "@mui/material";
import { createContext, useState } from "react";

export class SBAlert {
  message?: string;
  severity?: AlertColor;
  autoHideDuration?: number;
  open?: boolean;
  logMessage?: string;

  constructor(data: SBAlert) {
    this.message = data.message;
    this.severity = data.severity || "info";
    this.autoHideDuration = data.autoHideDuration || 5000;
    this.open = data.open ?? true;

    if (data.logMessage) {
      const logString = `${data.severity} from SBAlert; ${data.logMessage}`;
      if (data.severity === "error")
        console.error(logString);
      else if (data.severity === "warning")
        console.warn(logString)
      else if (data.severity === "info")
        console.info(logString) 
      else
        console.log(logString);
    }
  }
}

type SnackDefaultValue = {
  alert: SBAlert;
  setAlert: React.Dispatch<React.SetStateAction<SBAlert>>;
};

export const SBAlertContext = createContext<SnackDefaultValue>({
  alert: new SBAlert({ message: "", open: false }),
  setAlert: () => {}
});
